import * as React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { grey } from "@mui/material/colors";
import urlJoin from "url-join";

import { useThemeColor } from "../../../../providers/ThemeColorProvider";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { resServerBaseUrl } from "../../../../Config";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import { MODULE_PERMISSIONS } from "../../../../Constants";

const UserModulePermission = React.forwardRef((props, ref) => {
  const {
    userUUID,
    userAccountType,
    modulePermissions,
    onPermissionChange,
    showTitleBar,
  } = props;
  const [theme] = useThemeColor();
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const loadedPermissions = React.useRef({});
  const [currentPermission, setCurrentPermission] = React.useState();
  const [userPermission, setUserPermission] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handlePermissionChange = (key, value) => {
    if (value) {
      if (onPermissionChange) {
        onPermissionChange(userPermission | MODULE_PERMISSIONS[key]);
      }
    } else {
      if (onPermissionChange) {
        onPermissionChange(userPermission & ~MODULE_PERMISSIONS[key]);
      }
    }
  };
  const isSubmoduleDisabled = () => {
    return (
      userAccountType === 1 ||
      !userPermission ||
      (userPermission & MODULE_PERMISSIONS.AdminConsole) === 0
    );
  };
  const isModuleChecked = (key) => {
    return (userPermission & MODULE_PERMISSIONS[key]) > 0;
  };
  const isSubmoduleChecked = (key) => {
    return (
      (userPermission & MODULE_PERMISSIONS.AdminConsole) > 0 &&
      (userPermission & MODULE_PERMISSIONS[key]) > 0
    );
  };
  const isModuleAllow = (key) => {
    return (
      //userAccountType === 1 ||
      currentPermission && currentPermission?.[key] !== undefined
    );
  };
  const isSubmoduleAllow = (key) => {
    return currentPermission && currentPermission?.[key] !== undefined;
  };

  const containsUserAccountType = React.useMemo(() => {
    return (
      userAccountType !== undefined &&
      userAccountType !== "" &&
      userAccountType >= 0
    );
  }, [userAccountType]);

  React.useImperativeHandle(ref, () => ({
    getModulePermission: () => {
      return modulePermissions;
    },
  }));

  React.useEffect(() => {
    if (containsUserAccountType) {
      if (loadedPermissions.current[userAccountType] === undefined) {
        const url = urlJoin(resServerBaseUrl, "/User/GetAccountTypeSecurity");
        const data = {
          userUUID: userUUID,
          accountType: userAccountType,
        };

        setLoading(true);
        CallApiWithContext(url, authenticationContext, JSON.stringify(data))
          .then((res) => {
            loadedPermissions.current[userAccountType] = res;
            setCurrentPermission(res);
          })
          .catch(console.log)
          .finally(() => setLoading(false));
      } else {
        setCurrentPermission(loadedPermissions.current[userAccountType]);
      }
    }
  }, [userAccountType]);

  React.useEffect(() => {
    setUserPermission(modulePermissions || 1);
  }, [modulePermissions, userAccountType]);

  return (
    <>
      {containsUserAccountType ? (
        <Stack direction={"column"}>
          {showTitleBar ? (
            <Typography
              bgcolor={theme === "dark" ? grey[800] : grey[400]}
              color="white"
              textAlign={"center"}
            >
              {t("administrative_console.user_page.module_permission")}
            </Typography>
          ) : null}

          {loading ? (
            <>
              {Array.from(new Array(3)).map((sk, index) => (
                <Stack
                  key={index}
                  direction={"row"}
                  alignItems={"center"}
                  gap={1.5}
                  py={1}
                >
                  <Skeleton
                    variant={"rectangular"}
                    width={"20px"}
                    height={"20px"}
                  />
                  <Skeleton variant={"text"} width={"100px"} />
                </Stack>
              ))}
            </>
          ) : (
            <>
              {!isModuleAllow("Inbox") ? null : (
                <FormControlLabel
                  label={t("left_menu.inbox")}
                  control={
                    <Checkbox
                      disabled={true}
                      checked={isModuleChecked("Inbox")}
                      onChange={(e) =>
                        handlePermissionChange("Inbox", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("Outbox") ? null : (
                <FormControlLabel
                  label={t("left_menu.outbox")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("Outbox")}
                      onChange={(e) =>
                        handlePermissionChange("Outbox", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("Messages") ? null : (
                <FormControlLabel
                  label={t("left_menu.messages")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("Messages")}
                      onChange={(e) =>
                        handlePermissionChange("Messages", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("Workspace") ? null : (
                <FormControlLabel
                  label={t("left_menu.workspace")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("Workspace")}
                      onChange={(e) =>
                        handlePermissionChange("Workspace", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("DocumentManagement") ? null : (
                <FormControlLabel
                  label={t("left_menu.documanlink")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("DMS")}
                      onChange={(e) =>
                        handlePermissionChange("DMS", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("Report") ? null : (
                <FormControlLabel
                  label={t("left_menu.report")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("Report")}
                      onChange={(e) =>
                        handlePermissionChange("Report", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("DashBoard") ? null : (
                <FormControlLabel
                  label={t("left_menu.dashboard")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("Dashboard")}
                      onChange={(e) =>
                        handlePermissionChange("Dashboard", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("Designer") ? null : (
                <FormControlLabel
                  label={t("left_menu.designer")}
                  control={
                    <Checkbox
                      checked={isModuleChecked("Designer")}
                      onChange={(e) =>
                        handlePermissionChange("Designer", e.target.checked)
                      }
                    />
                  }
                />
              )}
              {!isModuleAllow("AdminConsole") ? null : (
                <>
                  <FormControlLabel
                    label={t("profile.administrative_console")}
                    control={
                      <Checkbox
                        disabled={userAccountType === 1}
                        checked={isModuleChecked("AdminConsole")}
                        onChange={(e) =>
                          handlePermissionChange(
                            "AdminConsole",
                            e.target.checked
                          )
                        }
                      />
                    }
                  />
                  <Stack sx={{ ml: 3 }}>
                    {isSubmoduleAllow("User_Setting") ? (
                      <>
                        <FormControlLabel
                          label={t("administrative_console.user")}
                          control={
                            <Checkbox
                              disabled={isSubmoduleDisabled()}
                              checked={isSubmoduleChecked("User_Setting")}
                              onChange={(e) =>
                                handlePermissionChange(
                                  "User_Setting",
                                  e.target.checked
                                )
                              }
                            />
                          }
                        />
                        {/* <FormControl
                          sx={{ ml: 3, textAlign: "start" }}
                          disabled={
                            !isSubmoduleChecked("User_Setting") &&
                            isSubmoduleDisabled()
                          }
                        >
                          <FormLabel id="user_list_permission">
                            {t("administrative_console.user_page.scope")}
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="user_list_permission"
                            row
                          >
                            <FormControlLabel
                              value="All User"
                              control={<Radio />}
                              label={t(
                                "administrative_console.user_page.all_user"
                              )}
                            />
                            <FormControlLabel
                              value="Partial User"
                              control={<Radio />}
                              label={t(
                                "administrative_console.user_page.partial_user"
                              )}
                            />
                          </RadioGroup>
                        </FormControl> */}
                      </>
                    ) : null}
                    {isSubmoduleAllow("Proxy_Setting") ? (
                      <FormControlLabel
                        label={t("administrative_console.proxy-delegation")}
                        control={
                          <Checkbox
                            disabled={isSubmoduleDisabled()}
                            checked={isSubmoduleChecked("Proxy_Setting")}
                            onChange={(e) =>
                              handlePermissionChange(
                                "Proxy_Setting",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    ) : null}
                    {isSubmoduleAllow("Connection_Setting") ? (
                      <FormControlLabel
                        label={t("administrative_console.connection")}
                        control={
                          <Checkbox
                            disabled={isSubmoduleDisabled()}
                            checked={isSubmoduleChecked("Connection_Setting")}
                            onChange={(e) =>
                              handlePermissionChange(
                                "Connection_Setting",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    ) : null}
                    {isSubmoduleAllow("Import_Setting") ? (
                      <FormControlLabel
                        label={t("administrative_console.import")}
                        control={
                          <Checkbox
                            disabled={isSubmoduleDisabled()}
                            checked={isSubmoduleChecked("Import_Setting")}
                            onChange={(e) =>
                              handlePermissionChange(
                                "Import_Setting",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    ) : null}
                    {isSubmoduleAllow("Email_Setting") ? (
                      <FormControlLabel
                        label={t("administrative_console.email")}
                        control={
                          <Checkbox
                            disabled={isSubmoduleDisabled()}
                            checked={isSubmoduleChecked("Email_Setting")}
                            onChange={(e) =>
                              handlePermissionChange(
                                "Email_Setting",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    ) : null}
                    {isSubmoduleAllow("Report_Setting") ? (
                      <FormControlLabel
                        label={t("administrative_console.report")}
                        control={
                          <Checkbox
                            disabled={isSubmoduleDisabled()}
                            checked={isSubmoduleChecked("Report_Setting")}
                            onChange={(e) =>
                              handlePermissionChange(
                                "Report_Setting",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    ) : null}
                    {isSubmoduleAllow("PasswordPolicy_Setting") ? (
                      <FormControlLabel
                        label={t("administrative_console.password-policy")}
                        control={
                          <Checkbox
                            disabled={isSubmoduleDisabled()}
                            checked={isSubmoduleChecked("Password_Policy")}
                            onChange={(e) =>
                              handlePermissionChange(
                                "Password_Policy",
                                e.target.checked
                              )
                            }
                          />
                        }
                      />
                    ) : null}
                  </Stack>
                </>
              )}
            </>
          )}
        </Stack>
      ) : null}
    </>
  );
});

export default UserModulePermission;
