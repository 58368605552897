import * as React from "react";
import { PhotoCamera } from "@mui/icons-material";
import { Button, Card, CardMedia, CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../Config";
import { CallApiWithUploadFile } from "../helpers/ApiHelper";
import { useAuthentication } from "../providers/AuthenticationProvider";
import { Box, createTheme } from "@mui/system";
import SharedDialog from "./SharedDialog";

const ProfileImgEditor = (props) => {
  const { loadingImg, imgByte, reloadFunct, userUUID, isAdminUpload } = props;
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const profileImage = React.useMemo(() => {
    return imgByte ? `data:image/jpeg;base64,${imgByte}` : "/unknown-logo.png";
  }, [imgByte]);

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };
  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const uploadImg = (e) => {
    if (isAdminUpload) {
      handleAdminUpload(e);
    } else {
      handleUserUpload(e);
    }
  };

  const handleUserUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Checking if the file type is allowed or not
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

      if (!allowedTypes.includes(selectedFile?.type)) {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: "Uploading is not image",
          buttons: [
            {
              text: t("report.exist_dialog.close"),
              action: () => {
                closeAlertMessage();
              },
            },
          ],
        });
        event.target.value = "";
      } else {
        const url = urlJoin(resServerBaseUrl, "/User/UploadProfileImage");
        let file = new FormData();

        file.append("image", selectedFile);

        CallApiWithUploadFile(url, authenticationContext, file).then(
          (response) => {
            reloadFunct();
          }
        );
      }
    }
  };

  const handleAdminUpload = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile && userUUID) {
      // Checking if the file type is allowed or not
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

      if (!allowedTypes.includes(selectedFile?.type)) {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: "Uploading is not image",
          buttons: [
            {
              text: t("report.exist_dialog.close"),
              action: () => {
                closeAlertMessage();
              },
            },
          ],
        });
        event.target.value = "";
      } else {
        const url = urlJoin(resServerBaseUrl, "/User/UpdateUserProfileImage");
        let file = new FormData();

        file.append("image", selectedFile);
        file.append("userUUID", userUUID);
        CallApiWithUploadFile(url, authenticationContext, file).then(
          (response) => {
            reloadFunct();
          }
        );
      }
    }
  };

  return (
    <Grid
      item
      container
      xs={12}
      //sm={6}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <Card
          style={{
            display: "block",
            marginTop: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            maxHeight: "200px",
            maxWidth: "200px",
            backgroundColor: "#F8F8F8",
          }}
        >
          <CardMedia
            sx={{ display: loadingImg ? "none" : "block" }}
            component="img"
            image={profileImage}
          />

          {loadingImg && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "80px",
                marginBottom: "80px",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          ></Box>
        </Card>
      </Grid>
      <Grid item>
        <Button
          xs={12}
          variant="contained"
          component="label"
          startIcon={<PhotoCamera />}
          style={{ marginTop: "10px" }}
        >
          <input
            hidden
            accept="image/*"
            multiple
            type="file"
            onChange={uploadImg}
          />
          {t("profile.upload")}
        </Button>
      </Grid>

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
};

export default ProfileImgEditor;
