import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Navigator from "./components/Navigator";
import Header from "./components/Header";
import Button from "@mui/material/Button";
import RouteConfig from "./RouteConfig";
import Snackbar from "@mui/material/Snackbar";
import "./MailBox.css";
import { DRAWER_WIDTH } from "./Constants";
import { InitServiceWorker } from "./firebase";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { useTranslation } from "react-i18next";
import { useAuthentication } from "./providers/AuthenticationProvider";
import { useThemeColor } from "./providers/ThemeColorProvider";
import { useTheme } from "@emotion/react";
import { Fade, Grid, LinearProgress } from "@mui/material";
import PropTypes from "prop-types";
import { CometChatMessageEvents } from "@cometchat/chat-uikit-react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { useProfile } from "./providers/ProfileProvider";

let themeLight = createTheme({});
// let themeLight = createTheme({
//   palette: {
//     primary: {
//       light: "#b3cc79",
//       main: "#3A6015",
//       dark: "#28430C",
//     },
//   },
//   typography: {
//     h5: {
//       fontWeight: 500,
//       fontSize: 26,
//       letterSpacing: 0.5,
//     },
//   },
//   shape: {
//     borderRadius: 8,
//   },
//   components: {
//     MuiTab: {
//       defaultProps: {
//         disableRipple: true,
//       },
//     },
//   },
//   mixins: {
//     toolbar: {
//       minHeight: 48,
//     },
//   },
// });

// themeLight = {
//   ...themeLight,
//   components: {
//     MuiDrawer: {
//       styleOverrides: {
//         paper: {
//           backgroundColor: "#283d17",
//         },
//       },
//     },
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: "none",
//           "&.MuiLoadingButton-colorPrimary": {
//             backgroundColor: "#3a6015",
//           },
//         },
//         contained: {
//           boxShadow: "none",
//           "&:active": {
//             boxShadow: "none",
//           },
//         },
//       },
//     },
//     MuiTabs: {
//       styleOverrides: {
//         root: {
//           marginLeft: themeLight.spacing(1),
//         },
//         indicator: {
//           height: 3,
//           borderTopLeftRadius: 3,
//           borderTopRightRadius: 3,
//           backgroundColor: themeLight.palette.common.white,
//         },
//       },
//     },
//     MuiTab: {
//       styleOverrides: {
//         root: {
//           textTransform: "none",
//           margin: "0 16px",
//           minWidth: 0,
//           padding: 0,
//           [themeLight.breakpoints.up("md")]: {
//             padding: 0,
//             minWidth: 0,
//           },
//         },
//       },
//     },
//     MuiIconButton: {
//       styleOverrides: {
//         root: {
//           padding: themeLight.spacing(1),
//         },
//       },
//     },
//     MuiTooltip: {
//       styleOverrides: {
//         tooltip: {
//           borderRadius: 4,
//         },
//       },
//     },
//     MuiDivider: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "rgb(255,255,255,0.15)",
//         },
//       },
//     },
//     MuiListItemButton: {
//       styleOverrides: {
//         root: {
//           "&.MuiListItemButton-gutters": {
//             backgroundColor: "#3a6015",
//           },
//           "&.Mui-selected": {
//             color: "#47c6ff",
//           },
//         },
//       },
//     },
//     MuiListItemText: {
//       styleOverrides: {
//         primary: {
//           fontSize: 14,
//           fontWeight: themeLight.typography.fontWeightMedium,
//         },
//       },
//     },
//     MuiListItemIcon: {
//       styleOverrides: {
//         root: {
//           color: "inherit",
//           minWidth: "auto",
//           marginRight: themeLight.spacing(2),
//           "& svg": {
//             fontSize: 20,
//           },
//         },
//       },
//     },
//     MuiAvatar: {
//       styleOverrides: {
//         root: {
//           width: 32,
//           height: 32,
//         },
//       },
//     },
//   },
// };

const CustomSnackbar = (props) => {
  const { open, onClickFunction, onCloseFunction, duration } = props;
  const [progress, setProgress] = React.useState(0);
  const { t } = useTranslation();
  const themeColorContext = useThemeColor();

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        return prevProgress >= 100 ? 0 : prevProgress + 1;
      });
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Snackbar
      sx={{
        bgcolor: `mainContainer.${themeColorContext[0]}`,
        borderRadius: 2,
        borderWidth: 1,
      }}
      onClose={() => {
        onCloseFunction();
      }}
      autoHideDuration={duration}
      open={open}
      TransitionComponent={Fade}
      key={"notification_permission_snackbar"}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Grid container direction={"column"} p={2} spacing={1}>
        <Grid
          container
          item
          direction={"row"}
          xs={12}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item>{t("home.allow_notification_content")}</Grid>
          <Grid container item direction={"row"}>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => {
                    onClickFunction();
                  }}
                >
                  {t("home.allow_notification_enable")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onCloseFunction();
                  }}
                >
                  {t("common.no")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            color="primary"
            variant="determinate"
            value={progress}
          />
        </Grid>
      </Grid>
    </Snackbar>
  );
};

CustomSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickFunction: PropTypes.func.isRequired,
  onCloseFunction: PropTypes.func.isRequired,
  duration: PropTypes.number.isRequired,
};

export default function Home(props) {
  const { onSignOut } = props;

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [messageCount, setMessageCount] = React.useState(0);
  const [formIdentity, setFormIdentity] = React.useState({
    tplId: null,
    name: "",
    taskID: null,
    inboxId: null,
    caseId: null,
    action: "",
    tplUUID: null,
  });
  const [notificationPermissionSnackOpen, setNotificationPermissionSnackOpen] =
    React.useState(false);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMlUp = useMediaQuery(theme.breakpoints.up("ml"));
  const authContext = useAuthentication();
  const themeColorContext = useThemeColor();
  const [profile] = useProfile();
  const snackbarDuration = 10000;

  const notificationBtnClick = () => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          InitServiceWorker(authContext);
          setNotificationPermissionSnackOpen(false);
        }
      });
    }
  };

  const handleSnackbarOnClose = () => {
    setNotificationPermissionSnackOpen(false);
  };

  const checkUnreadMessageCount = () => {
    CometChat.getUnreadMessageCount()
      .then((array) => {
        const unreadCount =
          Object.values(array.users).reduce((acc, value) => acc + value, 0) +
          Object.values(array.groups).reduce((acc, value) => acc + value, 0);
        setMessageCount(unreadCount);
      })
      .catch(console.log);
  };
  const onChatMessagesRead = () => {
    checkUnreadMessageCount();
  };

  React.useEffect(() => {
    if (profile) {
      checkUnreadMessageCount();

      CometChatMessageEvents.ccMessageRead.subscribe(onChatMessagesRead);
      CometChat.addMessageListener(
        profile.uuid,
        new CometChat.MessageListener({
          onTextMessageReceived: () => setMessageCount((prev) => prev + 1),
        })
      );
    }

    return () => {
      if (profile) {
        CometChat.removeMessageListener(profile.uuid);
      }
    };
  }, [profile]);
  React.useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      if (Notification.permission === "granted") {
        InitServiceWorker(authContext);
      } else {
        setNotificationPermissionSnackOpen(true);
      }
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      {isMlUp ? (
        <Box
          id="HomeNavigatorBox_MdUp"
          component="nav"
          sx={{
            boxSizing: "inherit",
            width: { sm: DRAWER_WIDTH },
            flexShrink: { sm: 0 },
          }}
        >
          <Navigator
            id="HomeNaivgator_MdUp"
            PaperProps={{ style: { zIndex: 1300, width: DRAWER_WIDTH } }}
            sx={{ display: { sm: "block", xs: "none" } }}
            variant="permanent"
            open={mobileOpen}
            messageCount={messageCount}
            onClose={handleDrawerToggle}
          />
        </Box>
      ) : (
        <Box
          id="HomeNavigatorBox"
          component="nav"
          sx={{ flexShrink: { sm: 0 } }}
        >
          <Navigator
            id="HomeNaivgator"
            PaperProps={{ style: { width: DRAWER_WIDTH } }}
            width={DRAWER_WIDTH}
            variant="temporary"
            open={mobileOpen}
            messageCount={messageCount}
            onClose={handleDrawerToggle}
          />
        </Box>
      )}

      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header
          onDrawerToggle={handleDrawerToggle}
          onSignOut={onSignOut}
          tittle={
            title
            /*{title == "left_menu.outbox" || title == "left_menu.inbox"
                ? title
            : t("left_menu.workspace")}*/
          }
          formIdentity={formIdentity}
          drawerWidth={DRAWER_WIDTH}
          isMdUp={isMlUp}
        />
        <Box
          component="main"
          id="mainContainer"
          sx={{
            flex: 1,
            py: 0.5,
            px: 1,
            bgcolor: `mainContainer.${themeColorContext[0]}`,
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <RouteConfig
            settitle={setTitle}
            setFormIdentity={setFormIdentity}
            formIdentity={formIdentity}
            isMdUp={isMlUp}
          />
        </Box>
      </Box>

      {notificationPermissionSnackOpen && (
        <CustomSnackbar
          open={notificationPermissionSnackOpen}
          onClickFunction={notificationBtnClick}
          onCloseFunction={handleSnackbarOnClose}
          duration={snackbarDuration}
        />
      )}
    </Box>
  );
}
