import axios from "axios";
import { authServerBaseUrl } from "../Config";
import urlJoin from "url-join";

const getRefreshedToken = async () => {
  const url = urlJoin(authServerBaseUrl, "/refresh-token");
  const bearer = "Bearer " + localStorage.getItem("accessToken");
  const headers = {
    Authorization: bearer,
    "X-FP-API-KEY": "chaptoken",
    "Content-Type": "application/json",
  };

  return await fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      RefreshToken: localStorage.getItem("refreshToken"),
    }),
  });
};

const wait = async (timeout = 500) => {
  return await new Promise((resolve, reject) => {
    window.setTimeout(() => resolve(), timeout);
  });
};

export async function CallApiWithContext(
  url,
  authenticationContext,
  data = null,
  method = "POST"
) {
  let headers = {
    "X-FP-API-KEY": "chaptoken",
    "Content-Type": "application/json",
  };

  if (process.env.NODE_ENV === "development") {
    const bearer = "Bearer " + localStorage.getItem("accessToken");

    headers = {
      ...headers,
      Authorization: bearer,
    };
  }

  // return axios({
  //     method: method,
  //     url: url,
  //     withCredentials: true,
  //     //credentials: 'include',
  //     headers: headers,
  //     data: data
  // })
  // .then((res) => {
  //             if (res.status === 401) {
  //                 authenticationContext[1](false);
  //             }

  //             return res.json();
  //         })
  //         .then((result) => {
  //             if (process.env.NODE_ENV === 'development') {
  //                 console.log(result);
  //             }
  //             return result;
  //         })
  //         .catch((error) => {
  //             console.log(error)
  //         });
  return fetch(url, {
    // Return promise
    method: method,
    withCredentials: true,
    // credentials: 'include',
    headers: headers,
    body: data,
  })
    .then(async (res) => {
      // response unauthorized
      if (res.status === 401) {
        // if refreshing token, wait for it complete.
        // prevent same refresh token update more than once.
        if (window.refreshingToken === true) {
          do {
            console.log("wait 500ms for new access token return.");
            await wait();
          } while (window.refreshingToken === true);

          console.log("wait completed, call last request again.");

          if (process.env.NODE_ENV === "development") {
            headers = {
              ...headers,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
          }

          // recall last action.
          return await fetch(url, {
            method: method,
            withCredentials: true,
            headers: headers,
            body: data,
          }).then((res) => res.json());
        }

        window.refreshingToken = true;

        // try to use refresh token update access token
        const recallRes = await getRefreshedToken();

        if (recallRes.status === 200) {
          // if success, update local access token and refresh token.
          const result = await recallRes.json();

          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("accessToken", result.token);
            localStorage.setItem("refreshToken", result.refreshToken);

            headers = {
              ...headers,
              Authorization: `Bearer ${result.token}`,
            };
          }

          window.refreshingToken = false;

          // recall last action.
          return await fetch(url, {
            method: method,
            withCredentials: true,
            headers: headers,
            body: data,
          }).then((res) => res.json());
        }

        // otherwise, back to login page.
        authenticationContext[1](false, "common.session_timeout");

        if (process.env.NODE_ENV === "development") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }

        throw { status: 403, message: "common.session_timeout" };
      } else if (res.status === 403) {
        // user haven't got right to view this page.
        throw res;
      }

      return res.json();
    })
    .then((result) => {
      if (process.env.NODE_ENV === "development") {
        console.log(result);
      }
      return result;
    })
    .catch((error) => {
      console.log(error);

      if (error.status === 403) {
        throw error;
      }
    })
    .finally(() => (window.refreshingToken = false));
}

export async function CallApiWithUploadFile(
  url,
  authenticationContext,
  data = {}
) {
  let headers = {
    "X-FP-API-KEY": "chaptoken",
    "Content-Type": "multipart/form-data",
  };

  if (process.env.NODE_ENV === "development") {
    let bearer = "Bearer " + localStorage.getItem("accessToken");

    headers = {
      ...headers,
      Authorization: bearer,
    };
  }

  return axios
    .post(url, data, {
      headers: headers,
    })
    .then(async (res) => {
      if (res.status === 401) {
        // if refreshing token, wait for it complete.
        // prevent same refresh token update more than once.
        if (window.refreshingToken === true) {
          do {
            console.log("wait 500ms for new access token return.");
            await wait();
          } while (window.refreshingToken === true);

          console.log("wait completed, call last request again.");

          if (process.env.NODE_ENV === "development") {
            headers = {
              ...headers,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
          }

          // recall last action.
          return await axios
            .post(url, data, {
              headers: headers,
            })
            .then((res) => res.data);
        }

        window.refreshingToken = true;

        // try to use refresh token update access token
        const recallRes = await getRefreshedToken();

        if (recallRes.status === 200) {
          // if success, update local access token and refresh token.
          const result = await recallRes.json();

          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("accessToken", result.token);
            localStorage.setItem("refreshToken", result.refreshToken);

            headers = {
              ...headers,
              Authorization: `Bearer ${result.token}`,
            };
          }

          window.refreshingToken = false;

          // recall last action.
          return await axios
            .post(url, data, {
              headers: headers,
            })
            .then((res) => res.data);
        }

        // otherwise, back to login page.
        authenticationContext[1](false, "common.session_timeout");

        if (process.env.NODE_ENV === "development") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }

        throw { status: 403, message: "common.session_timeout" };
      }

      return res.data;
    })
    .then((result) => {
      if (process.env.NODE_ENV === "development") {
        console.log(result);
      }
      return result;
    })
    .catch(async (error) => {
      console.log(error);

      if (error.status === 401) {
        // if refreshing token, wait for it complete.
        // prevent same refresh token update more than once.
        if (window.refreshingToken === true) {
          do {
            console.log("wait 500ms for new access token return.");
            await wait();
          } while (window.refreshingToken === true);

          console.log("wait completed, call last request again.");

          if (process.env.NODE_ENV === "development") {
            headers = {
              ...headers,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
          }

          // recall last action.
          return await axios
            .post(url, data, {
              headers: headers,
            })
            .then((res) => res.data);
        }

        window.refreshingToken = true;

        // try to use refresh token update access token
        const recallRes = await getRefreshedToken();

        if (recallRes.status === 200) {
          // if success, update local access token and refresh token.
          const result = await recallRes.json();

          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("accessToken", result.token);
            localStorage.setItem("refreshToken", result.refreshToken);

            headers = {
              ...headers,
              Authorization: `Bearer ${result.token}`,
            };
          }

          window.refreshingToken = false;

          // recall last action.
          return await axios
            .post(url, data, {
              headers: headers,
            })
            .then((res) => res.data);
        }

        // otherwise, back to login page.
        authenticationContext[1](false, "common.session_timeout");

        if (process.env.NODE_ENV === "development") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }

        throw { status: 403, message: "common.session_timeout" };
      }

      throw error;
    })
    .finally(() => (window.refreshingToken = false));
}

export function CallApiWithoutContext(
  url,
  data = {},
  contentType = "application/json",
  method = "POST"
) {
  return axios({
    // Return promise
    url: url,
    method: method,
    data: data,
  })
    .then((res) => res.data)
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
        throw error;
      }
    );
}

export async function CallApiWithDownloadFile(
  url,
  authenticationContext,
  data = {},
  fileName,
  method = "POST"
) {
  const downloadFile = (blob) => {
    const fileDownloadURL = URL.createObjectURL(blob);
    let alink = document.createElement("a");
    alink.href = fileDownloadURL;
    alink.download = fileName;
    alink.click();
  };

  let headers = {
    "X-FP-API-KEY": "chaptoken",
    "Content-Type": "application/json",
  };

  if (process.env.NODE_ENV === "development") {
    let bearer = "Bearer " + localStorage.getItem("accessToken");
    headers = {
      ...headers,
      Authorization: bearer,
    };
  }

  return fetch(url, {
    method: method,
    withCredentials: true,
    headers: headers,
    body: data,
  })
    .then(async (res) => {
      if (res.status === 200) {
        res.blob().then(downloadFile);
      } else if (res.status === 401) {
        // if refreshing token, wait for it complete.
        // prevent same refresh token update more than once.
        if (window.refreshingToken === true) {
          do {
            console.log("wait 500ms for new access token return.");
            await wait();
          } while (window.refreshingToken === true);

          console.log("wait completed, call last request again.");

          if (process.env.NODE_ENV === "development") {
            headers = {
              ...headers,
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            };
          }

          // recall last action.
          return await fetch(url, {
            method: method,
            withCredentials: true,
            headers: headers,
            body: data,
          }).then((res) => {
            res.blob().then(downloadFile);
          });
        }

        window.refreshingToken = true;

        // try to use refresh token update access token
        const recallRes = await getRefreshedToken();

        if (recallRes.status === 200) {
          // if success, update local access token and refresh token.
          const result = await recallRes.json();

          if (process.env.NODE_ENV === "development") {
            localStorage.setItem("accessToken", result.token);
            localStorage.setItem("refreshToken", result.refreshToken);

            headers = {
              ...headers,
              Authorization: `Bearer ${result.token}`,
            };
          }

          window.refreshingToken = false;

          // recall last action.
          return await fetch(url, {
            method: method,
            withCredentials: true,
            headers: headers,
            body: data,
          }).then((res) => {
            res.blob().then(downloadFile);
          });
        }

        // otherwise, back to login page.
        authenticationContext[1](false, "common.session_timeout");

        if (process.env.NODE_ENV === "development") {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
        }

        throw { status: 403, message: "common.session_timeout" };
      } else if (res.status === 403) {
        const error = new Error("You haven't got download permission.");

        error.response = res;

        throw error;
      } else {
        let error = null;

        try {
          const json = await res.json();
          error = new Error(json.message);
        } catch (e) {
          error = new Error(await res.text());
        }

        error.response = res;

        throw error;
      }
    })
    .then((res) => {
      let ok = "ok";
      return ok;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => (window.refreshingToken = false));
}
