import * as React from "react";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import TextField from "@mui/material/TextField";
import { Autocomplete, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import ProxyDelegationEditor, {
  ProxyDelegateType,
} from "../../ProxyDelegationEditor";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../../../Config";
import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../../../helpers/ApiHelper";
import {
  ConvertUTCDateToLocal,
  ConvertLocalDateToUTC,
} from "../../../../shared/Utils";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import SharedDialog from "../../../../shared/SharedDialog";
import TitleWithIcon from "../../../../shared/TitleWithIcon";

export default function ProxyDelegateSetting() {
  const { t } = useTranslation();
  const authenticationContext = useAuthentication();
  const GUIDpattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  const [proxyTableRows, setProxyTableRows] = React.useState([]);
  const [delegationTableRows, setDelegationTableRows] = React.useState([]);
  const [isLoadingProxyTable, setIsLoadingProxyTable] = React.useState(false);
  const [isLoadingDelegationTable, setIsLoadingDelegationTable] =
    React.useState(false);
  const [userOption, setUserOption] = React.useState([]);
  const [isLoadingAutocom, setIsLoadingAutocom] = React.useState(false);
  const [originalUserUUID, setOriginalUserUUID] = React.useState("");
  const [selectedUser, setSelectedUser] = React.useState("");
  const [inputedUser, setInputedUser] = React.useState("");
  const [isAdministrator, setIsAdministrator] = React.useState(true);
  const [caseTplRecord, setCaseTplRecord] = React.useState([]);
  const [isUseLoadingSpinner, setIsUseLoadingSpinner] = React.useState(false);
  var caseTplAuthen = JSON.stringify({ IsAdministrator: isAdministrator });
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [alertContent, setAlertContent] = React.useState("");
  const [alertTitle, setAlertTitle] = React.useState("");

  const handleAlertDialogClose = () => {
    setAlertDialog(false);
  };

  React.useEffect(() => {
    setIsLoadingAutocom(true);
    const url_username = urlJoin(
      resServerBaseUrl,
      "/User/GetUsersByPermissions"
    );
    CallApiWithContext(url_username, authenticationContext, null)
      .then((response) => {
        if (response) {
          var getUserNameArr = new Array();
          var userNameArr = new Array();
          getUserNameArr = Object.keys(response).map((key) => {
            return {
              id: response[key].id,
              name: response[key].login,
              uuid: response[key].uuid,
            };
          });
          getUserNameArr.forEach((element) => {
            var forPushUserIDName = {
              ID: element.id,
              label: element.name,
              uuid: element.uuid,
            };
            if (GUIDpattern.test(forPushUserIDName.label) === true) {
            } else {
              userNameArr.push(forPushUserIDName);
            }
          });
          userNameArr = userNameArr.filter(
            (L) =>
              L.label != "Robot" &&
              L.label != "00000000-0000-0000-0000-000000000000"
          );
          userNameArr.sort((a, b) => {
            const labelA = a.label.toUpperCase();
            const labelB = b.label.toUpperCase();
            if (labelA < labelB) {
              return -1;
            }
            if (labelA > labelB) {
              return 1;
            }
            return 0;
          });
          setUserOption(userNameArr);
          setIsLoadingAutocom(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoadingAutocom(false);
        setIsLoadingProxyTable(false);
        setIsLoadingDelegationTable(false);
        setIsUseLoadingSpinner(false);
      });
  }, [selectedUser]);

  const cleanTable = () => {
    setSelectedUser("");
    setInputedUser("");
    setOriginalUserUUID(null);
    setProxyTableRows(null);
    setDelegationTableRows(null);
  };

  return (
    <Box>
      <SharedDialog
        isOpen={alertDialog}
        onClose={handleAlertDialogClose}
        title={alertTitle}
        content={alertContent}
        buttons={[
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ]}
      ></SharedDialog>
      {!isUseLoadingSpinner ? (
        <Grid xs={12} md={12}>
          <Grid
            xs={12}
            md={12}
            container
            direction="row"
            justifyContent={{ xs: "center", sm: "start" }}
            sx={{ p: { xs: "5px", sm: "20px" } }}
          >
            <Grid container direction="row">
              <TitleWithIcon
                icon={<GroupIcon />}
                title={{
                  content: t("profile.proxy_and_delegation.select_user"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container>
              <Typography
                variant="p"
                style={{ color: "gray", fontSize: "14px" }}
              >
                {t("profile.proxy_and_delegation.autocomplete_description")}
              </Typography>
            </Grid>
            <Grid item>
              {isLoadingProxyTable ? (
                <Grid sx={{ pt: "5px" }}>
                  <Skeleton sx={{ width: "280px", height: "60px" }}></Skeleton>
                </Grid>
              ) : (
                <Autocomplete
                  size="small"
                  loading={isLoadingAutocom}
                  loadingText={t("profile.proxy_and_delegation.loading_text")}
                  defaultValue={selectedUser}
                  value={selectedUser}
                  onChange={(event, value) => {
                    if (value != null && value != "undefined" && value != "") {
                      setSelectedUser(value.label);
                      setOriginalUserUUID(value.uuid);
                    } else {
                      cleanTable();
                    }
                  }}
                  onInputChange={(event, value) => {
                    setInputedUser(value);
                  }}
                  inputValue={inputedUser}
                  options={userOption}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{ pt: "20px", width: "280px" }}
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>

          <Grid
            xs={12}
            md={12}
            container
            direction="column"
            sx={{
              height: "20%",
              width: "100%",
              px: { xs: "5px", sm: "20px" },
              pt: "20px",
            }}
          >
            <Grid container direction="row" style={{ textAlign: "left" }}>
              <TitleWithIcon
                icon={<PersonIcon />}
                title={{
                  content: t("profile.proxy_and_delegation.proxy_user"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container>
              <Typography
                variant="p"
                style={{ color: "gray", fontSize: "14px" }}
              >
                {t("profile.proxy_and_delegation.proxy_description")}
              </Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <ProxyDelegationEditor
                type={ProxyDelegateType.Proxy}
                originalUserUUID={originalUserUUID}
                isAdmin={true}
                userUUID={
                  userOption?.find((u) => u.label === selectedUser)?.uuid ??
                  null
                }
              />
            </Grid>
          </Grid>
          <Grid container direction="row" sx={{ height: "0px" }}></Grid>

          <Grid
            xs={12}
            md={12}
            container
            direction="column"
            sx={{
              height: "20%",
              width: "100%",
              px: { xs: "5px", sm: "20px" },
              py: "20px",
            }}
          >
            <Grid container direction="row" style={{ textAlign: "left" }}>
              <TitleWithIcon
                icon={<AssignmentIndIcon />}
                title={{
                  content: t("profile.proxy_and_delegation.delegation"),
                  variant: "h6",
                  alignItems: "center",
                  fontWeight: "bold",
                }}
              />
            </Grid>
            <Grid container>
              <Typography
                variant="p"
                style={{ color: "gray", fontSize: "14px" }}
              >
                {t("profile.proxy_and_delegation.delegation_description")}
              </Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <ProxyDelegationEditor
                type={ProxyDelegateType.Delegation}
                originalUserUUID={originalUserUUID}
                isAdmin={true}
                userUUID={
                  userOption?.find((u) => u.label === selectedUser)?.uuid ??
                  null
                }
              />
            </Grid>
          </Grid>
          <SharedDialog
            isOpen={alertDialog}
            onClose={handleAlertDialogClose}
            title={alertTitle}
            content={alertContent}
            buttons={[
              {
                text: t("administrative_console.report_page.confirm"),
                action: handleAlertDialogClose,
              },
            ]}
          ></SharedDialog>
        </Grid>
      ) : (
        <LoadingSpinner isOpen={true}></LoadingSpinner>
      )}
    </Box>
  );
}
