import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import i18n from "../../localization/i18n.js";
import { useTranslation } from "react-i18next";
import urlJoin from "url-join";

import { useThemeColor } from "../../providers/ThemeColorProvider.js";
import { CallApiWithContext } from "../../helpers/ApiHelper.js";
import { resServerBaseUrl } from "../../Config.js";
import { useAuthentication } from "../../providers/AuthenticationProvider.js";
import { useProfile } from "../../providers/ProfileProvider.js";
import TitleWithIcon from "../../shared/TitleWithIcon.js";
import "./Settings.css";

export default function Settings(props) {
  const { t } = useTranslation();
  const setTitle = props.settitle;
  const [selectedLanguage, setSelectedLanguage] = React.useState();
  const [attr, setAttr] = React.useState();
  const [theme, setTheme] = useThemeColor();
  const baseTheme = useTheme();
  const authenticationContext = useAuthentication();
  const profileContext = useProfile();

  const languages = [
    { value: "zh-CN", label: "简体中文", enum: 0 },
    { value: "en", label: "English", enum: 1 },
    { value: "zh-HK", label: "繁體中文", enum: 2 },
  ];

  const getLanguageDefaultValue = () => {
    return languages.find((item) => item.value === i18n.language)
      ? languages.find((item) => item.value === i18n.language)
      : languages[1];
  };

  const onLanguageChanged = (event, value) => {
    i18n.changeLanguage(value.value);
    setSelectedLanguage(value.enum);
  };

  const themes = [
    { value: "light", label: t("settings_page.light") },
    { value: "dark", label: t("settings_page.dark") },
  ];

  function getDefaultTheme() {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return themes[1].value;
    } else {
      return themes[0].value;
    }
  }

  React.useEffect(() => {
    // const getDefaultTheme = () => {
    //   if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    //     return themes[1].value;
    //   } else {
    //     return themes[0].value;
    //   }
    // };

    setTitle(t("header.settings"));

    setTheme(
      themes.find((item) => item.value === localStorage.getItem("theme"))
        ?.value ?? getDefaultTheme()
    );
  }, [themes]);

  React.useEffect(() => {
    if (profileContext[0]) {
      setAttr(JSON.parse(profileContext[0].attr));
    }
  }, [profileContext]);

  React.useEffect(() => {
    if (selectedLanguage) {
      let attrObj = attr;
      attrObj["LANGUAGE"] = selectedLanguage;
      let data = {
        Attr: JSON.stringify(attrObj),
      };
      const url = urlJoin(resServerBaseUrl, "/User/UpdateUserAttr");
      CallApiWithContext(url, authenticationContext, JSON.stringify(data)).then(
        () => {}
      );
    }
  }, [selectedLanguage]);

  const getThemeDefaultValue = () => {
    return themes.find((item) => item.value === localStorage.getItem("theme"))
      ? themes.find((item) => item.value === localStorage.getItem("theme"))
      : window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ? themes[1]
      : themes[0];
  };

  const onThemeChanged = (event, value) => {
    setTheme(value.value);
    localStorage.setItem("theme", value.value);
    // if (value === t("settings_page.default")) {
    //   localStorage.setItem("theme", "default");
    //   setTheme("default");
    // } else {
    //   localStorage.setItem("theme", "dark");
    //   setTheme("dark");
    // }
    // localStorage.setItem("theme", value);
  };

  const currentTheme = React.useMemo(() => {
    return (
      themes.find((item) => item.value === localStorage.getItem("theme"))
        ?.label ?? getDefaultTheme()
    );
  }, [t, theme]);

  return (
    <Box>
      <Stack spacing={1}>
        <div style={{ padding: baseTheme.spacing(1), paddingBottom: "0px" }}>
          <TitleWithIcon
            icon={<SettingsIcon fontSize="small" />}
            title={{
              content: t("settings_page.general_settings"),
              variant: "h6",
              alignItems: "center",
            }}
          />
        </div>

        <Grid
          container
          className="paper"
          spacing={1}
          sx={{ pb: 1, width: "100%" }}
        >
          <Grid
            item
            xs={3}
            sm={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            className="control-label"
          >
            <Typography>{t("settings_page.language")}</Typography>
          </Grid>
          <Grid item xs={9} sm={7} className="control">
            <Autocomplete
              disablePortal
              // disabled={!attr}
              options={languages}
              size="small"
              className="control"
              disableClearable
              renderInput={(params) => <TextField {...params} />}
              defaultValue={getLanguageDefaultValue()}
              onChange={onLanguageChanged}
            />
          </Grid>
          <Grid
            item
            xs={3}
            sm={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            className="control-label"
          >
            <Typography>{t("settings_page.theme")}</Typography>
          </Grid>
          <Grid item xs={9} sm={7} className="control">
            <Autocomplete
              disablePortal
              options={themes}
              size="small"
              className="control"
              disableClearable
              value={currentTheme}
              renderInput={(params) => <TextField {...params} />}
              defaultValue={getThemeDefaultValue}
              onChange={onThemeChanged}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
