import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useTranslation } from "react-i18next";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  ArrowDownward,
  ArrowForward,
  Dashboard,
  SwapVert,
} from "@mui/icons-material";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../Config";
import { CallApiWithContext } from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import LoadingSpinner from "../../shared/LoadingSpinner";
import "./Dashboard.css";
import SharedDialog from "../../shared/SharedDialog";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import { ReactSortable } from "react-sortablejs";
import BarChart from "./Charts/BarChart.js";
import PieChart from "./Charts/PieChart.js";
import { CHART_TYPE } from "../../Constants.js";
import ListChart from "./Charts/ListChart.js";
import utc from "dayjs/plugin/utc";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import TitleWithIcon from "../../shared/TitleWithIcon.js";
import { DashboardCriteriaComboBox } from "../../shared/CriteriaComboBox.js";
import { useThemeColor } from "../../providers/ThemeColorProvider.js";

dayjs.extend(utc);

export default function DashBoard(props) {
  const { settitle: setTitle } = props;
  const { t } = useTranslation();
  const [editSettingDialog, setEditSettingDialog] = React.useState(false);
  const [dashboardSetting, setDashboardSetting] = React.useState({});
  const [caseTplOption, setCaseTplOption] = React.useState([]);
  const [reportOption, setReportOption] = React.useState([]);
  const [reportDatas, setReportDatas] = React.useState([]);
  const [axis, setAxis] = React.useState([]);

  const [criteriaChanged, setCriteriaChanged] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(false);
  const [buttonLoading, setButtonLoad] = React.useState(false);
  const authenticationContext = useAuthentication();
  const [checkedEmpty, setCheckedEmpty] = React.useState({
    chartName: true,
    chartType: true,
    CaseTplUUID: true,
    TplSID: true,
    x: true,
    y: true,
    function: false,
  });
  const [isEditDashboardSetting, setIsEditDashboardSetting] =
    React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [request, setRequest] = React.useState({});
  const [dragMode, setDragMode] = React.useState(false);
  const [chart, setChart] = React.useState([]);
  const [availableFunctions, setAvailableFunction] = React.useState({});
  const [setting, setSetting] = React.useState([]);
  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };
  const [levelPage, setLevelPage] = React.useState({});
  const [levelCurrentPage, setLevelCurrentPage] = React.useState({});
  const [levelRowsPerPage, setLevelRow] = React.useState(2);
  const [operator, setOperator] = React.useState({});
  const [selectedFunctions, setSelectedFunctions] = React.useState(["Sum"]);
  const [reportLoading, setReportLoading] = React.useState(false);
  const [tab, setTab] = React.useState("1");
  const [dynamicDate, setDynamicDate] = React.useState([
    {
      type: "Absolute",
      columnName: "",
      range: [null, null],
    },
  ]);
  const [systemColumnExtraData, setSystemColumnExtraData] =
    React.useState(null);
  const [dynamicLists, setDynamicLists] = React.useState([]);
  const rowsPerPage = 5;
  const theme = useTheme();
  const themeColorContext = useThemeColor();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const criteriaComboBoxRef = React.useRef();

  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const SetReportOption = () => {
    setReportLoading(true);
    var url = urlJoin(resServerBaseUrl, "/Report/GetExistingReport");
    const data = JSON.stringify({
      CaseTemplateUUID: dashboardSetting?.chartRequest?.CaseTplUUID ?? "",
    });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        //get report sid
        let reports = response.reports.map((report) => {
          return { label: report.reportName, value: report.sid };
        });
        setReportOption(reports);
        let rows = response.reports.map((report) => {
          return { sid: report.sid, datas: report.data };
        });
        let arr = JSON.parse(
          response.reports.find(
            (reports) =>
              reports.sid === dashboardSetting?.chartRequest?.TplSID ?? ""
          ).data
        );
        let dlArr = AxisHandler(arr);
        setDynamicLists(dlArr);
        if (dashboardSetting?.chartRequest?.DynamicListName) {
          let childArr = JSON.parse(
            response.reports.find(
              (reports) =>
                reports.sid === dashboardSetting?.chartRequest?.TplSID ?? ""
            ).data
          ).find(
            (e) =>
              e.ColumnName === dashboardSetting?.chartRequest?.DynamicListName
          ).ChildColumn;
          AxisHandler(childArr);
        }
        setReportDatas(rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setReportLoading(false);
      });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log(value);
    setSelectedFunctions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    setRequest({
      ...request,
      Function: value,
    });
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250,
      },
    },
  };

  const dateUnit = [
    { label: "-", value: "none" },
    { label: t("dashboard.year"), value: "year" },
    { label: t("dashboard.season"), value: "season" },
    { label: t("dashboard.month"), value: "month" },
    { label: t("dashboard.day"), value: "day" },
  ];

  const FunctionGenerator = (type) => {
    switch (CHART_TYPE[type]) {
      case 0:
        return (
          <FormControl sx={{ width: "250px" }}>
            <InputLabel id="bar chart function">
              {t("dashboard.function")}
            </InputLabel>
            <Select
              multiple
              value={selectedFunctions ?? ""}
              onChange={(event) => {
                handleChange(event);
                handleEmptyCheck("function", null);
              }}
              input={<OutlinedInput label={t("dashboard.function")} />}
              renderValue={(selected) => {
                let arr = [];
                selected.map((s) => {
                  arr.push(t(`dashboard.${s.toLowerCase()}`));
                });
                return arr.join(", ");
              }}
              MenuProps={MenuProps}
            >
              {availableFunctions[type].map((funct) => (
                <MenuItem key={funct} value={funct}>
                  <Checkbox checked={selectedFunctions.indexOf(funct) > -1} />
                  <ListItemText
                    primary={t(`dashboard.${funct.toLowerCase()}`)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case 1:
      case 2:
        return (
          <FormControl sx={{ width: "150px" }}>
            <InputLabel id="pie chart function">
              {t("dashboard.function")}
            </InputLabel>
            <Select
              fullWidth
              value={request.Function ? request.Function[0] : []}
              id={"pie chart function"}
              onChange={(event) => {
                setRequest({
                  ...request,
                  Function: [event.target.value],
                });
                handleEmptyCheck("function", event.target.value);
              }}
              input={<OutlinedInput label={t("dashboard.function")} />}
            >
              {availableFunctions[type].map((option) => (
                <MenuItem key={option} value={option}>
                  <ListItemText
                    primary={t(`dashboard.${option.toLowerCase()}`)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
    }
  };

  const handleDynamicDayChange = (value, key, itemIndex, rangeIndex) => {
    let arr = dynamicDate;
    switch (key) {
      case "range":
        let rangeArr = arr[itemIndex][key];
        rangeArr[rangeIndex] = value;
        arr[itemIndex][key] = rangeArr;
        break;
      case "columnName":
        if (value == "none") {
          arr[itemIndex][key] = "";
          arr[itemIndex].range = ["", ""];
        } else {
          arr[itemIndex][key] = value;
        }
        break;
      case "unit":
        if (arr[itemIndex].columnName.includes(".")) {
          let col = arr[itemIndex].columnName.split(".")[0];
          arr[itemIndex].columnName = col + "." + value;
        } else {
          arr[itemIndex].columnName = arr[itemIndex].columnName + "." + value;
        }
        break;
      case "type":
        arr[itemIndex][key] = value;
        if (value == dynamicDateType[0]) {
          arr[itemIndex].range = ["", ""];
        } else if (value == dynamicDateType[1]) {
          arr[itemIndex].range = [1, 1];
        }
        break;

      default:
        arr[itemIndex][key] = value;
        break;
    }

    setDynamicDate((a) => [...arr]);
  };

  const GetCaseTemplate = () => {
    let tplUUID = [];
    var url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplates");
    const data = JSON.stringify({ IsAdministrator: true });
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        if (response.message !== "undefined") {
          response.map((tpl) => {
            tpl.templateInfos.map((info) => {
              tplUUID.push({
                label: info.name,
                value: info.uuid,
              });
            });
          });
          setCaseTplOption(tplUUID);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const GetChartType = () => {
    var url = urlJoin(resServerBaseUrl, "/Report/GetChartType");
    CallApiWithContext(url, authenticationContext)
      .then((response) => {
        let functions = {};
        response.chartTypes.map(
          (type) =>
            (functions[type.chartType] = JSON.parse(type.availableFunctions))
        );
        setAvailableFunction(functions);

        setChart(
          response.chartTypes
            .map((type) => type?.chartType)
            .map((type) => {
              if (type.toLowerCase().includes("bar")) {
                return { label: t(`dashboard.bar_chart`), value: type };
              } else if (type.toLowerCase().includes("pie")) {
                return { label: t(`dashboard.pie_chart`), value: type };
              } else {
                return {
                  label: t(`dashboard.${type.toLowerCase()}`),
                  value: type,
                };
              }
            })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetDashboardSetting = () => {
    let caseTplUUIDs = [];
    var url = urlJoin(resServerBaseUrl, "/Report/GetDashboardSettings");
    CallApiWithContext(url, authenticationContext)
      .then((response) => {
        setSetting(response.settingsList);
        let chartLoading = {};
        for (const setting of response.settingsList) {
          caseTplUUIDs.push(
            JSON.parse(setting.settings).chartRequest.CaseTplUUID
          );
          chartLoading[setting.sid] = true;
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const UpdateDashboardOrder = () => {
    const datas = setting.map((datas) => datas.sid);
    const data = JSON.stringify({
      SIDOrder: datas,
    });
    var url = urlJoin(resServerBaseUrl, "/Report/UpdateDashboardOrder");
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  function handleDragModeToggle() {
    setDragMode(!dragMode);

    if (dragMode) {
      UpdateDashboardOrder();
    }
  }

  function handleAddSettingButtononClick() {
    setEditSettingDialog(true);
    setIsEditDashboardSetting(false);
    setDashboardSetting({
      chartRow: 1,
      chartColumn: 1,
      x: { "": "" },
      y: { "": "" },
    });
    setRequest({
      IsDownload: false,
      IsPreviewReport: false,
      FilterCriteria: JSON.stringify({ [""]: { [""]: [""] } }),
      Function: ["Sum"],
    });
  }

  function handleSettingChange(props, value, index) {
    if (props === "x" || props === "y") {
      let tempKeyArr = dashboardSetting[props]
        ? Object.keys(dashboardSetting[props])
        : [];
      let tempObj = {};

      switch (props) {
        case "x":
          if (value === "none" && index !== 0) {
            tempKeyArr.splice(index, 1);
          } else {
            tempKeyArr[index] = value;
          }
          if (index + 1 === tempKeyArr.length) {
            tempKeyArr.push("");
          }
          tempKeyArr.map((key) => {
            if (dashboardSetting[props][key]) {
              tempObj[key] = dashboardSetting[props][key];
            } else {
              tempObj[key] = axis.find((a) => a.value === key)?.label ?? "";
            }
          });

          break;
        case "y":
          tempObj[value] = value;
          break;
      }
      setDashboardSetting({
        ...dashboardSetting,
        [props]: tempObj,
      });
      if (index === 0) {
        let req = request;

        req[props.toUpperCase()] = value;
        if (!value.includes(".")) {
          delete req.Level;
        }
        setRequest(req);
      }

      //set paging
      if (Object.keys(tempObj).length > levelPage[props] * levelRowsPerPage) {
        setLevelPage({
          ...levelPage,
          [props]:
            levelPage[props] === 0
              ? levelPage[props] + 2
              : levelPage[props] + 1,
        });
      }

      if (
        Object.keys(tempObj).length <
        (levelPage[props] - 1) * levelRowsPerPage + 1
      ) {
        setLevelPage({
          ...levelPage,
          [props]: levelPage[props] - 1,
        });
        if (levelCurrentPage > levelPage[props] - 1) {
          setLevelCurrentPage({
            ...levelCurrentPage,
            [props]: levelCurrentPage[props] - 1,
          });
        }
      }
    } else if (props === "x_rename" || props === "y_rename") {
      let type = props.split("_")[0];
      let tempKeyArr = dashboardSetting[type]
        ? Object.keys(dashboardSetting[type])
        : [];
      let tempObj = dashboardSetting[type];
      tempObj[tempKeyArr[index]] = value;
      setDashboardSetting({
        ...dashboardSetting,
        [type]: tempObj,
      });
    } else if (props === "dynamicDay_x" || props === "dynamicDay_y") {
      let type = props.split("_")[1];
      let tempKeyArr = dashboardSetting[type]
        ? Object.keys(dashboardSetting[type])
        : [];
      let tempObj = {};
      let key = Object.keys(dashboardSetting[type])[index];
      let level = {};
      const levelSetting = (k, levelObj) => {
        if (index == 0) {
          let newObj = {};
          newObj[k] = "";
          levelObj = newObj;
        }
        return levelObj;
      };
      if (value != "none") {
        if (key.includes(".")) {
          let keyArr = key.split(".");
          keyArr.pop();
          keyArr.push(value);
          key = keyArr.join(".");
          tempKeyArr[index] = key;
          level = levelSetting(key, level);
        } else {
          tempKeyArr[index] = key + "." + value;
          key = key + "." + value;
          level = levelSetting(key, level);
        }
        tempKeyArr.map((key, i) => {
          tempObj[key] = Object.values(dashboardSetting[type])[i];
        });

        console.log(level);
        setDashboardSetting({
          ...dashboardSetting,
          [type]: tempObj,
        });

        if (index === 0) {
          let requestObj = request;
          requestObj[type.toUpperCase()] = key;
          requestObj.Level = JSON.stringify(level);
          setRequest(requestObj);
        }
      } else {
        if (key.includes(".")) {
          let originalKey = key;
          key = key.split(".")[0];
          tempKeyArr[index] = key;

          let tempObj = {};

          tempKeyArr.map((k, i) => {
            tempObj[k] = Object.values(dashboardSetting[type])[i];
          });

          setDashboardSetting({
            ...dashboardSetting,
            [type]: tempObj,
          });

          let requestObj = request;
          requestObj[type.toUpperCase()] = key;
          delete requestObj.Level;

          setRequest(requestObj);
        }
      }
    } else {
      setDashboardSetting({
        ...dashboardSetting,
        [props]: value,
      });
    }
  }

  const handleRequestChange = (props, value) => {
    let req = request;
    if (props === "CaseTplUUID") {
      GetExtraData(value);
    } else if (props === "DynamicListName") {
      if (value != null) {
        let cols = dynamicLists.find((d) => d.value == value).childCol;
        AxisHandler(cols);
      } else {
        AxisHandler(
          JSON.parse(
            reportDatas.filter((reports) => reports.sid === request.TplSID)[0]
              .datas
          )
        );
        if (req[props]) {
          delete req[props];
        }
      }
    }
    if (
      props === "CaseTplUUID" ||
      props === "TplSID" ||
      props === "DynamicListName"
    ) {
      setDashboardSetting({
        ...dashboardSetting,
        x: { "": "" },
        y: { "": "" },
      });
      req.FilterCriteria = null;
      delete req.DynamicListName;
      delete req.Level;
    }
    setRequest({ ...req, [props]: value });
  };

  const AxisHandler = (datasArr) => {
    const dataName = [];
    const dlArr = [];
    datasArr.map((d) => {
      let dValue = d.ColumnName;
      let dLabel = d?.ColumnLabel ?? d.ColumnName;

      if (d.DataType !== "DynamicList" && d.DataType !== "DataGroup") {
        dataName.push({ label: dLabel, value: dValue });
      } else {
        dlArr.push({
          label: dLabel,
          value: dValue,
          childCol: d.ChildColumn,
        });
      }
    });
    dataName.splice(0, 0, { label: "none", value: "none" });
    dlArr.splice(0, 0, { label: "none", value: null });
    setAxis(dataName);
    return dlArr;
  };

  function handleEditDialogOnClose() {
    setSelectedFunctions(["Sum"]);
    setEditSettingDialog(false);
    setLevelPage(0);
    setOperator({
      ...operator,
      x: 14,
      y: 14,
    });
    setLevelCurrentPage({
      x: 0,
      y: 0,
    });
    setLevelPage({
      x: 0,
      y: 0,
    });
    setTab("1");
    setDynamicDate([
      {
        type: "Absolute",
        columnName: "",
        range: ["", ""],
      },
    ]);
    setDynamicLists([]);
  }

  function handleSIDChange(event, value) {
    setLevelPage({
      ...levelPage,
      x: 0,
      y: 0,
    });
    setLevelCurrentPage({
      ...levelCurrentPage,
      x: 0,
      y: 0,
    });
    let sid;
    if (value?.value) {
      sid = value.value;
    } else {
      sid = value;
    }

    if (sid && Object.keys(reportDatas).length > 0) {
      let dlArr = AxisHandler(
        JSON.parse(
          reportDatas.filter((reports) => reports.sid === sid)[0].datas
        )
      );
      setDynamicLists(dlArr);
    }
  }

  function DeleteSetting() {
    showAlertMessage({
      title: t("dashboard.delete_setting_title"),
      content: t("dashboard.delete_setting_comfirm"),
      buttons: [
        {
          text: t("profile.proxy_and_delegation.delete"),
          action: () => {
            EditDashboardSetting("delete");
            closeAlertMessage();
          },
        },
        {
          text: t("profile.proxy_and_delegation.cancel"),
          action: () => {
            closeAlertMessage();
          },
          color: "error",
          variant: "text",
        },
      ],
    });
  }

  const GetExtraData = (uuid) => {
    const data = JSON.stringify({ CaseTemplateUUID: uuid });
    let url = urlJoin(resServerBaseUrl, "/Report/GetSystemColumnExtraData");
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        if (response) {
          let obj =
            Object.keys(systemColumnExtraData).length > 0
              ? systemColumnExtraData
              : {};
          Object.keys(response).map((r) => {
            let arr = response[r].map((res) => {
              return { label: res, value: res };
            });
            obj[r.toLowerCase()] = arr;
          });
          setSystemColumnExtraData(obj);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dynamicDateType = ["Absolute", "Relative"];

  function handleEmptyCheck(props, value) {
    if (props === "Function") {
      if (dashboardSetting.chartType === "Pie Chart" && value != null) {
        setCheckedEmpty({ ...checkedEmpty, [props]: false });
      } else if (
        dashboardSetting.chartType === "Bar Chart" &&
        selectedFunctions.length > 0
      ) {
        setCheckedEmpty({ ...checkedEmpty, [props]: false });
      }
    } else {
      if (value === "") {
        setCheckedEmpty({ ...checkedEmpty, [props]: true });
      } else {
        setCheckedEmpty({ ...checkedEmpty, [props]: false });
      }
    }
  }

  function handleLevelPageChange(e, value, props) {
    setLevelCurrentPage({
      ...levelCurrentPage,
      [props]: value - 1,
    });
  }

  function handleEditDialogOnOpen(setting, sid) {
    setting = {
      ...setting,
      sid: sid,
    };
    setting.x[""] = "";
    setDashboardSetting(setting);
    if (
      setting.chartRequest.FilterCriteria == null ||
      setting.chartRequest.FilterCriteria == "{}"
    ) {
      setting.chartRequest.FilterCriteria = JSON.stringify({
        [""]: { [""]: [""] },
      });
    }
    setRequest({ ...setting.chartRequest });
    setIsEditDashboardSetting(true);

    setSelectedFunctions(setting.chartRequest.Function);

    GetExtraData(setting.chartRequest.CaseTplUUID);

    setLevelPage({
      x: Math.round(setting.x.length / levelRowsPerPage),
      y: Math.round(setting.y.length / levelRowsPerPage),
    });
    setLevelCurrentPage({
      x: 0,
      y: 0,
    });
    setCriteriaChanged(!criteriaChanged);
    setEditSettingDialog(true);
    if (setting?.DynamicDate ?? false) {
      setDynamicDate(JSON.parse(setting.DynamicDate));
    }
  }

  const GetAxisData = (type, axis) => {
    const responseData = reportDatas.find(
      (d) => d.sid === request?.TplSID ?? ""
    );
    if (responseData) {
      if (request.DynamicListName) {
        let childCol =
          JSON.parse(responseData?.datas).find((d) => {
            return d.ColumnName === request.DynamicListName;
          })?.ChildColumn ?? [];
        return childCol.find((d) => {
          return d.ColumnName === axis;
        })?.[type];
      } else {
        if (responseData.datas) {
          let arr = JSON.parse(responseData?.datas);
          return arr.find((d) => {
            return d.ColumnName === axis;
          })?.[type];
        }
      }
    }
  };

  function EditDashboardSetting(action) {
    setButtonLoad(true);

    let dashboardsetting = dashboardSetting;
    let req = request;
    let dynamic = dynamicDate;
    dynamic = dynamic.map((d) => d.columnName === "");
    if (!dynamic.includes(true))
      dashboardSetting.DynamicDate = JSON.stringify(dynamicDate);
    else if (dashboardSetting.DynamicDate) delete dashboardSetting.DynamicDate;

    req = GetFilterCriteria(req);

    dashboardsetting.chartRequest = req;

    if (dashboardsetting.data) {
      delete dashboardsetting.data;
    }

    let sid = isEditDashboardSetting ? dashboardsetting.sid : null;

    if (dashboardsetting.sid) {
      delete dashboardsetting.sid;
    }

    Object.keys(dashboardsetting.x).forEach(
      (k) => !dashboardsetting.x[k] && delete dashboardsetting.x[k]
    );

    const data = JSON.stringify({
      Action: action,
      DashBoardSID: sid,
      Settings: JSON.stringify(dashboardsetting),
    });

    let title, content;
    switch (action.toLowerCase()) {
      case "add":
        title = t("dashboard.add_setting_title");
        content = t("dashboard.add_setting_content");
        break;
      case "edit":
        title = t("dashboard.edit_setting_title");
        content = t("dashboard.edit_setting_content");
        break;
      case "delete":
        title = t("dashboard.delete_setting_title");
        content = t("dashboard.delete_setting_content");
        break;
    }

    var url = urlJoin(resServerBaseUrl, "/Report/SaveDashboardSettings");
    CallApiWithContext(url, authenticationContext, data)
      .then((response) => {
        if (response) {
          showAlertMessage({
            title: title,
            content: content,
            buttons: [
              {
                text: t("administrative_console.report_page.confirm"),
                action: () => {
                  closeAlertMessage();
                  setEditSettingDialog(false);
                  setReload(!reload);
                },
              },
            ],
          });
        } else {
          showAlertMessage({
            title: t("dashboard.edit_setting_failed_title"),
            content: t("edit_setting_failed_content.add_setting_content"),
            buttons: [
              {
                text: t("administrative_console.report_page.confirm"),
                action: () => {
                  closeAlertMessage();
                  setReload(!reload);
                },
              },
            ],
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setButtonLoad(false);
      });
  }

  function GetExistingReportOnChange(event, value) {
    setReportLoading(true);

    let uuid;
    if (value?.value ?? false) {
      uuid = value.value;
    } else {
      uuid = value;
    }

    let caseName = caseTplOption.find((opt) => opt.value === value).label;

    setRequest({
      ...request,
      caseTplName: caseName,
    });

    let reports = reportDatas.map((report) => {
      return { label: report.reportName, value: report.sid };
    });

    setReportOption(reports);
    let rows = reportDatas.map((report) => {
      return { sid: report.sid, datas: report.data };
    });
    if (uuid) {
      let rows;
      var url = urlJoin(resServerBaseUrl, "/Report/GetExistingReport");
      const data = JSON.stringify({ CaseTemplateUUID: uuid });
      CallApiWithContext(url, authenticationContext, data)
        .then((response) => {
          //get report sid
          let reports = response.reports.map((report) => {
            return { label: report.reportName, value: report.sid };
          });
          setReportOption(reports);
          rows = response.reports.map((report) => {
            return { sid: report.sid, datas: report.data };
          });
          setReportDatas(rows);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setReportLoading(false);
        });
    }
  }

  const GetFilterCriteria = (request) => {
    let fc = request.FilterCriteria;
    if (criteriaComboBoxRef.current) {
      fc = criteriaComboBoxRef.current.getFilterCriteriaSetting();
    }
    if (fc !== JSON.stringify({ [""]: { [""]: [""] } }) && fc != null) {
      let temp = {};
      Object.keys(JSON.parse(fc))
        .filter((k) => !!k)
        .map(
          (nonEmptyKey) => (temp[nonEmptyKey] = JSON.parse(fc)[nonEmptyKey])
        );
      request.FilterCriteria = JSON.stringify(temp);
    } else {
      request.FilterCriteria = null;
    }

    return request;
  };

  React.useEffect(() => {
    if (isEditDashboardSetting) {
      SetReportOption();
    }
  }, [isEditDashboardSetting]);

  React.useEffect(() => {
    setLoading(true);
    setTitle(t("left_menu.dashboard"));
    GetCaseTemplate();
    GetChartType();
    GetDashboardSetting();
    setTab("1");
  }, [reload]);

  React.useEffect(() => {
    let url = urlJoin(resServerBaseUrl, "/User/GetUsersFullnameByPermission");
    CallApiWithContext(
      url,
      authenticationContext,
      JSON.stringify({ Language: "" })
    )
      .then((response) => {
        let userNameIDArr = [];
        Object.keys(response).map((r) => {
          userNameIDArr.push({ label: response[r], value: r });
        });
        setSystemColumnExtraData({ "case initiator": userNameIDArr });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Grid container xs={12}>
      <Grid
        container
        display={"row"}
        justifyContent={"space-between"}
        xs={12}
        alignItems={"center"}
        position={"sticky"}
        bgcolor={`mainContainer.${themeColorContext[0]}`}
        top={"60px"}
        zIndex={1}
      >
        <Grid container item alignItems={"center"}>
          <TitleWithIcon
            icon={<Dashboard />}
            title={{
              content: t("left_menu.dashboard"),
              variant: "h6",
              alignItems: "center",
              fontWeight: "bold",
            }}
          />
        </Grid>
        <Grid container item>
          <Grid item>
            <Tooltip title={t("dashboard.drag_mode")}>
              <IconButton
                onClick={() => handleDragModeToggle()}
                color={dragMode ? "primary" : "default"}
              >
                <SwapVert />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t("dashboard.report_setting")}>
              <IconButton
                onClick={() => {
                  handleAddSettingButtononClick();
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <ReactSortable
          list={setting}
          setList={setSetting}
          animation={100}
          onChange={() => console.log(setting)}
          style={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: isSmallDevice ? "none" : "repeat(4,1fr)",
            gridGap: "10px",
            justifyContent: "center",
            padding: 5,
          }}
          disabled={!dragMode}
        >
          {setting
            ? setting.map((setting, index) => {
                let settings = JSON.parse(setting.settings);
                switch (CHART_TYPE[settings.chartType]) {
                  case 0:
                    return (
                      <Box
                        // height={500 * settings?.chartRow ?? 1}
                        // width={
                        //   isSmallDevice
                        //     ? boxWidth(1)
                        //     : boxWidth(settings?.chartColumn ?? 1)
                        // }
                        sx={{
                          border: 1,
                          p: "5px",
                          borderStyle: dragMode ? "dashed" : "solid",
                          gridColumnEnd: isSmallDevice
                            ? "span 1"
                            : `span ${settings.chartColumn}`,
                          gridRowEnd: isSmallDevice
                            ? "span 1"
                            : `span ${settings.chartRow}`,
                          overflow: "auto",
                        }}
                        key={index}
                      >
                        <BarChart
                          settings={setting}
                          settingsSID={setting.sid}
                          editFunction={handleEditDialogOnOpen}
                          isMidDevice={isSmallDevice}
                          GetAxisData={GetAxisData}
                        />
                      </Box>
                    );
                  case 1:
                    return (
                      <Box
                        // height={500 * settings?.chartRow ?? 1}
                        // width={
                        //   isSmallDevice
                        //     ? boxWidth(1)
                        //     : boxWidth(settings?.chartColumn ?? 1)
                        // }
                        sx={{
                          border: 1,
                          p: "5px",
                          borderStyle: dragMode ? "dashed" : "solid",
                          gridColumnEnd: isSmallDevice
                            ? "span 1"
                            : `span ${settings.chartColumn}`,
                          gridRowEnd: isSmallDevice
                            ? "span 1"
                            : `span ${settings.chartRow}`,
                          overflow: "auto",
                        }}
                        key={index}
                      >
                        <PieChart
                          settings={setting}
                          settingsSID={setting.sid}
                          editFunction={handleEditDialogOnOpen}
                          isMidDevice={isSmallDevice}
                        />
                      </Box>
                    );
                  case 2:
                    return (
                      <Box
                        // height={500 * settings?.chartRow ?? 1}
                        // width={
                        //   isSmallDevice
                        //     ? boxWidth(1)
                        //     : boxWidth(settings?.chartColumn ?? 1)
                        // }
                        sx={{
                          border: 1,
                          p: "5px",
                          borderStyle: dragMode ? "dashed" : "solid",
                          gridColumnEnd: isSmallDevice
                            ? "span 1"
                            : `span ${settings.chartColumn}`,
                          gridRowEnd: isSmallDevice
                            ? "span 1"
                            : `span ${settings.chartRow}`,
                          overflow: "auto",
                        }}
                        key={index}
                      >
                        <ListChart
                          settings={setting}
                          settingsSID={setting.sid}
                          editFunction={handleEditDialogOnOpen}
                          setLoadingSpinnerLoad={setLoading}
                        />
                      </Box>
                    );
                  default:
                    return null;
                }
              })
            : null}
        </ReactSortable>
      </Grid>

      <Dialog
        open={editSettingDialog}
        onClose={() => {
          setEditSettingDialog(false);
          if (isEditDashboardSetting) {
            setIsEditDashboardSetting(false);
          }
          handleEditDialogOnClose();
        }}
        maxWidth={"md"}
        fullScreen={isSmallDevice}
      >
        <DialogTitle>
          {isEditDashboardSetting
            ? t("dashboard.edit_setting_title")
            : t("dashboard.add_setting_title")}
        </DialogTitle>
        <DialogContent>
          <TabContext value={tab}>
            <TabPanel value="1" sx={{ p: 0 }}>
              <Grid container textAlign={"center"} spacing={1} mt={1}>
                {[
                  {
                    label: t("dashboard.chart_name"),
                    settingProps: "chartName",
                    options: [],
                  },
                  {
                    label: t("dashboard.chart_type"),
                    settingProps: "chartType",
                    options: chart,
                  },
                  {
                    label: t("dashboard.case_template"),
                    settingProps: "CaseTplUUID",
                    options: caseTplOption,
                  },

                  {
                    label: t("dashboard.column"),
                    settingProps: "chartColumn",
                    options: [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                    ],
                  },
                  {
                    label: t("dashboard.row"),
                    settingProps: "chartRow",
                    options: [
                      { label: 1, value: 1 },
                      { label: 2, value: 2 },
                      { label: 3, value: 3 },
                      { label: 4, value: 4 },
                    ],
                  },
                ].map((e, index) => {
                  if (e.settingProps === "chartName") {
                    return (
                      <Grid item md={6} xs={12} key={e.label}>
                        <TextField
                          value={dashboardSetting?.[e.settingProps]}
                          label={e.label}
                          fullWidth
                          onChange={(event) => {
                            handleSettingChange(
                              e.settingProps,
                              event.target.value,
                              null
                            );
                            handleEmptyCheck(
                              e.settingProps,
                              event.target.value
                            );
                          }}
                          required
                        ></TextField>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid
                        item
                        md={e.settingProps === "CaseTplUUID" ? 12 : 6}
                        xs={12}
                        key={e.label}
                        textAlign={"start"}
                      >
                        <FormControl fullWidth>
                          <InputLabel id={dashboardSetting[e.settingProps]}>
                            {e.label}
                          </InputLabel>
                          <Select
                            fullWidth
                            value={
                              e.settingProps === "CaseTplUUID"
                                ? request[e.settingProps]
                                : dashboardSetting[e.settingProps]
                            }
                            id={dashboardSetting[e.settingProps]}
                            onChange={(event) => {
                              if (e.settingProps === "CaseTplUUID") {
                                GetExistingReportOnChange(
                                  event,
                                  event.target.value
                                );
                                handleRequestChange(
                                  e.settingProps,
                                  event.target.value
                                );
                              } else {
                                handleSettingChange(
                                  e.settingProps,
                                  event.target.value,
                                  null
                                );
                              }
                              handleEmptyCheck(
                                e.settingProps,
                                event.target.value
                              );
                            }}
                            input={<OutlinedInput label={e.label} />}
                          >
                            {e.options.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <ListItemText primary={option.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    );
                  }
                })}

                {request.CaseTplUUID ? (
                  <Grid item xs={12} textAlign={"start"}>
                    {reportLoading ? (
                      <Skeleton width={"100%"} height={"80px"} />
                    ) : (
                      <FormControl fullWidth>
                        <InputLabel id={"CaseTpl"}>
                          {t("dashboard.report")}
                        </InputLabel>
                        <Select
                          fullWidth
                          value={request.TplSID}
                          id={"CaseTpl"}
                          onChange={(event) => {
                            handleSIDChange(event, event.target.value);
                            handleRequestChange("TplSID", event.target.value);

                            handleEmptyCheck("TplSID", event.target.value);
                          }}
                          input={
                            <OutlinedInput label={t("dashboard.report")} />
                          }
                        >
                          {reportOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                ) : null}

                {reportLoading ? null : (
                  <Grid container item xs={12}>
                    {/* {dynamicLists.length > 0 ? (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="dateType">{"Datagroup"}</InputLabel>
                          <Select
                            fullWidth
                            id={"dateType"}
                            value={request.DynamicListName}
                            input={<OutlinedInput label={"Datagroup"} />}
                            onChange={(event) => {
                              handleRequestChange(
                                `DynamicListName`,
                                event.target.value
                              );
                            }}
                          >
                            {dynamicLists.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                                disabled={Object.keys(dashboardSetting.x)
                                  .map((key) => key.split(".")[0])
                                  .includes(option.value)}
                              >
                                <ListItemText primary={option.label} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    ) : null} */}

                    {[
                      {
                        label: t("dashboard.x_axis"),
                        settingProps: "x",
                        options: axis,
                      },
                      {
                        label: t("dashboard.y_axis"),
                        settingProps: "y",
                        options: axis,
                      },
                    ].map((xy) => {
                      if (request.CaseTplUUID) {
                        return (
                          <>
                            {Object.keys(dashboardSetting[xy.settingProps])
                              ?.length >= 0
                              ? Object.keys(dashboardSetting[xy.settingProps])
                                  .slice(
                                    levelRowsPerPage *
                                      levelCurrentPage[xy.settingProps],
                                    levelRowsPerPage *
                                      levelCurrentPage[xy.settingProps] +
                                      levelRowsPerPage
                                  )
                                  .map((x, childIndex) => {
                                    return (
                                      <Grid
                                        container
                                        item
                                        xs={12}
                                        mt={"5px"}
                                        spacing={1}
                                      >
                                        <Grid
                                          item
                                          md={3}
                                          xs={12}
                                          key={childIndex}
                                          textAlign={"start"}
                                        >
                                          <FormControl fullWidth>
                                            <InputLabel id="level">
                                              {levelRowsPerPage *
                                                levelCurrentPage[
                                                  xy.settingProps
                                                ] +
                                                childIndex ===
                                              0
                                                ? xy.label
                                                : t("dashboard.level").includes(
                                                    "X"
                                                  )
                                                ? t("dashboard.level")
                                                    .split("X")
                                                    .join(
                                                      (
                                                        levelRowsPerPage *
                                                          levelCurrentPage[
                                                            xy.settingProps
                                                          ] +
                                                        childIndex +
                                                        1
                                                      ).toString()
                                                    )
                                                : t("dashboard.level") +
                                                  (
                                                    levelRowsPerPage *
                                                      levelCurrentPage[
                                                        xy.settingProps
                                                      ] +
                                                    childIndex +
                                                    1
                                                  ).toString()}
                                            </InputLabel>
                                            <Select
                                              disabled={
                                                request.TplSID ? false : true
                                              }
                                              value={
                                                x.includes(".")
                                                  ? x.split(".")[0]
                                                  : x
                                              }
                                              onChange={(event) => {
                                                handleSettingChange(
                                                  xy.settingProps,
                                                  event.target.value,
                                                  levelRowsPerPage *
                                                    levelCurrentPage[
                                                      xy.settingProps
                                                    ] +
                                                    childIndex
                                                );
                                                setCriteriaChanged(
                                                  !criteriaChanged
                                                );
                                                handleEmptyCheck(
                                                  xy.settingProps,
                                                  event.target.value
                                                );
                                              }}
                                              input={
                                                <OutlinedInput
                                                  label={
                                                    levelRowsPerPage *
                                                      levelCurrentPage[
                                                        xy.settingProps
                                                      ] +
                                                      childIndex ===
                                                    0
                                                      ? xy.label
                                                      : t(
                                                          "dashboard.level"
                                                        ).includes("X")
                                                      ? t("dashboard.level")
                                                          .split("X")
                                                          .join(
                                                            (
                                                              levelRowsPerPage *
                                                                levelCurrentPage[
                                                                  xy
                                                                    .settingProps
                                                                ] +
                                                              childIndex +
                                                              1
                                                            ).toString()
                                                          )
                                                      : t("dashboard.level") +
                                                        (
                                                          levelRowsPerPage *
                                                            levelCurrentPage[
                                                              xy.settingProps
                                                            ] +
                                                          childIndex +
                                                          1
                                                        ).toString()
                                                  }
                                                />
                                              }
                                            >
                                              {xy.settingProps === "x"
                                                ? levelRowsPerPage *
                                                    levelCurrentPage[
                                                      xy.settingProps
                                                    ] +
                                                    childIndex >
                                                  0
                                                  ? axis.map((row) => {
                                                      return (
                                                        <MenuItem
                                                          key={row.label}
                                                          value={row.value}
                                                          disabled={
                                                            Object.keys(
                                                              dashboardSetting.x
                                                            ).includes(
                                                              row.value
                                                            ) ||
                                                            Object.keys(
                                                              dashboardSetting.y
                                                            ).includes(
                                                              row.value
                                                            )
                                                          }
                                                        >
                                                          {row.label}
                                                        </MenuItem>
                                                      );
                                                    })
                                                  : axis
                                                      .filter(
                                                        (e) =>
                                                          e.value !== "none"
                                                      )
                                                      .map((row) => (
                                                        <MenuItem
                                                          key={row.label}
                                                          value={row.value}
                                                          disabled={
                                                            Object.keys(
                                                              dashboardSetting.x
                                                            ).includes(
                                                              row.value
                                                            ) ||
                                                            Object.keys(
                                                              dashboardSetting.y
                                                            ).includes(
                                                              row.value
                                                            )
                                                          }
                                                        >
                                                          <ListItemText
                                                            primary={row.label}
                                                          />
                                                        </MenuItem>
                                                      ))
                                                : axis
                                                    .filter((e) => {
                                                      if (
                                                        e.value !== "none" &&
                                                        GetAxisData(
                                                          "DataType",
                                                          e.value
                                                        ) === "Number"
                                                      ) {
                                                        return e;
                                                      }
                                                    })
                                                    .map((row) => (
                                                      <MenuItem
                                                        key={row.label}
                                                        value={row.value}
                                                        disabled={
                                                          Object.keys(
                                                            dashboardSetting.x
                                                          ).includes(
                                                            row.value
                                                          ) ||
                                                          Object.keys(
                                                            dashboardSetting.y
                                                          ).includes(row.value)
                                                        }
                                                      >
                                                        <ListItemText
                                                          primary={row.label}
                                                        />
                                                      </MenuItem>
                                                    ))}
                                            </Select>
                                          </FormControl>
                                        </Grid>
                                        <Grid item md={3} xs={12}>
                                          <TextField
                                            value={
                                              dashboardSetting[xy.settingProps][
                                                x
                                              ]
                                            }
                                            disabled={
                                              Object.keys(x)[0] ? false : true
                                            }
                                            label={
                                              levelRowsPerPage *
                                                levelCurrentPage[
                                                  xy.settingProps
                                                ] +
                                                childIndex ===
                                              0
                                                ? xy.settingProps === "x"
                                                  ? t("dashboard.x_axis_rename")
                                                  : t("dashboard.y_axis_rename")
                                                : t("dashboard.level_rename")
                                                    .split("X")
                                                    .join(
                                                      (
                                                        levelRowsPerPage *
                                                          levelCurrentPage[
                                                            xy.settingProps
                                                          ] +
                                                        childIndex +
                                                        1
                                                      ).toString()
                                                    )
                                            }
                                            fullWidth
                                            onChange={(event) => {
                                              handleSettingChange(
                                                xy.settingProps + "_rename",
                                                event.target.value,
                                                levelRowsPerPage *
                                                  levelCurrentPage[
                                                    xy.settingProps
                                                  ] +
                                                  childIndex
                                              );
                                            }}
                                          ></TextField>
                                        </Grid>
                                        {GetAxisData(
                                          "DataType",
                                          x.includes(".") ? x.split(".")[0] : x
                                        ) === "Date" ? (
                                          <Grid
                                            container
                                            item
                                            md={3}
                                            xs={12}
                                            textAlign={"center"}
                                            key={xy.label}
                                          >
                                            <Grid item xs={12}>
                                              <FormControl fullWidth>
                                                <InputLabel id="dateType">
                                                  {t("dashboard.unit")}
                                                </InputLabel>
                                                <Select
                                                  fullWidth
                                                  id={"dateType"}
                                                  value={
                                                    x.includes(".")
                                                      ? x.split(".")[1]
                                                      : null
                                                  }
                                                  input={
                                                    <OutlinedInput
                                                      label={t(
                                                        "dashboard.unit"
                                                      )}
                                                    />
                                                  }
                                                  onChange={(event) => {
                                                    handleSettingChange(
                                                      `dynamicDay_${xy.settingProps}`,
                                                      event.target.value,
                                                      levelRowsPerPage *
                                                        levelCurrentPage[
                                                          xy.settingProps
                                                        ] +
                                                        childIndex
                                                    );
                                                  }}
                                                >
                                                  {dateUnit.map((option) => (
                                                    <MenuItem
                                                      key={option.value}
                                                      value={option.value}
                                                      disabled={Object.keys(
                                                        dashboardSetting[
                                                          xy.settingProps
                                                        ]
                                                      )
                                                        .map(
                                                          (key) =>
                                                            key.split(".")[1]
                                                        )
                                                        .includes(option.value)}
                                                    >
                                                      <ListItemText
                                                        primary={option.label}
                                                      />
                                                    </MenuItem>
                                                  ))}
                                                </Select>
                                              </FormControl>
                                            </Grid>
                                          </Grid>
                                        ) : (
                                          <Grid item md={3} />
                                        )}
                                      </Grid>
                                    );
                                  })
                              : null}
                            <Grid container item xs={12} key={xy.settingProps}>
                              <Grid item>
                                {xy.settingProps === "x" &&
                                Object.keys(dashboardSetting[xy.settingProps])
                                  .length > 0 ? (
                                  <Pagination
                                    count={levelPage[xy.settingProps]}
                                    onChange={(event, value) =>
                                      handleLevelPageChange(
                                        event,
                                        value,
                                        xy.settingProps
                                      )
                                    }
                                  />
                                ) : null}
                              </Grid>
                            </Grid>
                          </>
                        );
                      }
                    })}
                  </Grid>
                )}

                <Grid item xs={12} textAlign={"start"}>
                  {request.CaseTplUUID ? (
                    <Button
                      onClick={() => {
                        setTab("2");
                      }}
                      variant="outlined"
                      disabled={!request.TplSID || !axis.length > 0}
                    >
                      {t("dashboard.filter_criteria")}
                    </Button>
                  ) : null}
                </Grid>

                <Grid item xs={12} textAlign={"start"} mt={1}>
                  {FunctionGenerator(dashboardSetting.chartType)}
                </Grid>
                <Grid item>
                  <FormControl component={"fieldset"} variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        checked={request.IsPreviewReport}
                        control={<Checkbox />}
                        onClick={(e) =>
                          handleRequestChange(
                            "IsPreviewReport",
                            e.target.checked
                          )
                        }
                        label={t("dashboard.preview_report")}
                      />
                    </FormGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <Grid
                container
                textAlign={"center"}
                direction={"column"}
                justifyContent={"center"}
                spacing={1}
              >
                <Grid item container textAlign={"start"} alignItems={"center"}>
                  <Grid item>
                    <IconButton
                      onClick={() => {
                        setTab("1");
                        setRequest(GetFilterCriteria(request));
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} textAlign={"start"}>
                    <Typography variant="p">
                      {t("dashboard.dynamic_date_setting")}
                    </Typography>
                  </Grid>

                  <Grid container xs={12} height={"250px"} overflow={"auto"}>
                    <Grid container xs={12} direction={"column"}>
                      <Grid item xs={12}>
                        <FormControl sx={{ textAlign: "start" }}>
                          <RadioGroup
                            row
                            defaultValue={dynamicDate[0].type}
                            // value={PrimaryPositionObj[c.SID] ?? null}
                            // name={c.Name}
                            onChange={(e) =>
                              handleDynamicDayChange(
                                e.target.value,
                                "type",
                                0,
                                null
                              )
                            }
                          >
                            {dynamicDateType.map((p) => (
                              <FormControlLabel
                                value={p}
                                control={<Radio />}
                                label={p} //add translation for that
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {dynamicDate.map((d, i) => (
                        <>
                          <Grid
                            container
                            xs={12}
                            direction={"row"}
                            spacing={1}
                            justifyContent={isSmallDevice ? "center" : "start"}
                            alignItems={"center"}
                            textAlign={isSmallDevice ? "center" : "start"}
                          >
                            <Grid item xs={12} md={3}>
                              <FormControl
                                fullWidth
                                // sx={{ width: key === "" ? "150px" : "auto" }}
                              >
                                <InputLabel id="axis">
                                  {t(
                                    "administrative_console.report_page.dialog_report"
                                  )}
                                </InputLabel>
                                <Select
                                  fullWidth
                                  id={"axis"}
                                  value={d.columnName.split(".")[0]}
                                  input={
                                    <OutlinedInput
                                      label={t(
                                        "administrative_console.report_page.dialog_report"
                                      )}
                                    />
                                  }
                                  onChange={(e) =>
                                    handleDynamicDayChange(
                                      e.target.value,
                                      "columnName",
                                      i,
                                      null
                                    )
                                  }
                                >
                                  {axis.map((option) => {
                                    let allow = GetAxisData(
                                      "AllowCriteria",
                                      option.value
                                    );
                                    let type = GetAxisData(
                                      "DataType",
                                      option.value
                                    );
                                    if (
                                      (allow && type === "Date") ||
                                      option.value == "none"
                                    ) {
                                      return (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                          disabled={
                                            Object.keys(dynamicDate).includes(
                                              option.value + ".year"
                                            ) &&
                                            Object.keys(dynamicDate).includes(
                                              option.value + ".season"
                                            ) &&
                                            Object.keys(dynamicDate).includes(
                                              option.value + ".month"
                                            ) &&
                                            Object.keys(dynamicDate).includes(
                                              option.value + ".day"
                                            )
                                          }
                                        >
                                          <ListItemText
                                            primary={option.label}
                                          />
                                        </MenuItem>
                                      );
                                    }
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            {d.type === dynamicDateType[1] ? (
                              <Grid item xs={12} md={2}>
                                <FormControl fullWidth>
                                  <InputLabel id="dateType">
                                    {t("dashboard.unit")}
                                  </InputLabel>
                                  <Select
                                    fullWidth
                                    id={"dateType"}
                                    value={d.columnName.split(".")[1] ?? ""}
                                    // disabled={d.columnName.split(".")[0] ?? false}
                                    input={
                                      <OutlinedInput
                                        label={t("dashboard.unit")}
                                      />
                                    }
                                    onChange={(e) =>
                                      handleDynamicDayChange(
                                        e.target.value,
                                        "unit",
                                        i
                                      )
                                    }
                                  >
                                    {dateUnit.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        // disabled={Object.keys(dynamicDate).includes(
                                        //   date.includes(".")
                                        //     ? date
                                        //     : date + "." + option.value
                                        // )}
                                      >
                                        <ListItemText primary={option.label} />
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            ) : null}

                            {d.type === dynamicDateType[0] ? (
                              <Grid item xs={12} md={3}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    disabled={d.columnName === ""}
                                    value={
                                      d?.range[0] ?? false
                                        ? dayjs(d.range[0])
                                        : null
                                    }
                                    sx={{ width: "100%" }}
                                    onChange={(e) =>
                                      handleDynamicDayChange(
                                        dayjs(e.$d).startOf("day"),
                                        "range",
                                        i,
                                        0
                                      )
                                    }
                                    slotProps={{
                                      actionBar: {
                                        actions: ["today"],
                                      },
                                    }}
                                    views={["year", "month", "day"]}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            ) : (
                              <Grid item xs={"auto"}>
                                <TextField
                                  sx={{
                                    width: isSmallDevice ? "180px" : "100px",
                                  }}
                                  type="number"
                                  value={d?.range[0]}
                                  onChange={(e) =>
                                    handleDynamicDayChange(
                                      parseInt(e.target.value),
                                      "range",
                                      i,
                                      0
                                    )
                                  }
                                ></TextField>
                              </Grid>
                            )}
                            <Grid
                              item
                              xs={d.type === dynamicDateType[0] ? 12 : "auto"}
                              md={"auto"}
                            >
                              {isSmallDevice &&
                              d.type === dynamicDateType[0] ? (
                                <ArrowDownward />
                              ) : (
                                <ArrowForward />
                              )}
                            </Grid>
                            {d.type === dynamicDateType[0] ? (
                              <Grid item xs={12} md={3}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    sx={{ width: "100%" }}
                                    disabled={
                                      d.columnName === "" ||
                                      (d?.range[1] ?? null) === "Now"
                                    }
                                    value={
                                      d.range[1]
                                        ? dayjs()
                                        : d?.range[1] ?? false
                                        ? dayjs(d.range[1])
                                        : null
                                    }
                                    onChange={(e) =>
                                      handleDynamicDayChange(
                                        dayjs(e.$d).endOf("day"),
                                        "range",
                                        i,
                                        1
                                      )
                                    }
                                    views={["year", "month", "day"]}
                                    slotProps={{
                                      actionBar: {
                                        actions: ["today"],
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            ) : (
                              <Grid item xs={"auto"}>
                                <TextField
                                  sx={{
                                    width: isSmallDevice ? "180px" : "100px",
                                  }}
                                  disabled={
                                    d.columnName === "" ||
                                    (d?.range[1] ?? null) === 0
                                  }
                                  type="number"
                                  value={d?.range[1]}
                                  onChange={(e) => {
                                    handleDynamicDayChange(
                                      parseInt(e.target.value),
                                      "range",
                                      i,
                                      1
                                    );
                                  }}
                                ></TextField>
                              </Grid>
                            )}
                            <Grid item xs={12} md={"auto"}>
                              <FormControl
                                component="fieldset"
                                variant="standard"
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          d.type === dynamicDateType[0]
                                            ? d?.range[1] == "Now"
                                            : d?.range[1] == 0
                                        }
                                        disabled={d.columnName === ""}
                                      />
                                    }
                                    label="until now"
                                    onChange={(e, checked) => {
                                      let trueValue =
                                        d.type === dynamicDateType[0]
                                          ? "Now"
                                          : 0;
                                      let falseValue =
                                        d.type === dynamicDateType[0] ? "" : 1;
                                      if (checked) {
                                        handleDynamicDayChange(
                                          trueValue,
                                          "range",
                                          i,
                                          1
                                        );
                                      } else {
                                        handleDynamicDayChange(
                                          falseValue,
                                          "range",
                                          i,
                                          1
                                        );
                                      }
                                    }}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>

                  <Grid item>
                    <Typography variant="p">
                      {t("dashboard.filter_criteria")}
                    </Typography>
                  </Grid>
                </Grid>

                <DashboardCriteriaComboBox
                  ref={criteriaComboBoxRef}
                  parentFilterCriteria={
                    request?.FilterCriteria ??
                    JSON.stringify({ [""]: JSON.stringify({ [""]: [""] }) })
                  }
                  rowsPerPage={rowsPerPage}
                  dropdownDataObj={systemColumnExtraData}
                  axis={axis}
                  GetAxisData={GetAxisData}
                />
              </Grid>
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <Grid container item>
            {isEditDashboardSetting ? (
              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  DeleteSetting();
                }}
              >
                {t("dashboard.delete_button")}
              </Button>
            ) : null}
          </Grid>
          <Grid container item spacing={1}>
            <Grid item>
              <LoadingButton
                loading={buttonLoading}
                variant="contained"
                disabled={
                  Object.values(checkedEmpty).includes(true) &&
                  !isEditDashboardSetting &&
                  dynamicDate.map((d) => d.columnName === "").includes(true)
                }
                onClick={() => {
                  // EditDashboardSettings("Edit");
                  if (isEditDashboardSetting) {
                    EditDashboardSetting("edit");
                  } else {
                    EditDashboardSetting("add");
                  }
                }}
              >
                {t("profile.proxy_and_delegation.save")}
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button color="error" onClick={() => handleEditDialogOnClose()}>
                {t("profile.proxy_and_delegation.cancel")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />

      <LoadingSpinner isOpen={loading} />
    </Grid>
  );
}
