import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneIcon from "@mui/icons-material/Done";
import PauseIcon from "@mui/icons-material/Pause";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import LoadingSpinner from "./LoadingSpinner";
import "../MailBox.css";
import { NoPermissionPage } from "../components/CaseList/CaseList";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

function MileStoneIcon(props) {
  const { state, isSmallDevice } = props;
  return state === 1 ? (
    <HourglassBottomIcon fontSize={isSmallDevice ? "small" : "medium"} />
  ) : state === 4 ? (
    <DoneIcon fontSize={isSmallDevice ? "small" : "medium"} />
  ) : state === 9 ? (
    <PauseIcon fontSize={isSmallDevice ? "small" : "medium"} />
  ) : state === 10 ? (
    <CloseIcon fontSize={isSmallDevice ? "small" : "medium"} />
  ) : null;
}

function CaseName(props) {
  const location = useLocation();
  const {
    planItemSID,
    caseSID,
    planItemName,
    caseNumber,
    isInbox,
    style,
    isSmallDevice,
  } = props;
  return (
    <TableCell
      colSpan={isSmallDevice ? (isInbox ? 2 : 3) : null}
      onClick={() => {
        localStorage.setItem(
          "startPageURL",
          location.pathname + location.search
        );
      }}
    >
      <Link
        to={`/case/${caseSID}/${planItemSID}`}
        style={{
          color: "gray",
          fontWeight: style,
          whiteSpace: "pre-wrap",
        }}
      >
        {planItemName}
        &nbsp; [{caseNumber}]
      </Link>
    </TableCell>
  );
}

function Time(props) {
  const { date, timeZone, milestone, isSmallDevice } = props;
  return (
    <TableCell
      className=".mail-date"
      sx={{
        textAlign: "right",
        borderBottom: isSmallDevice ? 0 : null,
      }}
    >
      {milestone}
      &nbsp; &nbsp; &nbsp;
      {dayjs.utc(date).tz(timeZone).format("DD/MM/YYYY HH:mm")}
    </TableCell>
  );
}

const InOutbox = (props) => {
  const { isInbox, objects, isLoading, noPermission, allSelected } = props;
  const setIsSelected = props.setIsSelected;
  const [selected, setSelected] = React.useState([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  dayjs.extend(utc);
  dayjs.extend(tz);

  const userTimezone = dayjs.tz.guess();

  const noRecordUrl = (
    <TableRow>
      <TableCell sx={{ borderBottom: "none" }}>
        <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
          {isLoading
            ? t("inbox_outbox.load_text")
            : t("inbox_outbox.no_record")}
        </Typography>
      </TableCell>
    </TableRow>
  );

  const handleClick = (event, name) => {
    const selectedIndex = selected?.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setIsSelected(newSelected);
  };
  React.useEffect(() => {
    setSelected(allSelected);
  }, [allSelected]);
  const isSelected = (name) => selected?.indexOf(name) !== -1;

  const rowStyle = {
    "&.Mui-selected": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? alpha(theme.palette.primary.main, 0.24)
          : alpha(theme.palette.primary.dark, 0.12),
    },
    "&.Mui-selected:hover": {
      backgroundColor:
        theme.palette.mode === "dark"
          ? alpha(theme.palette.primary.main, 0.24)
          : alpha(theme.palette.primary.dark, 0.12),
    },
  };
  return (
    <Grid container className="paper">
      <Grid item xs={12} sm={12}>
        <TableContainer>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              overflow: "auto",
              marginBottom: "40px",
            }}
          >
            <Table aria-labelledby="tableTitle" className="mail">
              <TableBody>
                {noPermission ? (
                  <NoPermissionPage />
                ) : objects?.length > 0 ? (
                  objects.map((item, index) => {
                    const style = isInbox
                      ? item.asRead
                        ? "normal"
                        : "bold"
                      : "normal";
                    const isItemSelected = isSelected(item.planItemSID);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return !isSmallDevice ? (
                      <TableRow
                        hover
                        onClick={
                          isInbox
                            ? (event) => handleClick(event, item.planItemSID)
                            : null
                        }
                        role={isInbox ? "checkbox" : null}
                        aria-checked={isInbox ? isItemSelected : null}
                        tabIndex={-1}
                        selected={isInbox ? isItemSelected : null}
                        key={item.planItemSID}
                        className="mail-item"
                      >
                        <TableCell
                          padding="checkbox"
                          sx={{ textAlign: "center" }}
                        >
                          {isInbox ? (
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                              className="padding-left"
                              sx={{ padding: 0 }}
                            />
                          ) : (
                            <MileStoneIcon
                              state={item.state}
                              isSmallDevice={isSmallDevice}
                            />
                          )}
                        </TableCell>

                        <TableCell className="mail-user" id={labelId}>
                          {item.initiatorName}
                        </TableCell>

                        <CaseName
                          planItemSID={item.planItemSID}
                          caseSID={item.caseSID}
                          planItemName={item.planItemName}
                          caseNumber={item.caseNumber}
                          isInbox={isInbox}
                          isSmallDevice={isSmallDevice}
                          style={style}
                        />

                        <Time
                          date={isInbox ? item.startDate : item.endDate}
                          timeZone={userTimezone}
                          milestone={item.milestone}
                          isSmallDevice={isSmallDevice}
                        />
                      </TableRow>
                    ) : (
                      <>
                        <TableRow
                          onClick={
                            isInbox
                              ? (event) => handleClick(event, item.planItemSID)
                              : null
                          }
                          role="checkbox"
                          aria-checked={isInbox ? isItemSelected : null}
                          tabIndex={-1}
                          selected={isInbox ? isItemSelected : null}
                          key={item.planItemID}
                          className="mail-item"
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "5px 5px 0px 0px",
                            },
                            ...rowStyle,
                          }}
                        >
                          <TableCell
                            padding="checkbox"
                            sx={{ textAlign: "center" }}
                            rowSpan={2}
                          >
                            {isInbox ? (
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                                className="padding-left"
                                size="small"
                              />
                            ) : (
                              <MileStoneIcon
                                state={item.state}
                                isSmallDevice={isSmallDevice}
                              />
                            )}
                          </TableCell>

                          <TableCell
                            className="mail-user"
                            id={labelId}
                            sx={{ borderBottom: 0 }}
                          >
                            {item.initiatorName}
                          </TableCell>

                          <Time
                            date={isInbox ? item.startDate : item.endDate}
                            timeZone={userTimezone}
                            milestone={item.milestone}
                            isSmallDevice={isSmallDevice}
                          />
                        </TableRow>
                        <TableRow
                          onClick={
                            isInbox
                              ? (event) => handleClick(event, item.planItemSID)
                              : null
                          }
                          role={isInbox ? "checkbox" : null}
                          aria-checked={isInbox ? isItemSelected : null}
                          tabIndex={-1}
                          selected={isInbox ? isItemSelected : null}
                          key={item.planItemSID}
                          className="mail-item"
                          sx={{
                            "& .MuiTableCell-root": {
                              padding: "0px 0px 5px",
                            },
                            ...rowStyle,
                          }}
                        >
                          <CaseName
                            planItemSID={item.planItemSID}
                            caseSID={item.caseSID}
                            planItemName={item.planItemName}
                            caseNumber={item.caseNumber}
                            isInbox={isInbox}
                            isSmallDevice={isSmallDevice}
                          />
                        </TableRow>
                      </>
                    );
                  })
                ) : (
                  noRecordUrl
                )}
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>
        <LoadingSpinner isOpen={isLoading}></LoadingSpinner>
      </Grid>
    </Grid>
  );
};

InOutbox.propTypes = {
  isInbox: PropTypes.bool.isRequired,
  objects: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  noPermission: PropTypes.bool.isRequired,
  setIsSelected: PropTypes.func,
  allSelected: PropTypes.array,
};

export default InOutbox;
