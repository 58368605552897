import React from "react";
import {
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Handle,
  Position,
  BaseEdge,
  EdgeLabelRenderer,
  getSmoothStepPath,
  useReactFlow,
  useViewport,
  ConnectionLineType,
} from "reactflow";
import EmailIcon from "@mui/icons-material/Email";
import "./Flow.css";
import { Grid, Slider, Tooltip } from "@mui/material";
import { useTheme } from "@emotion/react";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import StopIcon from "@mui/icons-material/Stop";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CancelIcon from "@mui/icons-material/Cancel";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import CheckIcon from "@mui/icons-material/Check";
import { borderRadius } from "@mui/system";
function CustomNode({ data }) {
  const theme = useTheme();
  const taskUser = [];
  for (let i = 0; i < data.taskUser?.length; i++) {
    if (i <= data.loopTimes && data.taskUser[i]?.logUserName) {
      taskUser.push(data.taskUser[i]?.logUserName);
    }
  }
  for (let i = 0; i < data.currentUser?.length; i++) {
    if (i <= data.loopTimes && data.currentUser[i]?.logUserName) {
      taskUser.push(data.currentUser[i]?.logUserName);
    }
  }
  return (
    <>
      {data.flowTask?.some((f) => f.planItemSID == data.planItemSID) ? (
        data.state == "1" ||
        data.state == "4" ||
        data.state == "3" ||
        data.state == "10" ? (
          <div
            className={data.id == "return" ? "icon-left" : "icon-right"}
            style={{
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            }}
          >
            {data.state == "1" ? (
              <HourglassBottomIcon fontSize="small" />
            ) : data.state == "4" ? (
              <CheckIcon fontSize="small" />
            ) : data.state == "3" ? (
              <CancelIcon fontSize="small" />
            ) : data.state == "10" ? (
              <DoDisturbOnIcon fontSize="small" />
            ) : null}
          </div>
        ) : null
      ) : null}
      {data.loopTimes > 0 ? (
        <div className="loop_times">{data.loopTimes}</div>
      ) : null}
      <div
        className="react-flow__node-default"
        style={{
          borderColor: theme.palette.flowEdge[theme.palette.mode],
        }}
      >
        <Handle
          type="target"
          position={data.id == "return" ? Position.Right : Position.Left}
        />
        <Handle
          type="source"
          position={data.id == "return" ? Position.Left : Position.Right}
        />
        <p>
          <label
            style={{
              color: theme.palette.flowEdge[theme.palette.mode],
            }}
          >
            {data.label}
          </label>
          {taskUser.length > 0 ? (
            taskUser.length > 3 ? (
              <Tooltip
                title={
                  <span style={{ whiteSpace: "pre-line" }}>
                    {taskUser?.map((name, index) => {
                      return (
                        <label
                          style={{
                            color: theme.palette.flowEdge[theme.palette.mode],
                          }}
                        >
                          {`${index + 1}.${name}`}
                          <br />
                        </label>
                      );
                    })}
                  </span>
                }
                placement="bottom"
                enterTouchDelay={300}
              >
                <br />
                Task User
              </Tooltip>
            ) : (
              taskUser?.map((name, index) => {
                return (
                  <label
                    style={{
                      color: theme.palette.flowEdge[theme.palette.mode],
                    }}
                  >
                    <br />
                    {`${index + 1}.${name}`}
                  </label>
                );
              })
            )
          ) : null}
        </p>
      </div>
      {data.emailName?.length > 0 ? (
        <div
          className={data.id == "return" ? "icon-left" : "icon-right"}
          style={{
            backgroundColor:
              theme.palette.secondaryContainer[theme.palette.mode],
          }}
        >
          <Tooltip
            title={
              <span style={{ whiteSpace: "pre-line" }}>{data.emailName}</span>
            }
            placement="bottom"
            enterTouchDelay={300}
          >
            <EmailIcon fontSize="small" />
          </Tooltip>
        </div>
      ) : null}
    </>
  );
}

function Milestone({ data }) {
  const theme = useTheme();
  {
    return (
      <>
        {data.flowTask?.some((f) => f.planItemSID == data.planItemSID) ? (
          data.state == "4" || data.state == "3" || data.state == "10" ? (
            <div
              className="icon-right"
              style={{
                backgroundColor:
                  theme.palette.secondaryContainer[theme.palette.mode],
              }}
            >
              {data.state == "4" ? (
                <CheckIcon fontSize="small" />
              ) : data.state == "3" ? (
                <CancelIcon fontSize="small" />
              ) : data.state == "10" ? (
                <DoDisturbOnIcon fontSize="small" />
              ) : null}
            </div>
          ) : null
        ) : null}
        {data.loopTimes > 0 ? (
          <div className="loop_times">{data.loopTimes}</div>
        ) : null}
        <div
          className="react-flow__node-circle"
          style={{
            borderColor: theme.palette.flowEdge[theme.palette.mode],
          }}
        >
          <Handle type="target" position={Position.Bottom} />
          <p>
            <label
              style={{
                color: theme.palette.flowEdge[theme.palette.mode],
              }}
            >
              {data.label}
            </label>
          </p>
        </div>
      </>
    );
  }
}

function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  markerEnd,
  label,
  sourcePosition,
  targetPosition,
  source,
  target,
}) {
  const theme = useTheme();
  const [originalEdge] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  let y = "";
  let x = "";
  if (source !== target) {
    x = sourceX + 180;
    y = sourceY - 60;
    if (sourceY == targetY && targetX - sourceX > 250) {
      const edgePath = `M ${sourceX} ${sourceY}  L ${
        sourceX + 10
      } ${sourceY}L ${sourceX + 10} ${sourceY - 60} L ${targetX - 10} ${
        targetY - 60
      }L ${targetX - 10} ${targetY} L ${targetX} ${targetY}`;

      return (
        <>
          <BaseEdge
            path={edgePath}
            markerEnd={markerEnd}
            style={{ stroke: theme.palette.flowEdge[theme.palette.mode] }}
          />
          <EdgeLabelRenderer>
            <label
              style={{
                fontSize: 10,
                position: "absolute",
                transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
                backgroundColor:
                  theme.palette.secondaryContainer[theme.palette.mode],
                zIndex: "1",
              }}
            >
              {label}
            </label>
          </EdgeLabelRenderer>
        </>
      );
    } else {
      return (
        <>
          <BaseEdge
            path={originalEdge}
            markerEnd={markerEnd}
            style={{ stroke: theme.palette.flowEdge[theme.palette.mode] }}
          />
          <EdgeLabelRenderer>
            {label ? (
              <label
                style={{
                  fontSize: 10,
                  position: "absolute",
                  transform: ` translate(50%, -60%) translate(${sourceX}px,${sourceY}px)`,
                  backgroundColor:
                    theme.palette.secondaryContainer[theme.palette.mode],
                  zIndex: "1",
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                {label}
              </label>
            ) : null}
          </EdgeLabelRenderer>
        </>
      );
    }
  } else {
    x = sourceX - 75;
    y = sourceY - 80;
    const edgePath =
      targetX < sourceX
        ? `M ${sourceX} ${sourceY}  L ${sourceX + 10} ${sourceY}L ${
            sourceX + 10
          } ${sourceY - 80} L ${targetX - 10} ${targetY - 80}L ${
            targetX - 10
          } ${targetY} L ${targetX} ${targetY}`
        : `M ${sourceX} ${sourceY}  L ${sourceX - 10} ${sourceY}L ${
            sourceX - 10
          } ${sourceY - 80} L ${targetX + 10} ${targetY - 80}L ${
            targetX + 10
          } ${targetY} L ${targetX} ${targetY}`;

    return (
      <>
        <BaseEdge
          id={id}
          path={edgePath}
          markerEnd={markerEnd}
          style={{ stroke: "red" }}
        />
        <EdgeLabelRenderer>
          <label
            style={{
              fontSize: 10,
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            }}
          >
            {"Loop Back"}
          </label>
        </EdgeLabelRenderer>
      </>
    );
  }
}
function RejectEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  markerEnd,
  sourcePosition,
  targetPosition,
}) {
  const theme = useTheme();
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  let y = labelY;
  let x = labelX;
  if (!id.includes(1)) {
    y -= 100;
    x += 125 * (id?.match(/\d+/)[0] - 1);
  }

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: theme.palette.flowEdge[theme.palette.mode] }}
      />
      <EdgeLabelRenderer>
        <label
          style={{
            fontSize: 10,
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
            backgroundColor:
              theme.palette.secondaryContainer[theme.palette.mode],
          }}
        >
          {label}
        </label>
      </EdgeLabelRenderer>
    </>
  );
}
const nodeTypes = { customNode: CustomNode, milestone: Milestone };
const edgeTypes = {
  customEdge: CustomEdge,
  rejectEdge: RejectEdge,
};
function FlowChart(props) {
  const theme = useTheme();
  const { caseInfo, allTaskInfo, flowTask, activityLog, currentStageLog } =
    props;
  const stages = caseInfo?.stages;
  const task = allTaskInfo;
  const milestones = caseInfo?.milestones;
  const discretionaryTask = caseInfo?.moreTasks;
  const completedStyle = {
    //green
    backgroundColor: theme.palette.mode == "light" ? "#75f975" : "green",
  };
  const activeStyle = {
    //yellow
    backgroundColor: theme.palette.mode == "light" ? "#ffd600" : "#c7a700",
  };
  const returnStyle = {
    //red
    backgroundColor: theme.palette.mode == "light" ? "#ff3535" : "#9b0000",
  };

  const edgeProps = {
    type: "arrow",
    color: theme.palette.flowEdge[theme.palette.mode],
    width: 30,
    height: 20,
  };
  let params = [];
  let path = [];
  let flowTasks = [];
  let positionX = 250;
  let taskLength = 0;
  let milestoneLength = 0;
  let discretionaryTaskLength = 0;
  let allTasks = [];
  let packedTask = [];

  //Push alltask together
  if (task && milestones) {
    allTasks.push(...task);
    allTasks.push(...milestones);
  }
  //Map human task
  task?.map((task) => {
    if (task.type != 4) {
      flowTasks.push(task);
    }
  });

  //Stick email task and milestone after each human tasks
  for (let h = 0; h < flowTasks?.length; h++) {
    let orderedTasks = [];
    for (let i = 0; i < allTasks?.length; i++) {
      let taskIndex = [];
      let stop = false;
      if (i == 0) {
        orderedTasks.push(flowTasks[h]);
      } else {
        for (
          let a = 0;
          a < orderedTasks[i - 1].nextPlanItemTplSID?.length;
          a++
        ) {
          let nextIndex = allTasks?.findIndex(
            (t) =>
              orderedTasks[i - 1].nextPlanItemTplSID[a] == t.planItemTemplateSid
          );
          if (
            allTasks[nextIndex]?.type == 0 ||
            allTasks[nextIndex]?.type == 3 ||
            allTasks[nextIndex]?.type == 5 ||
            (orderedTasks[i - 1].nextPlanItemTplSID?.length == 1 &&
              nextIndex == -1)
          ) {
            stop = true;
          } else {
            taskIndex.push(nextIndex);
          }
        }
        if (stop || orderedTasks[i - 1].nextPlanItemTplSID?.length == 0) {
          break;
        } else {
          for (let b = 0; b < taskIndex?.length; b++) {
            if (taskIndex[b] != -1) {
              orderedTasks.push(allTasks[taskIndex[b]]);
            }
          }
        }
      }
    }
    orderedTasks.sort((a, b) => a.planItem?.tplID - b.planItem?.tplID);
    packedTask.push(orderedTasks);
  }

  let emailName = [];
  for (let i = 0; i < packedTask?.length; i++) {
    let currentTaskEmail = [];
    for (let l = 0; l < packedTask[i].length; l++) {
      if (packedTask[i][l]?.type == 4) {
        currentTaskEmail.push(`${packedTask[i][l]?.name}\n`);
      }
    }
    emailName.push(currentTaskEmail);
  }

  //Path to Approval tasks
  path.push({
    id: `Case`,
    source: "case",
    target: task ? "0" : "task",
    label: "Submit",
    type: "customEdge",
    markerEnd: edgeProps,
  });

  //Task Nodes
  if (task) {
    //Generate task for running case
    for (let i = 0; i < flowTasks.length; i++) {
      //Case start
      if (flowTasks[i].name.toLowerCase() == "returned form") {
        // Returned Form
        params.push({
          id: "return",
          position: { x: 300, y: 350 },
          data: {
            label: "Returned form",
            id: "return",
            emailName: emailName[i],
            userActions: flowTasks[i].userActions,
            loopTimes:
              flowTask.filter((f) => f.name == "Returned form").length > 0
                ? flowTask.filter((f) => f.name == "Returned form").length - 1
                : 0,
          },
          type: "customNode",
          style:
            caseInfo?.currentPlanItemSid == flowTasks[i].planItemSID
              ? returnStyle
              : {
                  backgroundColor:
                    theme.palette.secondaryContainer[theme.palette.mode],
                },
        });
      } else {
        //Tasks
        params.push({
          id: `${taskLength}`,
          position: { x: 50 + taskLength * positionX, y: 150 },
          data: {
            label: flowTasks[i]?.name,
            emailName: emailName[i],
            flowTask: flowTask,
            planItemSID: flowTasks[i].planItemSID,
            planItemTemplateSid: flowTasks[i].planItemTemplateSid,
            userActions: flowTasks[i].userActions,
            state: flowTasks[i].currentState,
            loopTimes:
              flowTask?.filter((f) => f.name == flowTasks[i]?.name).length > 0
                ? flowTask?.filter((f) => f.name == flowTasks[i]?.name).length -
                  1
                : 0,
            currentUser:
              currentStageLog.length > 0
                ? currentStageLog?.filter(
                    (c) => c.taskName == flowTasks[i]?.name
                  )
                : null,
            taskUser: activityLog?.filter(
              (a) => a.planItemTplID == flowTasks[i]?.planItemTemplateID
            ),
          },
          type: "customNode",
          style:
            flowTasks[i]?.planItemSID == caseInfo.currentPlanItemSid ||
            flowTasks[i]?.currentState == 1
              ? flowTasks[i]?.currentState == 1
                ? activeStyle
                : completedStyle
              : {
                  backgroundColor:
                    theme.palette.secondaryContainer[theme.palette.mode],
                },
        });
        taskLength++;
      }
      //milestones
      for (let l = 0; l < packedTask[i].length; l++) {
        for (let m = 0; m < milestones.length; m++) {
          if (
            packedTask[i][l]?.planItemTemplateSid ==
              milestones[m].planItemTemplateSid &&
            !params.some((p) => p.id == `m${m}`)
          ) {
            params.push({
              id: `m${milestoneLength}`,
              className: "react-flow__node_milestones",
              position: {
                x: params[
                  params.findIndex((p) =>
                    packedTask[i].some(
                      (t) => t.planItemTemplateSid == p.data.planItemTemplateSid
                    )
                  )
                ]?.position.x
                  ? params[
                      params.findIndex((p) =>
                        packedTask[i].some(
                          (t) =>
                            t.planItemTemplateSid == p.data.planItemTemplateSid
                        )
                      )
                    ]?.position.x + 140
                  : 0 + 140,
                y: 0,
              },
              data: {
                label: milestones[m].name,
                state: milestones[m].state,
                planItemSID: milestones[m].planItemSID,
                loopTimes:
                  flowTask.filter((f) => f.name == milestones[m].name).length >
                  0
                    ? flowTask.filter((f) => f.name == milestones[m].name)
                        .length - 1
                    : 0,
                flowTask: flowTask,
              },
              targetPosition: "bottom",
              type: "milestone",
            });
            //milestones path
            path.push({
              id: `m${milestoneLength}`,
              source: `${params[i + milestoneLength]?.id}`,
              target: `m${milestoneLength}`,
              type: "customEdge",
            });
            milestoneLength++;
          }
        }
      }
    }

    //Generate path
    for (let i = 0; i <= taskLength - 1; i++) {
      //Approval task path
      path.push({
        id: `t${i}`,
        source: `${i}`,
        target: i == taskLength - 1 ? "end" : `${i + 1}`,
        label: params[params.findIndex((p) => i == p.id)]?.data.userActions
          ? params[params.findIndex((p) => i == p.id)]?.data.userActions[0]
          : "",
        type: "customEdge",
        sourceHandle: "a",
        markerEnd: edgeProps,
      });
      if (
        params[params.findIndex((p) => i == p.id)]?.data.userActions.length > 1
      ) {
        path.push({
          id: `t${i}return`,
          source: `${i}`,
          target: `return`,
          label:
            params[params.findIndex((p) => i == p.id)]?.data.userActions[1],
          type: "rejectEdge",
          sourceHandle: "b",
          markerEnd: edgeProps,
        });
      }

      // Draw a path if loopback = true
      //the loopback will not function right now since the loopback have not defined
      if (task.loopback) {
        path.push({
          id: `t${i}loopback`,
          source: `${i}`,
          target: `${i}`,
          label: "Approve",
          type: "customEdge",
          sourceHandle: "a",
          markerEnd: { type: "arrow", color: "red", width: 30, height: 20 },
        });
      }
    }
  } else {
    //Generate task for Case before submitted
    //Case Start
    params.push({
      id: "case",
      position: { x: 50, y: 150 },
      data: {
        label: caseInfo?.name,
      },
      targetPosition: "left",
      sourcePosition: "right",
      style:
        caseInfo?.state == 0
          ? activeStyle
          : {
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            },
      type: "customNode",
    });
    //approval Task
    params.push({
      id: "task",
      position: { x: 300, y: 150 },
      data: { label: "Approval In Progress" },
      targetPosition: "left",
      sourcePosition: "right",
      type: "customNode",
    });

    //Approval task path
    path.push({
      id: `r1`,
      source: `task`,
      target: `return`,
      label: "Reject",
      type: "rejectEdge",
      markerEnd: edgeProps,
    });

    path.push({
      id: `complete`,
      source: `task`,
      target: `end`,
      label: "Approve",
      type: "customEdge",
      markerEnd: edgeProps,
    });
  }
  // Generate returned form node while the planitem haven't triggered
  if (path.find((p) => p.target == "return")) {
    // Returned Form
    params.push({
      id: "return",
      position: { x: 300, y: 350 },
      data: {
        label: "Returned form",
        id: "return",
      },
      type: "customNode",
      style: {
        backgroundColor: theme.palette.secondaryContainer[theme.palette.mode],
      },
    });
  }

  //Complete Node
  params.push({
    id: "end",
    position: task
      ? // && caseInfo?.tasks[0]?.userActions != "Submit"
        { x: 50 + taskLength * positionX, y: 150 }
      : { x: 550, y: 150 },
    data: {
      label: stages[stages?.length - 1]?.[0]?.name,
      state: stages[stages?.length - 1]?.[0]?.currentState,
    },
    type: "customNode",
    style:
      caseInfo?.state == 4 &&
      flowTask[flowTask.length - 1].planItemSID ==
        stages[stages?.length - 1]?.[0]?.planItemSID
        ? completedStyle
        : {
            backgroundColor:
              theme.palette.secondaryContainer[theme.palette.mode],
          },
    targetPosition: "left",
  });

  //Returned Form path
  path.push({
    id: `return1`,
    source: `return`,
    target: task ? "1" : `task`,
    label: params[params.findIndex((p) => p.id == "return")]?.data.userActions
      ? params[params.findIndex((p) => p.id == "return")]?.data.userActions[0]
      : "",
    type: "rejectEdge",
    markerEnd: edgeProps,
    style: { stroke: theme.palette.flowEdge[theme.palette.mode] },
  });

  //Discretionary Tasks
  for (let i = 0; i < discretionaryTask?.length; i++) {
    params.push({
      id: `d${i}`,
      position: {
        x: 300 + discretionaryTaskLength * positionX,
        y: path.find((p) => p.target == "return") ? 500 : 350,
      },
      data: {
        label: discretionaryTask[i]?.name,
        loopTimes:
          flowTask?.filter((f) => f.name == discretionaryTask[i]?.name).length >
          0
            ? flowTask?.filter((f) => f.name == discretionaryTask[i]?.name)
                .length - 1
            : 0,
      },
      type: "customNode",
      style:
        caseInfo?.currentPlanItemTplSid ==
        discretionaryTask[i]?.planItemTemplateSid
          ? activeStyle
          : caseInfo?.state == 3
          ? returnStyle
          : {
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            },
    });
    discretionaryTaskLength++;
  }

  const initialNodes = params;
  const initialEdges = path;

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, onEdgesChange] = useEdgesState(initialEdges);

  //Control current step for flow chart
  const [activeStep, setActiveStep] = React.useState(
    caseInfo
      ? flowTask?.findIndex(
          (f) => f.planItemSID == caseInfo?.currentPlanItemSid
        )
      : caseInfo.state == 4 || caseInfo.state == 1
      ? flowTask?.length - 1
      : 0
  );
  const [flowSpeed, setFlowSpeed] = React.useState("1");
  //Calculate how many times the task has reactived
  const handleLoop = (activeStep) => {
    const activedFlowTask = flowTask.slice(0, activeStep + 1);
    setNodes((nodes) =>
      nodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            loopTimes:
              activedFlowTask.filter((f) => f.name == node.data.label).length >
              0
                ? activedFlowTask.filter((f) => f.name == node.data.label)
                    .length - 1
                : 0,
          },
        };
      })
    );
  };
  //Handle the step of the chart
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    statusChange(activeStep + 1);
    handleLoop(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    statusChange(activeStep - 1);
    handleLoop(activeStep - 1);
  };

  const { setCenter, screenToFlowPosition } = useReactFlow();
  const { x, y, zoom } = useViewport();

  //Animate control for flow chart
  const [paused, setPaused] = React.useState(true);

  const handleAnimationPlay = () => {
    setPaused(false);
  };

  const handleAnimationPause = () => {
    setPaused(true);
  };

  const handleAnimationReset = () => {
    const startStep = caseInfo.state != 0 ? flowTask.length - 1 : 0;
    setActiveStep(startStep);
    resetStep();
    handleLoop(startStep);
    setPaused(true);
  };
  //Draggable bar for step control
  const handleDragValue = (event) => {
    const next = parseInt(event.target.value);
    setActiveStep(next);
    statusChange(next);
    handleLoop(next);
    setPaused(true);
  };
  //Timer for the animation
  React.useEffect(
    function () {
      var timeout;
      const next = activeStep < flowTask?.length - 1 ? activeStep + 1 : 0;
      if (!paused) {
        timeout = setTimeout(function () {
          setActiveStep(next);
          statusChange(next);
          handleLoop(next);
          if (next == flowTask?.length - 1) {
            setPaused(true);
          }
        }, 1000 / flowSpeed);
      }
      return function () {
        clearTimeout(timeout);
      };
    },
    [paused, activeStep]
  );
  //function to change staus of the steps
  const statusChange = (next) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.data.label == flowTask[next]?.name) {
          return {
            ...node,
            style:
              flowTask[next]?.name ==
              nodes[nodes.findIndex((n) => n.id == "return")]?.data.label
                ? returnStyle
                : node.data.state != "4"
                ? activeStyle
                : completedStyle,
            ...node.style,
          };
        }

        if (
          node?.style?.backgroundColor !=
            theme.palette.secondaryContainer[theme.palette.mode] &&
          !flowTask[next].discretionary
        ) {
          return {
            ...node,
            style: {
              ...node.style,
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            },
          };
        }
        return node;
      })
    );
    resetPosition(next);
  };

  const resetStep = () => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (
          node.data.label == flowTask[flowTask.length - 1]?.name ||
          (node.data.state == 1 && node.id != "end")
        ) {
          return {
            ...node,
            style:
              node.id == "return"
                ? returnStyle
                : node.data.state != "4"
                ? activeStyle
                : completedStyle,
            ...node.style,
          };
        }

        if (
          node?.style?.backgroundColor !=
          theme.palette.secondaryContainer[theme.palette.mode]
        ) {
          return {
            ...node,
            style: {
              ...node.style,
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            },
          };
        }
        return node;
      })
    );
    resetPosition(flowTask.length - 1);
  };

  const resetPosition = (next) => {
    const flowSize = document.querySelector(".react-flow__pane");
    const flowBorderLeftPosition =
      (window.innerWidth - flowSize?.offsetWidth) / 2;
    const flowborderTopPositsion =
      (window.innerHeight - flowSize?.offsetHeight) / 2;
    const flowBorderTopLeftCoordinate = screenToFlowPosition({
      x: flowBorderLeftPosition,
      y: flowborderTopPositsion,
    });
    const flowBorderBottomRightCoordinate = screenToFlowPosition({
      x:
        window.innerWidth -
        flowBorderLeftPosition -
        flowSize?.offsetWidth * 0.25,
      y:
        window.innerHeight -
        flowborderTopPositsion -
        document.querySelector(".animation_control")?.offsetHeight -
        flowSize?.offsetHeight * 0.2,
    });
    const currentNodesPosition =
      nodes[nodes.findIndex((n) => n.data.label == flowTask[next].name)]
        .position;
    if (
      currentNodesPosition.x < flowBorderTopLeftCoordinate.x ||
      currentNodesPosition.x > flowBorderBottomRightCoordinate.x ||
      currentNodesPosition.y < flowBorderTopLeftCoordinate.y ||
      currentNodesPosition.y > flowBorderBottomRightCoordinate.y
    ) {
      setCenter(
        currentNodesPosition.x + flowSize?.offsetWidth * 0.3,
        currentNodesPosition.y,
        {
          zoom,
        }
      );
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitView={true}
        maxZoom={1}
        panOnDrag={true}
        nodesDraggable={false}
      >
        {caseInfo.state != 0 && flowTask?.length > 1 ? (
          <div
            className="animation_control"
            style={{
              backgroundColor:
                theme.palette.secondaryContainer[theme.palette.mode],
            }}
          >
            <div>
              <div>
                <Button size="small" onClick={handleAnimationPlay}>
                  <PlayArrowIcon />
                </Button>
                <Button size="small" onClick={handleAnimationPause}>
                  <PauseIcon />
                </Button>
                <Button size="small" onClick={handleAnimationReset}>
                  <StopIcon />
                </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Grid width={"95%"}>
                  <Slider
                    value={activeStep}
                    onChange={handleDragValue}
                    step={1}
                    max={flowTask.length - 1}
                  ></Slider>
                </Grid>
              </div>
              <div
                style={{
                  float: "right",
                  marginRight: 50,
                }}
              >
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === flowTask?.length - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
                Speed:{" "}
                <select
                  id="flowSpeed"
                  value={flowSpeed}
                  onChange={(event) => setFlowSpeed(event.target.value)}
                >
                  <option value="0.5">x0.5</option>
                  <option value="1">x1</option>
                  <option value="2">x2</option>
                </select>
              </div>
            </div>
          </div>
        ) : null}
      </ReactFlow>
    </div>
  );
}
export default function Flow(props) {
  return (
    <ReactFlowProvider>
      <FlowChart {...props} />
    </ReactFlowProvider>
  );
}
