import { Grid, Typography } from "@mui/material";

function TitleWithIcon(props) {
  const { icon, title, spacing = 1 } = props;

  return (
    <Grid
      container
      alignItems={title?.alignItems ?? null}
      justifyContent={title?.justifyContent ?? ""}
      columnSpacing={spacing}
    >
      {icon ? (
        <Grid item pt={1}>
          {icon}
        </Grid>
      ) : null}

      <Grid item>
        <Typography
          variant={title?.variant ?? "body1"}
          gutterBottom={title?.gutterBottom ?? false}
          component={title?.component ?? null}
          // variantMapping={title?.variantMapping ?? "body1"}
          noWrap={title?.noWrap ?? false}
          paragraph={title?.paragraph ?? false}
          sx={{
            fontWeight: title?.fontWeight ?? "normal",
          }}
        >
          {title?.content ?? ""}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default TitleWithIcon;
