import * as React from "react";
import {
  Badge,
  Box,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import PropTypes from "prop-types";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import BlockIcon from "@mui/icons-material/Block";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import VerifiedIcon from "@mui/icons-material/VerifiedUser";

import urlJoin from "url-join";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useComponentSize, useWindowSize } from "react-use-size";
import { Dropzone, FileMosaic } from "@files-ui/react";

import Flow from "./Flow";
import SharedDialog from "../../shared/SharedDialog";
import LoadingSpinner from "../../shared/LoadingSpinner";
import { resServerBaseUrl } from "../../Config";
import {
  CallApiWithContext,
  CallApiWithUploadFile,
  CallApiWithDownloadFile,
} from "../../helpers/ApiHelper";
import { useAuthentication } from "../../providers/AuthenticationProvider";
import { useThemeColor } from "../../providers/ThemeColorProvider";
import { useProfile } from "../../providers/ProfileProvider";
import { NoPermissionPage } from "../CaseList/CaseList";
import StageProgressBar from "./ProgressBar";
import JsonFormViewer from "../JsonForm/JsonFormViewer";
import C1PdfViewer from "../PdfExport/GcPdfViewer_new";
import {
  DEFINITION_TYPE,
  DRAWER_WIDTH,
  ELEMENT_STATUS,
  IsTerminalState,
} from "../../Constants";

const listItemStyles = {
  header: { backgroundColor: "rgba(200, 200, 200, 0.4)", userSelect: "none" },
  attachment: {
    p: "4px 8px",
    "& div": { width: "100%" },
    "&:last": { pb: "8px" },
  },
};

const FormRuntime = (props) => {
  const location = useLocation();
  const theme = useTheme();
  const nav = useNavigate();
  const { t } = useTranslation();

  const showLeftHandSideDrawer = useMediaQuery(theme.breakpoints.up("ml"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const authenticationContext = useAuthentication();
  const [profile] = useProfile();

  const { IsOutbox, settitle: setTitle, setFormIdentity } = props;
  const { TaskID, TplID, uuid, PlanItemSID, CaseSID, PlanItemTplSID } =
    useParams();
  const [tempName, setTempName] = React.useState();
  const [noPermission, setNoPermission] = React.useState(false);
  const action = ["new", "triggered"].includes(
    location.pathname
      .split("/")
      .filter((s) => !!s)
      .pop()
  )
    ? location.pathname
        .split("/")
        .filter((s) => !!s)
        .pop()
    : "";
  const formRef = React.useRef();
  const {
    ref: formViewerRef,
    height: viewerHeight,
    width: _,
  } = useComponentSize();
  const { height: windowHeight } = useWindowSize();

  const [isLoading, setIsLoading] = React.useState(false);
  const [forbidden, setForbidden] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });

  const [isOpenFlowChart, setIsOpenFlowChart] = React.useState(false);

  /* activity log */
  const [isOpenActivityLog, setIsOpenActivityLog] = React.useState(false);
  const [activityLog, setActivityLog] = React.useState(null);
  const [isLoadingHistoryLog, setIsLoadingHistoryLog] = React.useState(false);
  const [currentStageLog, setCurrentStageLog] = React.useState(null);
  const [isLoadingCurrentStageLog, setIsLoadingCurrentStageLog] =
    React.useState(false);
  const [historyLogType, setHistoryLogType] = React.useState("info");
  const [fullScreen, setFullScreen] = React.useState(false);
  const isActivityLogLoaded = React.useRef(false);
  let resizeActivityLogTimeout = undefined;

  /* right hand side drawer */
  const [isRightDrawerOpen, setIsRightDrawerOpen] = React.useState(false);
  /* attachment */
  const [fileInput, setFileInput] = React.useState({});
  const [attachments, setAttachments] = React.useState({});
  const [clickable, setClickable] = React.useState(true);
  const [isLoadingDownloadAttachment, setIsLoadingDownloadAttachment] =
    React.useState(false);
  const [isLoadingDeleteAttachment, setIsLoadingDeleteAttachment] =
    React.useState(false);
  const [customSetting, setCustomSetting] = React.useState({});
  const [userInfo, setUserInfo] = React.useState({});

  /* case info */
  const [caseInfo, setCaseInfo] = React.useState();
  const [openingSubCase, setOpeningSubCase] = React.useState(false);
  const [allTaskInfo, setAllTaskInfo] = React.useState();
  const [flowTaskInfo, setFlowTaskInfo] = React.useState();
  const [isLoadingAllTaskInfo, setIsLoadingAllTaskInfo] = React.useState(false);
  const [isReadOnly, setIsReadOnly] = React.useState(false);
  const [isOutbox, setIsOutbox] = React.useState();
  const [stages, setStages] = React.useState([]);
  const [planItemSID, setPlanItemSID] = React.useState([]);
  const [activityLogDrawerHeight, setActivityLogDrawerHeight] =
    React.useState(300);
  const themeColorContext = useThemeColor();

  /* Check user timezone */
  dayjs.extend(utc);
  dayjs.extend(tz);
  const userTimezone = dayjs.tz.guess();

  const currentTask = React.useMemo(() => {
    if (PlanItemTplSID && caseInfo?.moreTasks?.length > 0) {
      const task = caseInfo.moreTasks.find(
        (t) => t.planItemTemplateSid === PlanItemTplSID
      );

      if (task) return task;
    } else if (caseInfo?.tasks?.length > 0) {
      const taskID = isNaN(TaskID) ? 0 : parseInt(TaskID);

      if (taskID) {
        return caseInfo.tasks.find((t) => t.planItem?.id === taskID);
      }

      return caseInfo.tasks[0];
    }

    return null;
  }, [caseInfo]);
  const optionalStages = React.useMemo(() => {
    return caseInfo?.stages?.filter((s) => s[0].discretionary);
  }, [caseInfo]);
  const optionalTasks = React.useMemo(() => {
    return caseInfo?.moreTasks;
  }, [caseInfo]);
  const attachmentSecurities = React.useMemo(() => {
    if (caseInfo?.caseAttachmentSecurities?.length > 0) {
      return caseInfo.caseAttachmentSecurities.map((s) => {
        if (s.permission !== null) {
          const permission = JSON.parse(s.permission);
          const resolvePermission = (key) => {
            return (
              permission[key] === "Y" ||
              (permission.Full === "Y" && permission[key] !== "N")
            );
          };

          return {
            name: s.name,
            permission: {
              canUpload: resolvePermission("Add"),
              canDownload: resolvePermission("Download"),
              canDelete: resolvePermission("Delete"),
              canPreview: resolvePermission("Preview"),
              canPrint: resolvePermission("Print"),
            },
          };
        } else {
          return null;
        }
      });
    }

    return [
      // {
      //   name: null,
      //   permission: {
      //     canUpload: false,
      //     canDownload: false,
      //     canDelete: false,
      //     canPreview: false,
      //     canPrint: false,
      //   },
      // },
    ];
  }, [caseInfo]);
  const actionButtons = React.useMemo(() => {
    if (!isReadOnly) {
      if (currentTask?.userActions?.length > 0) {
        return [...currentTask.userActions];
      } else if (currentTask != null) {
        return [t("formruntime.submit")];
      }
      return [];
    }
    return [];
  }, [isReadOnly, currentTask]);
  const isTriggerCase = React.useMemo(() => {
    return action === "new" || action === "triggered";
  }, [action]);
  const isCopyAsNew = React.useMemo(() => {
    return action === "triggered";
  }, [action]);
  const showActivityLogSplitter = React.useMemo(() => {
    return isMdUp && isOpenActivityLog;
  }, [isMdUp, isOpenActivityLog]);

  const showAlertMessage = (messageObject) => {
    setAlertDialog((prev) => ({
      ...prev,
      ...messageObject,
    }));
    setShowAlertDialog(true);
  };
  const closeAlertMessage = () => {
    setShowAlertDialog(false);
  };

  const handleToggleActivityLog = () => {
    setIsOpenActivityLog((prev) => !prev);
  };
  const handleToggleRightDrawer = () => {
    setIsRightDrawerOpen((prev) => !prev);
  };

  const submitTask = (data) => {
    const handleConfirmSubmitClick = () => {
      formRef.current
        .submitTask(data)
        .then((res) => {
          /* if submit success, prompt submission succeeded and back to inbox */
          showAlertMessage({
            title: t("formruntime.task_submitted"),
            content: t("formruntime.task_submitted_context"),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  nav(localStorage.getItem("startPageURL"));
                },
              },
            ],
          });
        })
        .catch((error) => {
          console.log(error);
          /* otherwise, prompt submission failed message */
          showAlertMessage({
            title: t("formruntime.submission_failed"),
            content: error,
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => {
                  closeAlertMessage();
                  formRef.current.onFormClose();
                },
              },
            ],
          });
        });
    };
    /* prompt confirm submit message */
    showAlertMessage({
      title: t("formruntime.submit_task"),
      content: t("formruntime.submit_task_context"),
      buttons: [
        {
          /* if yes, continue process submission */
          text: t("formruntime.yes"),
          action: () => {
            closeAlertMessage();
            handleConfirmSubmitClick();
          },
        },
        {
          /* if no, cancel the submission. */
          text: t("formruntime.no"),
          action: closeAlertMessage,
          variant: "outlined",
          color: "error",
        },
      ],
    });
  };

  const handleActionButtonClick = (actionButton) => {
    if (formRef.current) {
      /* inform form viewer action button clicked */
      formRef.current
        .actionButtonClick(actionButton)
        .then((res) => {
          console.log(`User action: ${res.action}`);

          if (res.canProcess) {
            if (actionButton === "_Trigger_") {
              nav(`/case/${CaseSID}/${PlanItemSID || planItemSID}/triggered`);
              setIsReadOnly(false);
              setIsOutbox(true);
            } else if (
              actionButton === "_Close_" ||
              actionButton === "Cancel"
            ) {
              formRef.current.onFormClose();
              //Default back to workspace if localStorage did not contain startPageURL
              nav(
                localStorage.getItem("startPageURL")
                  ? localStorage.getItem("startPageURL")
                  : `/workspace/${caseInfo?.tplUUID}`
              );
              // let locationState = location.pathname.split("/").slice(1);
              // if (
              //   !locationState[2] ||
              //   locationState.some((c) => c === "new") ||
              //   (locationState.includes("triggered") &&
              //     locationState.length === 4)
              // ) {
              //   if (CaseSID || locationState.some((c) => c == "new")) {
              //     nav(
              //       localStorage
              //         .getItem("startPageURL")
              //         .includes("/workspace") &&
              //         localStorage
              //           .getItem("startPageURL")
              //           .includes(caseInfo.tplUUID)
              //         ? localStorage.getItem("startPageURL")
              //         : `/workspace/${caseInfo.tplUUID}`
              //     );
              //   }
              // } else if (IsOutbox || isOutbox === true) {
              //   nav(
              //     localStorage.getItem("startPageURL").includes("/outbox")
              //       ? localStorage.getItem("startPageURL")
              //       : "/outbox"
              //   );
              // } else if (IsOutbox || isOutbox === false) {
              //   nav(
              //     localStorage.getItem("startPageURL").includes("/inbox")
              //       ? localStorage.getItem("startPageURL")
              //       : "/inbox"
              //   );
              // }
            } else {
              submitTask({
                CaseSID: CaseSID,
                CaseTplUUID: tempName,
                Metadata: caseInfo?.metaData,
                Action: action,
                CaseName: caseInfo?.name,
                TempName: tempName,
                CompleteTaskName: currentTask?.name,
                BtnAction: actionButton,
                TaskID: TaskID || currentTask?.planItemID,
                TaskSID: currentTask?.planItemSID,
                PlanItemTplSID:
                  currentTask?.planItemTemplateSid || PlanItemTplSID,
                StageID: -1,
              });
            }
          }
        })
        .catch((error) => {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: error.toString(),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: closeAlertMessage,
              },
            ],
          });
        });
    } else if (actionButton === "_Close_" || actionButton === "Cancel") {
      nav(
        localStorage.getItem("startPageURL")
          ? localStorage.getItem("startPageURL")
          : `/workspace/${caseInfo?.tplUUID}`
      );
    }
  };
  const handleOptionalTaskClick = (optionalTask, index) => {
    if (optionalTask.type === DEFINITION_TYPE.EventListener) {
      showAlertMessage({
        title: "Raise event",
        content: "Are you sure to trigger this task?",
        buttons: [
          {
            text: "Yes",
            action: () => {
              const url = urlJoin(resServerBaseUrl, "/Case/OccurPlanItem");
              const formData = JSON.stringify({
                PlanItemSID: optionalTask.planItemSID,
              });

              CallApiWithContext(url, authenticationContext, formData)
                .then((res) => {
                  if (res.isSucceeded) {
                    // refresh current page.
                    window.location.reload(false);
                  }
                })
                .catch((error) => console.log(error));
              closeAlertMessage();
            },
          },
          {
            text: "No",
            action: closeAlertMessage,
            variant: "outlined",
            color: "error",
          },
        ],
      });
    } else if (optionalTask.type === DEFINITION_TYPE.Task) {
      // open optional task form.
      if (optionalTask.discretionary) {
        switch (optionalTask.currentState) {
          case ELEMENT_STATUS.Initial:
            nav(
              `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemTemplateSid}/optional/`
            );
            //window.location.href = `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemTemplateSid}/optional/`;
            break;
          case ELEMENT_STATUS.Active:
            nav(
              `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemSid}/`
            );
            //window.location.href = `${process.env.PUBLIC_URL}/case/${CaseSID}/${optionalTask.planItemSid}/`;
            break;
        }
      }
    } else if (optionalTask.type === DEFINITION_TYPE.Stage) {
      showAlertMessage({
        title: "Complete stage",
        content: `Are you sure to complete the stage, ${optionalTask.planItem.name}?`,
        buttons: [
          {
            text: "Yes",
            action: () => {
              const url = urlJoin(resServerBaseUrl, "/Case/CompletePlanItem");
              const formData = JSON.stringify({
                PlanItemSID: optionalTask.planItemSID,
              });

              CallApiWithContext(url, authenticationContext, formData)
                .then((res) => {
                  // refresh current page.
                  window.location.reload(false);
                })
                .catch((error) => console.log(error));
              closeAlertMessage();
            },
          },
          {
            text: "No",
            action: closeAlertMessage,
            variant: "outlined",
            color: "error",
          },
        ],
      });
    }
  };
  const handleServerBypassDenied = () => {
    customSetting.AVSERVERBYPASS = false;
    showAlertMessage({
      title: t("profile.proxy_and_delegation.error"),
      content: t("formruntime.gcpdf_viewer.fail_upload_attachment"),
      buttons: [
        {
          text: t("administrative_console.group_page.dialog.close"),
          action: closeAlertMessage,
        },
      ],
    });
    updateCustomSetting();
  };
  const handleServerBypassConfirm = (formDataFile) => {
    customSetting.AVSERVERBYPASS = true;
    updateCustomSetting();
    updateFilesSkippedVirusCheck(formDataFile);
  };
  const handleRememberMySettingChange = (prop) => (event) => {
    setCustomSetting({ ...customSetting, [prop]: event.target.checked });
  };
  const updateCustomSetting = () => {
    var saveData = {
      ID: userInfo.id,
      Login: userInfo.login,
      CustomSetting: JSON.stringify(customSetting),
    };
    const updateUserURL = urlJoin(
      resServerBaseUrl,
      "User/UpdateUserCustomSetting"
    );
    CallApiWithContext(
      updateUserURL,
      authenticationContext,
      JSON.stringify(saveData)
    )
      .then((response) => {
        console.log(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateFilesSkippedVirusCheck = (formDataFile) => {
    const url = urlJoin(
      resServerBaseUrl,
      "/Import/UploadAttachmentSkippedVirusCheck"
    );

    CallApiWithUploadFile(url, authenticationContext, formDataFile)
      .then((response) => {
        if (response) {
          setAttachments((prev) => [
            ...prev,
            {
              name: formDataFile.get("file").name,
              isNew: true,
              isChecked: false,
            },
          ]);
        }
        setFileInput({});
      })
      .catch((error) => {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: error,
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => setShowAlertDialog(false),
            },
          ],
        });
        console.log(error);
      });
  };
  const handleActivityLogDrawerGripPointerDown = (e) => {
    e.preventDefault();
    e.stopPropagation();

    e.target.addEventListener(
      "pointermove",
      handleActivityDrawerPointerMove,
      true
    );
    e.target.addEventListener(
      "pointerup",
      handleActivityDrawerGripPointerUp,
      true
    );
    e.target.setPointerCapture(e.pointerId);
  };
  const handleActivityDrawerGripPointerUp = (e) => {
    e.target.releasePointerCapture(e.pointerId);
    e.target.removeEventListener(
      "pointerup",
      handleActivityDrawerGripPointerUp,
      true
    );
    e.target.removeEventListener(
      "pointermove",
      handleActivityDrawerPointerMove,
      true
    );
  };
  const handleActivityDrawerPointerMove = (e) => {
    const newHeight = document.body.offsetHeight - e.clientY;

    e.stopPropagation();
    e.preventDefault();

    resizeActivityLogHeight(newHeight, 0);
  };
  /*
  const handleActivityLogDrawerGripTouchStart = (e) => {
    document.addEventListener(
      "touchend",
      handleActivityDrawerGripTouchEnd,
      true
    );
    document.addEventListener(
      "touchmove",
      handleActivityDrawerGripTouchMove,
      true
    );
  };
  const handleActivityDrawerGripTouchEnd = () => {
    document.removeEventListener(
      "touchend",
      handleActivityDrawerGripTouchEnd,
      true
    );
    document.removeEventListener(
      "touchmove",
      handleActivityDrawerGripTouchMove,
      true
    );
  };
  const handleActivityDrawerGripTouchMove = (e) => {
    const clientY = e.targetTouches[0].clientY;
    const newHeight = document.body.offsetHeight - clientY;
    const drawer = document.querySelector("#ActivityLogDrawer");
    const paper = drawer.querySelector("div");

    e.stopPropagation();
    e.preventDefault();

    const minHeight = Math.min(paper.scrollHeight, 150);

    if (newHeight < paper.scrollHeight) {
      const maxHeight = Math.min(document.body.offsetHeight, 1000);

      if (newHeight > minHeight && newHeight < maxHeight) {
        setActivityLogDrawerHeight(newHeight);
      } else if (newHeight < minHeight) {
        // set minimum height
        setActivityLogDrawerHeight(minHeight);
        // close the activity log drawer
        handleActivityDrawerGripTouchEnd();
        setIsOpenActivityLog(false);
      }
    } else {
      setActivityLogDrawerHeight(paper.scrollHeight);
    }
  };
  */
  const handleHistoryLogTypeChange = (e) => {
    setHistoryLogType(e.target.value);
  };

  /* attachment events */
  const updateFiles = (incomingFiles, controlName) => {
    //do something with the files
    setFileInput({ [controlName]: incomingFiles });
    // setIsLoadingAddAttachment(true);

    const isRepeat =
      attachments[controlName]?.some((a) =>
        incomingFiles.some((n) => n.file.name === a.fileName)
      ) == true;

    if (isRepeat) {
      setFileInput({});
      showAlertMessage({
        title: t("profile.proxy_and_delegation.error"),
        content: t("formruntime.gcpdf_viewer.file_exist"),
        buttons: [
          {
            text: t("administrative_console.group_page.dialog.close"),
            action: closeAlertMessage,
          },
        ],
      });
      // setOpenAddAttachmentDialog(false);
      // setIsLoadingAddAttachment(false);
    } else {
      const url = urlJoin(resServerBaseUrl, "/Import/UploadAttachment");

      incomingFiles.forEach((f) => {
        const formDataFile = new FormData();

        formDataFile.append("file", f.file);
        formDataFile.append("CaseSID", openingSubCase ? undefined : CaseSID);
        formDataFile.append("TempName", tempName);
        formDataFile.append("CaseTplUUID", caseInfo?.tplUUID);
        formDataFile.append("PlanItemSID", PlanItemSID);
        formDataFile.append("Name", controlName);

        CallApiWithUploadFile(url, authenticationContext, formDataFile)
          .then((response) => {
            if (typeof response == "undefined") {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: t("formruntime.gcpdf_viewer.fail_upload_attachment"),
                buttons: [
                  {
                    text: t("administrative_console.group_page.dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
            } else if (response.message == "Inactive") {
              const url = urlJoin(resServerBaseUrl, "/User/GetUserProfile");
              CallApiWithContext(url, authenticationContext).then((json) => {
                if (json != null) {
                  var customSetting = JSON.parse(json.customSetting);
                  setCustomSetting(customSetting);
                  setUserInfo(json);
                  if (
                    customSetting.KEEPAVSERVERBYPASS == true &&
                    customSetting.AVSERVERBYPASS == false
                  ) {
                    showAlertMessage({
                      title: t("profile.proxy_and_delegation.error"),
                      content: t(
                        "formruntime.gcpdf_viewer.fail_upload_attachment"
                      ),
                      buttons: [
                        {
                          text: t(
                            "administrative_console.group_page.dialog.close"
                          ),
                          action: () => {
                            closeAlertMessage();
                          },
                        },
                      ],
                    });
                  } else if (
                    customSetting.KEEPAVSERVERBYPASS == true &&
                    customSetting.AVSERVERBYPASS == true
                  ) {
                    updateFilesSkippedVirusCheck(formDataFile);
                  } else {
                    showAlertMessage({
                      title: t(
                        "administrative_console.import_page.confirm_bypass_server"
                      ),
                      content: (
                        <>
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={handleRememberMySettingChange(
                                    "KEEPAVSERVERBYPASS"
                                  )}
                                />
                              }
                              label={t(
                                "administrative_console.import_page.remember"
                              )}
                            />
                          </FormGroup>
                        </>
                      ),
                      buttons: [
                        {
                          /* if yes, continue process submission */
                          text: t("formruntime.yes"),
                          action: () => {
                            closeAlertMessage();
                            handleServerBypassConfirm(formDataFile);
                          },
                        },
                        {
                          /* if no, cancel the submission. */
                          text: t("formruntime.no"),
                          action: () => {
                            closeAlertMessage();
                            handleServerBypassDenied();
                          },
                          variant: "outlined",
                          color: "error",
                        },
                      ],
                    });
                  }
                }
              });
            } else if (response) {
              // showAlertMessage({
              //   content: t(
              //     "formruntime.gcpdf_viewer.success_upload_attachment"
              //   ),
              //   title: "",
              // });
              setAttachments((prev) => {
                const atchArray = prev[controlName] || [];

                atchArray.push({
                  fileName: formDataFile.get("file").name,
                  caseSid: CaseSID,
                  attachmentControl: controlName,
                  isNew: true,
                  isChecked: true,
                });

                return {
                  ...prev,
                  [controlName]: atchArray,
                };
              });
            }
            setFileInput({});
            // setOpenAddAttachmentDialog(false);
            // setIsLoadingAddAttachment(false);
          })
          .catch((error) => {
            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: error,
              buttons: [
                {
                  text: t("administrative_console.group_page.dialog.close"),
                  action: () => setShowAlertDialog(false),
                },
              ],
            });
            // setOpenAddAttachmentDialog(false);
            // setIsLoadingAddAttachment(false);
            console.log(error);
          });
      });
    }

    //even your own upload implementation
  };

  const handleDownloadAttachmentButtonOnClick = (name, index) => {
    //if (attachmentSecurity.canDownload) {
    if (attachmentSecurities.some((s) => s.name === name)) {
      const attachmentSecurity = attachmentSecurities.find(
        (s) => s.name === name
      ).permission;

      if (
        attachmentSecurity.canDownload ||
        (CaseSID != null && attachments[name][index].isNew)
      ) {
        setIsLoadingDownloadAttachment(true);

        const file = attachments[name][index];
        const data = {
          CaseSID: file.caseSid,
          CaseTplUUID: caseInfo?.tplUUID,
          // PlanItemID: TaskID,
          PlanItemSID: PlanItemSID,
          TempName: tempName,
          FileName: file.fileName,
          Name: name,
          IsNew: file.isNew,
        };
        const url = urlJoin(resServerBaseUrl, "/Import/DownloadAttachment");

        CallApiWithDownloadFile(
          url,
          authenticationContext,
          JSON.stringify(data),
          file.fileName
        )
          .then((response) => {
            if (response) {
            } else {
              showAlertMessage({
                title: t("profile.proxy_and_delegation.error"),
                content: t("formruntime.gcpdf_viewer.fail_download_attachment"),
                buttons: [
                  {
                    text: t("administrative_console.group_page.dialog.close"),
                    action: () => {
                      closeAlertMessage();
                    },
                  },
                ],
              });
            }
          })
          .catch((error) => {
            let errorMsg = error;

            if (error.response.status === 403) {
              errorMsg = t("formruntime.no_download_permission");
            }

            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: errorMsg,
              buttons: [
                {
                  text: t("administrative_console.group_page.dialog.close"),
                  action: () => {
                    closeAlertMessage();
                  },
                },
              ],
            });
          })
          .finally(() => {
            setIsLoadingDownloadAttachment(false);
          });
      } else {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: t("formruntime.gcpdf_viewer.no_permission_to_download"),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
              },
            },
          ],
        });
      }
    }
  };
  const handleOpenDeleteAttachmentDialog = (name, index) => {
    if (attachmentSecurities.some((s) => s.name === name)) {
      const attachmentSecurity = attachmentSecurities.find(
        (s) => s.name === name
      ).permission;
      const file = attachments[name][index];

      if (attachmentSecurity.canDelete || (CaseSID != null && file.isNew)) {
        showAlertMessage({
          title: t("formruntime.gcpdf_viewer.confirm_delete_attachment"),
          content: file.fileName,
          buttons: [
            {
              text: t("formruntime.yes"),
              action: () => {
                closeAlertMessage();
                handleDeleteAttachement(name, index);
              },
            },
            {
              text: t("formruntime.no"),
              action: () => {
                closeAlertMessage();
              },
              variant: "outlined",
              color: "error",
            },
          ],
        });
      } else {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: t("formruntime.gcpdf_viewer.no_permission_to_delete"),
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => {
                closeAlertMessage();
              },
            },
          ],
        });
      }
    }
  };
  const handleDeleteAttachement = (name, index) => {
    // setIsLoadingDeleteAttachment(true);
    const file = attachments[name][index];

    let data = {
      CaseSID: CaseSID,
      CaseTplUUID: caseInfo?.tplUUID,
      PlanItemID: TaskID,
      TempName: tempName,
      FileName: file.fileName,
      IsNew: file.isNew,
      Name: name,
    };
    const url = urlJoin(resServerBaseUrl, "/Import/DeleteAttachment");

    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        if (response.message) {
          setAttachments((prev) => ({
            ...prev,
            [name]: prev[name]?.filter((f) => f.fileName != file.fileName),
          }));
          // showAlertMessage({
          //   title: "",
          //   content: t(response.message),
          //   buttons: [
          //     {
          //       text: t("administrative_console.group_page.dialog.close"),
          //       action: () => setShowAlertDialog(false),
          //     },
          //   ],
          // });
        }
        // else if (response) {
        //   showAlertMessage({
        //     title: "",
        //     content: t(response),
        //     buttons: [
        //       {
        //         text: t("administrative_console.group_page.dialog.close"),
        //         action: () => setShowAlertDialog(false),
        //       },
        //     ],
        //   });
        // }
        else if (!response) {
          showAlertMessage({
            title: t("profile.proxy_and_delegation.error"),
            content: t("formruntime.gcpdf_viewer.fail_to_delete_attachment"),
            buttons: [
              {
                text: t("administrative_console.group_page.dialog.close"),
                action: () => setShowAlertDialog(false),
              },
            ],
          });
        }
        setIsLoadingDeleteAttachment(false);
      })
      .catch((error) => {
        showAlertMessage({
          title: t("profile.proxy_and_delegation.error"),
          content: error,
          buttons: [
            {
              text: t("administrative_console.group_page.dialog.close"),
              action: () => setShowAlertDialog(false),
            },
          ],
        });
        console.log(error);
        // setIsLoadingDeleteAttachment(false);
      });
  };
  const handleOpenFlowChart = () => {
    const allTaskInfoUrl = urlJoin(resServerBaseUrl, "/Case/GetAllTaskInfo");
    const data = JSON.stringify({
      CaseSID: CaseSID,
      copyAsNew: isCopyAsNew,
      PlanItemSID: PlanItemSID,
      IsOutbox: isOutbox || IsOutbox,
    });
    setIsOpenFlowChart(true);
    if (!allTaskInfo) {
      setIsLoadingAllTaskInfo(true);
      CallApiWithContext(allTaskInfoUrl, authenticationContext, data)
        .then((res) => {
          if (res) {
            setAllTaskInfo(res.allTaskInfo);
            setFlowTaskInfo(res.flowTaskInfo);
          } else {
            setAllTaskInfo(null);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoadingAllTaskInfo(false);
        });
    }
  };
  const handleCloseFlowChart = () => {
    setIsOpenFlowChart(false);
  };

  /* init form event */
  const initFormRuntime = () => {
    setCaseInfo(undefined);
  };
  const getPlanItemDefinitionType = (type) => {
    const DefinitionType = {
      Stage: 1,
      Task: 2,
      Milestone: 3,
      EventListener: 4,
    };
    const StyledTag = styled(Typography)(({ theme }) => ({
      borderRadius: "5px",
      padding: "2px 5px",
      textAlign: "center",
      maxWidth: "100px",
    }));

    switch (type) {
      case DefinitionType.Task:
        return (
          <StyledTag
            variant="body2"
            sx={{
              bgcolor: "#85FFC8",
              color: "black",
            }}
          >
            {t("formruntime.activity_log.task")}
          </StyledTag>
        );
      case DefinitionType.Stage:
        return (
          <StyledTag
            variant="body2"
            sx={{ bgcolor: "#5BD6FF", color: "black" }}
          >
            {t("formruntime.activity_log.stage")}
          </StyledTag>
        );
      case DefinitionType.Milestone:
        return (
          <StyledTag
            variant="body2"
            sx={{ bgcolor: "#42F58A", color: "black" }}
          >
            {t("formruntime.activity_log.milestone")}
          </StyledTag>
        );
      case DefinitionType.EventListener:
        return (
          <StyledTag
            variant="body2"
            sx={{ bgcolor: "#C8FD79", color: "black" }}
          >
            {t("formruntime.activity_log.event")}
          </StyledTag>
        );
    }

    return t("formruntime.activity_log.unspecified");
  };
  const resizeActivityLogHeight = (newHeight, timeout = 100) => {
    if (isOpenActivityLog) {
      if (resizeActivityLogTimeout) {
        clearTimeout(resizeActivityLogTimeout);
      }

      const resize = () => {
        const progressBar = document.querySelector("#progressBar");
        const maxHeight =
          window.innerHeight - progressBar.getBoundingClientRect().bottom - 5;
        const formHeight = maxHeight - newHeight;
        const bar = document
          .querySelector("#ActivityLogPaper")
          .querySelector("div");
        const logContent = document.querySelector("#logContent");

        if (bar && logContent) {
          const paper = logContent.offsetHeight + bar.offsetHeight;
          const minHeight = Math.min(paper, 150);

          if (newHeight <= paper) {
            if (newHeight > minHeight && formHeight > 0) {
              setActivityLogDrawerHeight(newHeight);
            } else if (newHeight < minHeight) {
              // set minimum height
              setActivityLogDrawerHeight(minHeight);
              setIsOpenActivityLog(false);
            } else {
              setActivityLogDrawerHeight(maxHeight);
            }
          } else {
            setActivityLogDrawerHeight(paper);
          }
        }
      };

      if (timeout === 0) {
        resize();
      } else {
        resizeActivityLogTimeout = window.setTimeout(() => {
          resizeActivityLogTimeout = undefined;
          resize();
        }, timeout);
      }
    }
  };

  /* flow chart dialog */
  const handleOpenFullScreen = () => {
    setFullScreen(true);
  };
  const handleExitFullScreen = () => {
    setFullScreen(false);
  };

  React.useEffect(() => {
    if (isOpenActivityLog) {
      resizeActivityLogHeight(activityLogDrawerHeight);
    }
  }, [windowHeight, historyLogType]);
  /* get activity log */
  React.useEffect(() => {
    if (
      caseInfo &&
      !isActivityLogLoaded.current &&
      (isOpenActivityLog || isOpenFlowChart)
    ) {
      isActivityLogLoaded.current = true;

      setIsLoadingCurrentStageLog(true);
      setIsLoadingHistoryLog(true);

      const activityLogUrl = urlJoin(resServerBaseUrl, "/Case/GetActivityLog");
      const currentStageLogUrl = urlJoin(
        resServerBaseUrl,
        "/Case/GetCurrentStageLog"
      );
      const data = JSON.stringify({
        CaseSID: CaseSID,
        Language: localStorage.getItem("i18nextLng"),
      });

      CallApiWithContext(activityLogUrl, authenticationContext, data)
        .then((res) => {
          if (res && !res.message) {
            setActivityLog(res);
          } else {
            setActivityLog(null);
          }
        })
        .catch((error) => {
          console.log(error);
          // setError(true);
        })
        .finally(() => {
          setIsLoadingHistoryLog(false);
        });

      CallApiWithContext(currentStageLogUrl, authenticationContext, data)
        .then((res) => {
          if (res && !res.message) {
            setCurrentStageLog(res);
          } else {
            setCurrentStageLog(null);
          }
        })
        .catch((error) => {
          console.log(error);
          // setError(true);
        })
        .finally(() => {
          setIsLoadingCurrentStageLog(false);
        });
    }
  }, [CaseSID, caseInfo, isOpenActivityLog, isOpenFlowChart]);
  /* get case info */
  React.useEffect(() => {
    if (isTriggerCase) {
      setIsLoading(true);
      setAllTaskInfo(null);
      const url = urlJoin(resServerBaseUrl, "/Case/GetCaseTemplateByUUID");
      const data = JSON.stringify({
        uuid: uuid || caseInfo?.tplUUID,
        language: localStorage.getItem("i18nextLng"),
      });

      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {
          if (res?.caseInfo) {
            setCaseInfo(res.caseInfo);
            setTempName(res.tempUUID);
            setStages(res.caseInfo.stages);
          } else if (res?.message) {
            showAlertMessage({
              title: t("profile.proxy_and_delegation.error"),
              content: res.message,
              buttons: [
                {
                  text: t("administrative_console.report_page.confirm"),
                  action: () => setShowAlertDialog(false),
                },
              ],
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setNoPermission(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // ignore the reload when replace the url due to open from workspace without plan item sid.
      if (PlanItemSID === planItemSID) {
        return;
      }
      setIsLoading(true);
      setAllTaskInfo(null);
      initFormRuntime();
      // get running case
      const url = urlJoin(resServerBaseUrl, "/Case/GetRunningCaseByCaseSID");
      const data = JSON.stringify({
        CaseSID: CaseSID,
        PlanItemSID: PlanItemSID,
        PlanItemTplSID: PlanItemTplSID,
        Language: localStorage.getItem("i18nextLng"),
      });

      CallApiWithContext(url, authenticationContext, data)
        .then((res) => {
          if (res?.caseInfo) {
            const caseInfo = res.caseInfo;

            setCaseInfo(caseInfo);
            setIsReadOnly(res.isReadOnly);
            setIsOutbox(res.isReadOnly);
            setTempName(res.tempUUID);
            setStages(caseInfo?.stages);
            setOpeningSubCase(res.openSubCase);

            if (!PlanItemSID) {
              setPlanItemSID(res.planItemSID);
            }
            if (
              !PlanItemTplSID &&
              caseInfo?.currentPlanItemSid &&
              PlanItemSID !== caseInfo?.currentPlanItemSid
            ) {
              nav(
                `${process.env.PUBLIC_URL}/case/${res.caseSID}/${caseInfo.currentPlanItemSid}/`,
                { replace: true, state: null }
              );
              // window.history.replaceState(
              //   null,
              //   "FlexCase",
              //   `${process.env.PUBLIC_URL}/case/${res.caseSID}/${caseInfo.currentPlanItemSid}/`
              // );
            }

            if (caseInfo?.caseAttachmentNames) {
              const curAttachments = {};

              Object.keys(caseInfo.caseAttachmentNames).forEach((k) => {
                const newFiles = caseInfo.caseAttachmentNames[k];
                const curFiles = curAttachments[k] || [];

                newFiles?.forEach((f) => curFiles.push({ ...f, isNew: false }));
                curAttachments[k] = curFiles;
              });

              setAttachments(curAttachments);
            }
          } else if (res.errors && res.status == 400) {
            setTitle(t("case_list.no_permission_title"));
            setForbidden(true);
          }
        })
        .catch((res) => {
          if (res.status == 403) {
            setTitle(t("case_list.no_permission_title"));
            setForbidden(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [authenticationContext, isTriggerCase, CaseSID, PlanItemSID, uuid, t]);
  /* load task info */
  React.useEffect(() => {
    const title = currentTask?.name || caseInfo?.name;

    isActivityLogLoaded.current = false;
    setIsOpenActivityLog(false);
    setActivityLogDrawerHeight(300);
    setHistoryLogType("info");
    setTitle(title);

    if (currentTask) {
      setFormIdentity((prev) => ({
        ...prev,
        caseSID: CaseSID,
        taskUUID: PlanItemSID,
        tplUUID: caseInfo?.tplUUID,
        name: title,
        action: action,
        isOutbox: IsOutbox || isOutbox,
        caseName: caseInfo?.name,
      }));
    } else {
      setFormIdentity((prev) => ({
        ...prev,
        caseSID: CaseSID,
        tplUUID: caseInfo?.tplUUID,
        name: caseInfo?.name,
        action: "",
        isOutbox: IsOutbox || isOutbox,
        caseName: caseInfo?.name,
      }));
    }
  }, [caseInfo, currentTask, isTriggerCase]);
  /* flow chart dialog */
  React.useEffect(() => {
    if (!isSmUp) {
      setFullScreen(true);
    }
  }, [isSmUp]);

  return (
    <div style={{ height: "100%" }}>
      {noPermission ? (
        <NoPermissionPage />
      ) : (
        <>
          <Grid container direction="column" sx={{ height: "100%" }}>
            {/* case bar */}
            <Grid xs={"auto"} id="progressBar">
              <Grid container alignItems={"flex-end"}>
                <Grid
                  xs
                  className="barContainer"
                  overflow={"auto"}
                  width={"100%"}
                >
                  <StageProgressBar
                    isLoading={stages === null || stages.length === 0}
                    stages={stages ?? []}
                    currentStage={caseInfo?.currentStage}
                    caseInfo={caseInfo}
                  />
                </Grid>
                <Grid xs={"auto"} sx={{ display: { md: "none" } }}>
                  <IconButton
                    color="inherit"
                    onClick={() => setIsRightDrawerOpen((prev) => !prev)}
                    sx={{
                      mr: 1,
                      display: { md: "none" },
                    }}
                  >
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            {/* form viewer and action drawer */}
            <Grid
              container
              xs
              id="formViewer"
              direction={"column"}
              sx={{ overflow: "hidden" }}
            >
              {/* form viewer container */}
              <div
                ref={formViewerRef}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  overflow: "hidden",
                  flex: 1,
                }}
              >
                {/* form runtime */}
                {forbidden ? (
                  <div style={{ flex: 1, height: viewerHeight }}>
                    <NoPermissionPage />
                  </div>
                ) : (
                  <Grid
                    component={"div"}
                    id="formContainer"
                    container
                    item="true"
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    xs
                    sx={{ flex: 1, height: viewerHeight, overflow: "auto" }}
                  >
                    {caseInfo?.formType === "JsonForm" ? (
                      <JsonFormViewer
                        ref={formRef}
                        isNewWorkOrder={isTriggerCase}
                        isReadOnly={isReadOnly}
                        isOutbox={isOutbox || IsOutbox}
                        caseTplUUID={caseInfo?.tplUUID}
                        planItemSID={
                          PlanItemSID || caseInfo?.currentPlanItemSid
                        }
                        caseSID={CaseSID}
                        alertMessage={[showAlertMessage, closeAlertMessage]}
                      />
                    ) : caseInfo ? (
                      <C1PdfViewer
                        ref={formRef}
                        isOutbox={isOutbox || IsOutbox}
                        caseTplID={caseInfo?.tplId}
                        caseTplUUID={caseInfo?.tplUUID}
                        readonly={isReadOnly}
                        name={caseInfo?.name}
                        viewerHeight={viewerHeight}
                      />
                    ) : null}
                  </Grid>
                )}

                {/* right hand side drawer */}
                <Grid xs={"auto"}>
                  <Box
                    component="nav"
                    sx={{
                      width: isMdUp ? DRAWER_WIDTH : null,
                      height: "100%",
                      flexShrink: { sm: 0 },
                    }}
                  >
                    <Drawer
                      variant={isMdUp ? "permanent" : "temporary"}
                      open={isRightDrawerOpen}
                      onClose={handleToggleRightDrawer}
                      anchor={"right"}
                      sx={{
                        display: { md: "block" },
                        "& .MuiDrawer-paper": {
                          position: "relative",
                          width: isMdUp ? DRAWER_WIDTH : "100%",
                          bgcolor: "#eaeff1",
                        },
                      }}
                    >
                      <List
                        id="contentTable"
                        style={{ height: isMdUp ? viewerHeight : "100%" }}
                        sx={{
                          "&.MuiListItemButton-gutters": {
                            backgroundColor: "white",
                            color: "rgba(255, 255, 255, 0.7)",
                          },
                        }}
                        disablePadding
                      >
                        {/* close drawer button */}
                        {isMdUp ? null : (
                          <Tooltip title={t("formruntime.close_action_panel")}>
                            <IconButton
                              color="inherit"
                              onClick={() =>
                                setIsRightDrawerOpen((prev) => !prev)
                              }
                              sx={{
                                ml: 0.5,
                                display: { md: "none", sm: "inline-flex" },
                              }}
                            >
                              <KeyboardArrowRightIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        <ListItem
                          id="contentText"
                          style={listItemStyles.header}
                        >
                          <ListItemText primary={t("formruntime.actions")} />
                        </ListItem>

                        {/* action buttons */}
                        {isReadOnly && !isTriggerCase ? (
                          <ListItem disablePadding>
                            <ListItemButton
                              disableGutters
                              onClick={() =>
                                handleActionButtonClick("_Trigger_")
                              }
                            >
                              <ListItemIcon>
                                <ContentCopyIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={t("formruntime.clone_this_case")}
                              />
                            </ListItemButton>
                          </ListItem>
                        ) : (
                          actionButtons.map((btn, index) => (
                            <ListItem
                              key={`action_buttons_${index}`}
                              disablePadding
                            >
                              <ListItemButton
                                onClick={() => handleActionButtonClick(btn)}
                                disableGutters
                              >
                                <ListItemIcon>
                                  <TaskAltIcon />
                                </ListItemIcon>
                                {btn == "Approve" ? (
                                  <ListItemText
                                    primary={t("formruntime.approve")}
                                  />
                                ) : null}
                                {btn == "Reject" ? (
                                  <ListItemText
                                    primary={t("formruntime.reject")}
                                  />
                                ) : null}
                                {btn == "Complete" ? (
                                  <ListItemText
                                    primary={t("formruntime.complete")}
                                  />
                                ) : null}
                                {btn == "Approve" ||
                                btn == "Reject" ||
                                btn == "Complete" ? null : (
                                  <ListItemText primary={btn.split(":")[0]} />
                                )}
                              </ListItemButton>
                            </ListItem>
                          ))
                        )}

                        {actionButtons.includes("Cancel") ? (
                          ""
                        ) : (
                          <ListItem disablePadding>
                            <ListItemButton
                              onClick={() => handleActionButtonClick("_Close_")}
                              disableGutters
                            >
                              <ListItemIcon>
                                <CloseIcon />
                              </ListItemIcon>
                              <ListItemText primary={t("formruntime.cancel")} />
                            </ListItemButton>
                          </ListItem>
                        )}

                        <Divider />

                        {/* optional task list */}
                        {optionalTasks?.length > 0 ? (
                          <>
                            <ListItem
                              id="contentText"
                              style={listItemStyles.header}
                            >
                              <ListItemText
                                primary={t("formruntime.more_tasks")}
                              />
                            </ListItem>
                            {optionalTasks.map((item, index) => {
                              return (
                                <ListItem
                                  key={`optionalTask_${index}`}
                                  disablePadding
                                >
                                  <ListItemButton
                                    key={item.id}
                                    onClick={() =>
                                      handleOptionalTaskClick(item, index)
                                    }
                                    disableGutters
                                  >
                                    <ListItemIcon>
                                      <AssignmentIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={item.name} />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                            <Divider />
                          </>
                        ) : null}

                        {/* check whether any attachment control can view
                        if yes, show the attachment header */}
                        {attachmentSecurities.every(
                          (s) =>
                            !s.permission.canPreview &&
                            !s.permission.canUpload &&
                            !s.permission.canDownload &&
                            !s.permission.canDelete
                        ) ? null : (
                          <ListItem style={listItemStyles.header}>
                            <ListItemText
                              primary={t("formruntime.attachment")}
                            />
                          </ListItem>
                        )}
                        {/* if any of attachment control can view, 
                        display the attachment control(s). */}
                        {attachmentSecurities?.map((s, index) =>
                          !s.permission.canPreview &&
                          !s.permission.canUpload &&
                          !s.permission.canDownload &&
                          !s.permission.canDelete ? null : !IsTerminalState(
                              caseInfo?.state
                            ) && s.permission.canUpload ? (
                            <ListItem
                              disablePadding
                              key={`${s.name ?? "attachment"}${index}`}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  padding: `${
                                    index === 0 ? "4px" : "0px"
                                  } 8px 8px 8px`,
                                }}
                              >
                                <Typography
                                  component={"div"}
                                  variant="caption"
                                  textAlign={"left"}
                                  width="100%"
                                  marginLeft={"8px"}
                                >
                                  {s.name}
                                </Typography>

                                <Dropzone
                                  accept=".zip,application/zip,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.cmmn,.log,text/plain,application/json,image/jpeg"
                                  onChange={(e) => updateFiles(e, s.name)}
                                  minHeight={"50px"}
                                  header={false}
                                  footer={false}
                                  clickable={clickable}
                                  autoClean
                                  value={fileInput[s.name] ?? []}
                                  headerConfig={{
                                    deleteFiles: fileInput[s.name]?.length > 0,
                                  }}
                                >
                                  {fileInput[s.name]?.map((f) => (
                                    <FileMosaic
                                      style={{
                                        height: "80px",
                                        width: "80px",
                                        overflow: "hidden",
                                      }}
                                      key={`file_${f.id}`}
                                      {...f}
                                      uploadStatus={"uploading"}
                                    />
                                  ))}
                                  {!s.permission.canUpload &&
                                  !s.permission.canDownload &&
                                  !s.permission.canDelete ? null : attachments[
                                      s.name
                                    ]?.length > 0 ||
                                    fileInput[s.name]?.length > 0 ? (
                                    attachments[s.name]?.map((item, index) => {
                                      if (item !== null) {
                                        return (
                                          <Tooltip
                                            key={`attachment_${index}`}
                                            title={item.name}
                                            placement="top"
                                          >
                                            <ListItem
                                              sx={{ m: "0" }}
                                              disablePadding
                                            >
                                              <ListItemButton
                                                disableGutters
                                                sx={{ p: "0px" }}
                                              >
                                                <AttachFileIcon />
                                                <ListItemText
                                                  primaryTypographyProps={{
                                                    variant: "subtitle2",
                                                    style: {
                                                      whiteSpace: "nowrap",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                    },
                                                  }}
                                                  primary={
                                                    <Tooltip
                                                      title={item.fileName}
                                                    >
                                                      {item.fileName}
                                                    </Tooltip>
                                                  }
                                                />
                                                {s.permission.canDownload ? (
                                                  isLoadingDownloadAttachment ? (
                                                    <LoadingButton
                                                      size="small"
                                                      loading
                                                      style={{
                                                        padding: "0px",
                                                      }}
                                                    ></LoadingButton>
                                                  ) : (
                                                    <IconButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDownloadAttachmentButtonOnClick(
                                                          s.name,
                                                          index
                                                        );
                                                      }}
                                                      size="small"
                                                    >
                                                      <DownloadIcon fontSize="inherit" />
                                                    </IconButton>
                                                  )
                                                ) : null}
                                                {item.isNew ||
                                                (s.permission.canDelete &&
                                                  item.caseSid === CaseSID) ? (
                                                  isLoadingDeleteAttachment ? (
                                                    <LoadingButton
                                                      size="small"
                                                      loading
                                                      disabled={
                                                        !s.permission.canDelete
                                                      }
                                                      style={{
                                                        padding: "0px",
                                                      }}
                                                    ></LoadingButton>
                                                  ) : (
                                                    <IconButton
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenDeleteAttachmentDialog(
                                                          s.name,
                                                          index
                                                        );
                                                      }}
                                                      size="small"
                                                    >
                                                      <DeleteIcon fontSize="inherit" />
                                                    </IconButton>
                                                  )
                                                ) : null}
                                                {item.isChecked ? (
                                                  <VerifiedIcon fontSize="small" />
                                                ) : null}
                                                <a href=""></a>
                                              </ListItemButton>
                                            </ListItem>
                                          </Tooltip>
                                        );
                                      }

                                      return null;
                                    })
                                  ) : s.permission.canUpload ? (
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        fontSize: "14px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {t("formruntime.drop_files")}
                                      <CloudUploadOutlinedIcon
                                        sx={{ mx: 1 }}
                                        fontSize="medium"
                                      />
                                      {t("formruntime.to_upload")}
                                    </div>
                                  ) : null}
                                </Dropzone>
                              </div>
                            </ListItem>
                          ) : (
                            <>
                              <ListItem
                                disablePadding
                                sx={listItemStyles.attachment}
                                key={`${s.name ?? "attachment"}${index}`}
                              >
                                <div>
                                  <Typography
                                    component={"div"}
                                    variant="caption"
                                    textAlign={"left"}
                                    width="100%"
                                    marginLeft={"8px"}
                                  >
                                    {s.name}
                                  </Typography>

                                  <div
                                    style={{
                                      border: "solid 1px #ccc",
                                      minHeight: "50px",
                                      borderRadius: "8px",
                                      flex: 1,
                                      color: "rgb(100, 108, 127)",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {attachments[s.name]?.filter(
                                      (a) => a !== null
                                    ).length > 0 ? (
                                      attachments[s.name].map((item, index) => {
                                        if (item !== null) {
                                          return (
                                            <Tooltip
                                              key={`attachment_${index}`}
                                              title={item.name}
                                              placement="top"
                                            >
                                              <ListItem
                                                disablePadding
                                                sx={{ py: "5px" }}
                                              >
                                                <ListItemButton
                                                  disableGutters
                                                  sx={{ p: 0 }}
                                                >
                                                  <AttachFileIcon />
                                                  <ListItemText
                                                    primaryTypographyProps={{
                                                      variant: "subtitle2",
                                                      style: {
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow:
                                                          "ellipsis",
                                                      },
                                                    }}
                                                    primary={
                                                      <Tooltip
                                                        title={item.fileName}
                                                      >
                                                        {item.fileName}
                                                      </Tooltip>
                                                    }
                                                  />
                                                  {s.permission.canDownload ? (
                                                    isLoadingDownloadAttachment ? (
                                                      <LoadingButton
                                                        size="small"
                                                        loading
                                                        style={{
                                                          padding: "0px",
                                                        }}
                                                      ></LoadingButton>
                                                    ) : (
                                                      <IconButton
                                                        onMouseEnter={() =>
                                                          setClickable(false)
                                                        }
                                                        onMouseLeave={() =>
                                                          setClickable(true)
                                                        }
                                                        onClick={() =>
                                                          handleDownloadAttachmentButtonOnClick(
                                                            s.name,
                                                            index
                                                          )
                                                        }
                                                        size="small"
                                                      >
                                                        <DownloadIcon fontSize="inherit" />
                                                      </IconButton>
                                                    )
                                                  ) : null}
                                                </ListItemButton>
                                              </ListItem>
                                            </Tooltip>
                                          );
                                        }

                                        return null;
                                      })
                                    ) : (
                                      <ListItemText
                                        style={{
                                          color: "#aaa",
                                          textAlign: "center",
                                        }}
                                      >
                                        {t("formruntime.empty")}
                                      </ListItemText>
                                    )}
                                  </div>
                                </div>
                              </ListItem>
                            </>
                          )
                        )}
                        {attachmentSecurities?.length > 0 ? <Divider /> : null}

                        {/* related case list */}
                        {caseInfo?.relatedCases?.length > 0 ? (
                          <>
                            <ListItem style={listItemStyles.header}>
                              <ListItemText
                                primary={t("formruntime.related_cases")}
                              />
                            </ListItem>
                            {caseInfo.relatedCases.map((c, index) => (
                              <ListItem
                                key={`related_case_${index}`}
                                disablePadding
                              >
                                <ListItemButton
                                  disableGutters
                                  component={Link}
                                  to={`${process.env.PUBLIC_URL}/case/${c.childCaseSID}`}
                                  color="inherit"
                                >
                                  <ListItemIcon>
                                    {c.state === ELEMENT_STATUS.Completed ? (
                                      <CheckIcon color="success" />
                                    ) : c.state ===
                                      ELEMENT_STATUS.Terminated ? (
                                      <CloseIcon color={"error"} />
                                    ) : c.locked ? (
                                      <BlockIcon color="error" />
                                    ) : (
                                      <BusinessCenterIcon />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText>
                                    {c.childCaseNumber}
                                    {c.childCaseDisplayName ? (
                                      <>
                                        <br />
                                        <Typography variant={"caption"}>
                                          {c.childCaseDisplayName}
                                        </Typography>
                                      </>
                                    ) : null}
                                  </ListItemText>
                                </ListItemButton>
                              </ListItem>
                            ))}
                            <Divider />
                          </>
                        ) : null}
                      </List>
                    </Drawer>
                  </Box>
                </Grid>
              </div>
            </Grid>
            {/* activity log drawer */}
            <Grid
              xs={"auto"}
              id="drawerContainer"
              sx={{
                height: isOpenActivityLog
                  ? `${activityLogDrawerHeight - 3}px`
                  : "45px",
              }}
            >
              <Drawer
                id="ActivityLogDrawer"
                open={isOpenActivityLog}
                anchor={"bottom"}
                variant={"permanent"}
              >
                <div
                  id="DrawerGrip"
                  onPointerDown={handleActivityLogDrawerGripPointerDown}
                  // onMouseDown={(e) => handleActivityLogDrawerGripMouseDown(e)}
                  // onTouchStart={(e) => handleActivityLogDrawerGripTouchStart(e)}
                  style={{
                    display: showActivityLogSplitter ? "block" : "none",
                    height: "5px",
                    cursor: "ns-resize",
                    padding: "0 0 0 4px",
                    position: "sticky",
                    backgroundColor:
                      theme.palette.mainContainer[theme.palette.mode],
                    top: 0,
                    right: 0,
                    left: 0,
                    zIndex: 100,
                  }}
                />
                <Paper
                  id="ActivityLogPaper"
                  sx={{
                    ml: showLeftHandSideDrawer ? `${DRAWER_WIDTH}px` : 0,
                    maxHeight: activityLogDrawerHeight,
                    minHeight:
                      isMdUp || !isOpenActivityLog
                        ? 0
                        : document.body.offsetHeight,
                    borderRadius: 0,
                    overflow: "auto",
                  }}
                >
                  <Toolbar
                    sx={{
                      bgcolor: `secondaryContainer.${themeColorContext[0]}`,
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        overflow: "hidden",
                      }}
                    >
                      <IconButton onClick={handleOpenFlowChart}>
                        <AccountTreeOutlinedIcon />
                      </IconButton>
                      <Dialog
                        open={isOpenFlowChart}
                        onClose={handleCloseFlowChart}
                        fullScreen={fullScreen}
                        sx={{
                          "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                              width: "100%",
                              maxWidth: fullScreen ? "100%" : "60%",
                              height: fullScreen ? "100%" : "60%",
                            },
                          },
                        }}
                      >
                        <IconButton
                          onClick={handleCloseFlowChart}
                          sx={{
                            position: "absolute",
                            right: 5,
                            top: 5,
                            zIndex: 1,
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                        {isSmUp ? (
                          fullScreen ? (
                            <IconButton
                              size="small"
                              onClick={handleExitFullScreen}
                              sx={{
                                position: "absolute",
                                right: 5,
                                bottom: 5,
                                zIndex: 1,
                              }}
                            >
                              <FullscreenExitIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={handleOpenFullScreen}
                              sx={{
                                position: "absolute",
                                right: 5,
                                bottom: 5,
                                zIndex: 1,
                              }}
                            >
                              <FullscreenIcon />
                            </IconButton>
                          )
                        ) : null}
                        {caseInfo &&
                        !isLoadingAllTaskInfo &&
                        !isLoadingCurrentStageLog &&
                        !isLoadingHistoryLog ? (
                          <Flow
                            currentTask={currentTask}
                            caseInfo={caseInfo}
                            allTaskInfo={allTaskInfo}
                            flowTask={flowTaskInfo}
                            activityLog={activityLog}
                            currentStageLog={currentStageLog}
                          />
                        ) : (
                          <LoadingSpinner isOpen={isLoading} />
                        )}
                      </Dialog>
                    </Box>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                      color="inherit"
                      onClick={handleToggleActivityLog}
                    >
                      {isOpenActivityLog ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowUpIcon />
                      )}
                    </IconButton>
                    <Typography component={"span"}>
                      {t("formruntime.gcpdf_viewer.activity_log")}
                    </Typography>
                  </Toolbar>

                  {isOpenActivityLog ? (
                    <Grid id="logContent">
                      <Box
                        sx={{
                          height: "100%",
                          bgcolor: `secondaryContainer.${themeColorContext[0]}`,
                          p: 1,
                        }}
                      >
                        {/* current stage log */}
                        {!IsTerminalState(caseInfo?.state) ? (
                          <Grid
                            container
                            direction={"column"}
                            sx={{ p: isSmUp ? "10px" : "10px 0px" }}
                          >
                            <Grid xs={"auto"} textAlign={"left"}>
                              <h3>
                                {t(
                                  "formruntime.gcpdf_viewer.current_stage_log"
                                )}
                              </h3>
                            </Grid>
                            <Grid xs>
                              <Box sx={{ overflowX: "auto", width: "100%" }}>
                                <Table>
                                  <TableHead
                                    sx={{
                                      fontWeight: "600",
                                      // bgcolor: "lightgray"
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell>
                                        {t(
                                          "formruntime.activity_log.arrival_date"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          "formruntime.activity_log.task_name"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          "formruntime.activity_log.current_user"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t("formruntime.activity_log.status")}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {isLoadingCurrentStageLog ? (
                                    <TableBody>
                                      <TableRow>
                                        {Array.from(new Array(4)).map(
                                          (_, i) => (
                                            <TableCell
                                              key={`skeleton_cell_${i}`}
                                            >
                                              <Skeleton
                                                variant="text"
                                                sx={{
                                                  width: "50%",
                                                  height: "30px",
                                                }}
                                              />
                                            </TableCell>
                                          )
                                        )}
                                      </TableRow>
                                    </TableBody>
                                  ) : currentStageLog?.length > 0 ? (
                                    currentStageLog.map((row, rowindex) => (
                                      <TableRow
                                        key={`current_stage_${rowindex}`}
                                      >
                                        <TableCell
                                          sx={{
                                            whiteSpace: {
                                              xs: "nowrap",
                                            },
                                          }}
                                        >
                                          {dayjs
                                            .utc(row.logDate)
                                            .tz(userTimezone)
                                            .format("YYYY-MM-DD HH:mm")}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                          {row.taskName}
                                        </TableCell>
                                        <TableCell>{row.logUserName}</TableCell>
                                        <TableCell
                                          sx={{
                                            whiteSpace: {
                                              xs: "nowrap",
                                            },
                                          }}
                                        >
                                          {row.status}
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  ) : (
                                    <TableBody>
                                      <TableRow>
                                        <TableCell colSpan={4}>
                                          {t(
                                            "formruntime.gcpdf_viewer.no_current_stage_log"
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  )}
                                </Table>
                              </Box>
                            </Grid>
                          </Grid>
                        ) : null}
                        {/* activity log */}
                        <Grid
                          container
                          direction={"column"}
                          sx={{ p: isSmUp ? "10px" : "10px 0px" }}
                        >
                          <Grid
                            container
                            item
                            xs={"auto"}
                            textAlign={"left"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <h3 style={{ display: "inline-block" }}>
                              {t("formruntime.gcpdf_viewer.history_log")}
                            </h3>
                            {profile?.accountType == 1 ? (
                              <Select
                                value={historyLogType}
                                onChange={handleHistoryLogTypeChange}
                                sx={{ width: "150px", height: "40px" }}
                              >
                                <MenuItem value="info">
                                  {t("formruntime.activity_log.info")}
                                </MenuItem>
                                <MenuItem value="trace">
                                  {t("formruntime.activity_log.trace")}
                                </MenuItem>
                              </Select>
                            ) : null}
                          </Grid>
                          <Grid item xs>
                            <Box sx={{ overflowX: "auto", width: "100%" }}>
                              <Table>
                                <TableHead
                                  sx={{
                                    fontWeight: "600",
                                    // bgcolor: "lightgray"
                                  }}
                                >
                                  {isSmUp ? (
                                    <TableRow>
                                      <TableCell>
                                        {historyLogType === "info"
                                          ? t(
                                              "formruntime.activity_log.arrival_date"
                                            )
                                          : t(
                                              "formruntime.activity_log.log_date"
                                            )}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          "formruntime.activity_log.complete_date"
                                        )}
                                      </TableCell>
                                      {historyLogType === "trace" ? (
                                        <TableCell>
                                          {t("formruntime.activity_log.type")}
                                        </TableCell>
                                      ) : null}
                                      <TableCell>
                                        {historyLogType === "info"
                                          ? t(
                                              "formruntime.activity_log.task_name"
                                            )
                                          : t("formruntime.activity_log.name")}
                                      </TableCell>
                                      <TableCell>
                                        {t(
                                          "formruntime.activity_log.current_user"
                                        )}
                                      </TableCell>
                                      <TableCell>
                                        {t("formruntime.activity_log.action")}
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <>
                                      <TableRow>
                                        <TableCell>
                                          {historyLogType === "info"
                                            ? t(
                                                "formruntime.activity_log.arrival_date"
                                              )
                                            : t(
                                                "formruntime.activity_log.log_date"
                                              )}
                                        </TableCell>
                                        {historyLogType === "trace" ? (
                                          <TableCell rowSpan={2}>
                                            {t("formruntime.activity_log.type")}
                                          </TableCell>
                                        ) : null}
                                        <TableCell rowSpan={2}>
                                          {historyLogType === "info"
                                            ? t(
                                                "formruntime.activity_log.task_name"
                                              )
                                            : t(
                                                "formruntime.activity_log.name"
                                              )}
                                        </TableCell>
                                        <TableCell rowSpan={2}>
                                          {t(
                                            "formruntime.activity_log.current_user"
                                          )}
                                        </TableCell>
                                        <TableCell rowSpan={2}>
                                          {t("formruntime.activity_log.action")}
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell>
                                          {t(
                                            "formruntime.activity_log.complete_date"
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                                </TableHead>
                                <TableBody>
                                  {isLoadingHistoryLog ? (
                                    <TableRow>
                                      {Array.from(
                                        new Array(
                                          isSmUp
                                            ? 5
                                            : 4 + historyLogType === "info"
                                            ? 0
                                            : 1
                                        )
                                      ).map((_, index) => (
                                        <TableCell key={`history_col_${index}`}>
                                          <Skeleton
                                            variant="text"
                                            sx={{
                                              width: "50%",
                                              height: "30px",
                                            }}
                                          />
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ) : activityLog?.length > 0 ? (
                                    activityLog
                                      .filter(
                                        (l) =>
                                          historyLogType !== "info" ||
                                          l.viewLogType === 1
                                      )
                                      .map((row, rowindex) => (
                                        <React.Fragment
                                          key={`history_row_${rowindex}`}
                                        >
                                          <TableRow>
                                            <TableCell
                                              sx={{
                                                whiteSpace: {
                                                  xs: "nowrap",
                                                },
                                                borderBottomWidth: isSmUp
                                                  ? 1
                                                  : 0,
                                                paddingBottom: isSmUp
                                                  ? null
                                                  : 0.5,
                                                verticalAlign: isSmUp
                                                  ? "center"
                                                  : "bottom",
                                              }}
                                            >
                                              {dayjs
                                                .utc(
                                                  row.viewLogType == 1
                                                    ? row.startDate
                                                    : row.logDate
                                                )
                                                .tz(userTimezone)
                                                .format("YYYY-MM-DD HH:mm")}
                                            </TableCell>
                                            {isSmUp ? (
                                              <TableCell
                                                sx={{
                                                  whiteSpace: {
                                                    xs: "nowrap",
                                                  },
                                                }}
                                              >
                                                {row.completeDate
                                                  ? dayjs
                                                      .utc(row.completeDate)
                                                      .tz(userTimezone)
                                                      .format(
                                                        "YYYY-MM-DD HH:mm"
                                                      )
                                                  : "--"}
                                              </TableCell>
                                            ) : null}
                                            {historyLogType === "trace" ? (
                                              <TableCell
                                                component={"th"}
                                                scope="row"
                                                rowSpan={isSmUp ? 1 : 2}
                                              >
                                                {getPlanItemDefinitionType(
                                                  row.planItemDefinitionType
                                                )}
                                              </TableCell>
                                            ) : null}
                                            <TableCell
                                              component="th"
                                              scope="row"
                                              rowSpan={isSmUp ? 1 : 2}
                                            >
                                              {row.taskName}
                                            </TableCell>
                                            <TableCell rowSpan={isSmUp ? 1 : 2}>
                                              {row.logUserName}
                                            </TableCell>
                                            <TableCell rowSpan={isSmUp ? 1 : 2}>
                                              {row.action}
                                            </TableCell>
                                          </TableRow>
                                          {!isSmUp ? (
                                            <TableRow>
                                              <TableCell
                                                sx={{
                                                  whiteSpace: {
                                                    xs: "nowrap",
                                                  },
                                                  paddingTop: isSmUp
                                                    ? null
                                                    : 0.5,
                                                  verticalAlign: isSmUp
                                                    ? "center"
                                                    : "top",
                                                }}
                                              >
                                                {row.completeDate
                                                  ? dayjs
                                                      .utc(row.completeDate)
                                                      .tz(userTimezone)
                                                      .format(
                                                        "YYYY-MM-DD HH:mm"
                                                      )
                                                  : "--"}
                                              </TableCell>
                                            </TableRow>
                                          ) : null}
                                        </React.Fragment>
                                      ))
                                  ) : (
                                    <TableRow>
                                      <TableCell colSpan={4}>
                                        {t(
                                          "formruntime.gcpdf_viewer.no_history_log"
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ) : null}
                </Paper>
              </Drawer>
            </Grid>
          </Grid>
          <LoadingSpinner isOpen={isLoading} />
          <SharedDialog
            isOpen={showAlertDialog}
            onClose={() => setShowAlertDialog(false)}
            title={alertDialog.title}
            content={alertDialog.content}
            buttons={alertDialog.buttons}
          />
        </>
      )}
    </div>
  );
};

FormRuntime.propTypes = {
  isOutbox: PropTypes.bool,
  settitle: PropTypes.func.isRequired,
  setFormIdentity: PropTypes.func.isRequired,
};

export default FormRuntime;
