import * as React from "react";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import { Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import urlJoin from "url-join";
import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { Dropzone, FileMosaic } from "@files-ui/react";

import {
  CallApiWithContext,
  CallApiWithUploadFile,
} from "../../../../helpers/ApiHelper";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import { resServerBaseUrl } from "../../../../Config";
import SharedDialog from "../../../../shared/SharedDialog";
import LoadingSpinner from "../../../../shared/LoadingSpinner";
import { useThemeColor } from "../../../../providers/ThemeColorProvider";

export default function ImportSetting() {
  const { t } = useTranslation();
  const [result, SetResult] = React.useState(null);
  const authenticationContext = useAuthentication();
  const [alertDialog, setAlertDialog] = React.useState({
    showDialog: false,
    content: "",
    title: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => {},
      },
    ],
  });
  const [serverBypassDialog, setServerBypassDialog] = React.useState(false);
  const [isUseLoadingSpinner, setIsUseLoadingSpinner] = React.useState(false);
  const [customSetting, setCustomSetting] = React.useState({});
  const [userInfo, setUserInfo] = React.useState({});
  const [files, setFiles] = React.useState([]);
  const themeColorContext = useThemeColor();

  const updateFiles = (incommingFiles) => {
    //do something with the files
    console.log("incomming files", incommingFiles);
    setFiles(incommingFiles);
    //even your own upload implementation
  };
  const removeFile = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };

  const handleCancel = (id) => {
    setFiles(
      files.map((ef) => {
        if (ef.id === id) {
          return { ...ef, uploadStatus: undefined };
        } else return { ...ef };
      })
    );
  };

  const handleAlertDialogClose = () => {
    setAlertDialog({
      showDialog: false,
      content: "",
      title: "",
      buttons: [{ text: "", action: () => {} }],
    });
  };
  const handleServerBypassDialogClose = () => {
    customSetting.AVSERVERBYPASS = false;
    updateCustomSetting();
    setServerBypassDialog(false);
    SetResult(t("administrative_console.import_page.import_fail"));
  };

  const handleServerBypassConfirm = () => {
    customSetting.AVSERVERBYPASS = true;
    updateCustomSetting();
    setServerBypassDialog(false);
    importDataSkipedVirusCheck();
  };

  const handleDoNotShowAgainChange = (prop) => (event) => {
    setCustomSetting({ ...customSetting, [prop]: event.target.checked });
  };

  const onClickFn = () => {
    setIsUseLoadingSpinner(true);
    let file = new FormData();
    if (files.length == 0) {
      setAlertDialog({
        showDialog: true,
        content: t("administrative_console.import_page.please_select_a_file"),
        title: "",
        buttons: [
          {
            text: t("administrative_console.report_page.confirm"),
            action: handleAlertDialogClose,
          },
        ],
      });
      setIsUseLoadingSpinner(false);
    } else {
      // file.append("file", fileInput.target.files[0]);
      files.map((file1) => file.append("file", file1.file));
      const url = urlJoin(resServerBaseUrl, "/Import/ImportData");

      for (let value of file.values()) {
        let upfile = new FormData();
        upfile.append("file", value);
        CallApiWithUploadFile(url, authenticationContext, upfile)
          .then((response) => {
            if (response && response.renamed && response.data) {
              const continueImport = (token, continueImport) => {
                handleAlertDialogClose();
                setIsUseLoadingSpinner(true);
                SetResult("");

                const contUrl = urlJoin(
                  resServerBaseUrl,
                  "/Import/ImportByToken"
                );

                CallApiWithContext(
                  contUrl,
                  authenticationContext,
                  JSON.stringify({
                    ContinueImport: continueImport,
                    Token: token,
                  })
                )
                  .then((res) => {
                    SetResult(res.message);
                  })
                  .catch(console.log)
                  .finally(() => {
                    setIsUseLoadingSpinner(false);
                  });
              };

              let renamed = "";

              Object.entries(response.data.value).forEach(([key, value]) => {
                renamed +=
                  (renamed === "" ? "" : "\n") +
                  t(
                    "administrative_console.import_page.import_renamed_template",
                    { from: key, to: value }
                  );
              });

              setAlertDialog({
                showDialog: true,
                content: t(
                  "administrative_console.import_page.import_renamed_message",
                  { value: renamed }
                ),
                title: t(
                  "administrative_console.import_page.import_renamed_title"
                ),
                buttons: [
                  {
                    text: t("common.continue"),
                    action: () => continueImport(response.data.token, true),
                  },
                  {
                    text: t("common.cancel"),
                    variant: "outlined",
                    color: "error",
                    action: () => continueImport(response.data.token, false),
                  },
                ],
              });
            } else if (response && response.data) {
              var byteString = atob(response.data);
              var arrayBuffer = new ArrayBuffer(byteString.length);
              var intArray = new Uint8Array(arrayBuffer);

              for (var i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
              }
              const blob = new Blob([intArray], {
                type: "application/vnd.ms-excel",
              });
              saveAs(blob, response.fileName);
              //SetResult(t('administrative_console.import_page.successed'));
              setAlertDialog({
                showDialog: true,
                content: t(
                  "administrative_console.import_page.upload_succeed_message"
                ),
                title: t("administrative_console.import_page.upload_succeeded"),
                buttons: [
                  {
                    text: t("administrative_console.report_page.confirm"),
                    action: handleAlertDialogClose,
                  },
                ],
              });
            } else if (response.message == "Inactive") {
              const url = urlJoin(resServerBaseUrl, "/User/GetUserProfile");
              CallApiWithContext(url, authenticationContext).then((json) => {
                if (json != null) {
                  var customSetting = JSON.parse(json.customSetting);
                  setCustomSetting(customSetting);
                  setUserInfo(json);
                  if (
                    customSetting.KEEPAVSERVERBYPASS == true &&
                    customSetting.AVSERVERBYPASS == true
                  ) {
                    importDataSkipedVirusCheck();
                    setIsUseLoadingSpinner(false);
                  } else if (
                    customSetting.KEEPAVSERVERBYPASS == true &&
                    customSetting.AVSERVERBYPASS == false
                  ) {
                    SetResult(
                      t("administrative_console.import_page.import_fail")
                    );
                    setIsUseLoadingSpinner(false);
                  } else {
                    setServerBypassDialog(true);
                    setIsUseLoadingSpinner(false);
                  }
                }
              });
            } else if (response) {
              SetResult(response.message);
              setIsUseLoadingSpinner(false);
              //SetResult(t('administrative_console.import_page.importing'));
            } else {
              SetResult(t("administrative_console.import_page.import_fail"));
              setIsUseLoadingSpinner(false);
            }
          })
          .catch((error) => {
            console.log(error);

            setIsUseLoadingSpinner(false);
            setAlertDialog({
              showDialog: true,
              content: error,
              title: "Error",
              buttons: [
                {
                  text: t("administrative_console.report_page.confirm"),
                  action: handleAlertDialogClose,
                },
              ],
            });
          });
      }
    }
  };
  const importDataSkipedVirusCheck = () => {
    setIsUseLoadingSpinner(true);
    let file = new FormData();
    // file.append("file", fileInput.target.files[0]);
    files.map((file1) => file.append("file", file1.file));
    console.log("WFrdwew", files[0].file);
    const url = urlJoin(resServerBaseUrl, "/Import/ImportDataSkipedVirusCheck");
    for (let value of file.values()) {
      let upfile = new FormData();
      upfile.append("file", value);
      console.log("Sadfsfw", value);
      CallApiWithUploadFile(url, authenticationContext, upfile)
        .then((response) => {
          if (response && response.data) {
            var byteString = atob(response.data);
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var intArray = new Uint8Array(arrayBuffer);

            for (var i = 0; i < byteString.length; i++) {
              intArray[i] = byteString.charCodeAt(i);
            }
            const blob = new Blob([intArray], {
              type: "application/vnd.ms-excel",
            });
            saveAs(blob, response.fileName);
            SetResult(t("administrative_console.import_page.successed"));
          } else if (response) {
            SetResult(response.message);
          } else {
            SetResult(t("administrative_console.import_page.import_fail"));
          }
          setIsUseLoadingSpinner(false);
        })
        .catch((error) => {
          setIsUseLoadingSpinner(false);
          console.log(error);
          setAlertDialog({
            showDialog: true,
            content: t("report.error"),
            title: t("report.error"),
            buttons: [
              {
                text: t("administrative_console.report_page.confirm"),
                action: handleAlertDialogClose,
              },
            ],
          });
        });
    }
  };
  const updateCustomSetting = () => {
    var saveData = {
      ID: userInfo.id,
      Login: userInfo.login,
      CustomSetting: JSON.stringify(customSetting),
    };
    const updateUserURL = urlJoin(
      resServerBaseUrl,
      "User/UpdateUserCustomSetting"
    );
    CallApiWithContext(
      updateUserURL,
      authenticationContext,
      JSON.stringify(saveData)
    )
      .then((response) => {
        console.log(response.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Grid container direction="column" spacing={1} sx={{ p: "1%" }}>
        <Grid item style={{ textAlign: "left" }}>
          <p>{t("administrative_console.import_page.description")}</p>
          <h5>{t("administrative_console.import_page.support")}</h5>
        </Grid>
        <Grid item>
          <Dropzone
            background={
              themeColorContext[0] == "light"
                ? "radial-gradient(circle at 18.7% 37.8%, rgb(250, 250, 250) 0%, rgb(225, 234, 238) 90%);"
                : "radial-gradient(circle at 18.7% 37.8%, rgb(33 33 33) 0%, rgb(33 33 33) 0%)"
            }
            accept=".zip,.docx,application/zip,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.cmmn"
            headerConfig={{ deleteFiles: files.length == 0 ? false : true }}
            onChange={updateFiles}
            value={files}
            label={
              <div>
                <CloudUploadOutlinedIcon sx={{ fontSize: 55 }} />
                <Typography variant="h6">
                  {t("administrative_console.import_page.dropzoneText")}
                </Typography>
              </div>
            }
            autoClean
            actionButtons={{
              position: "after",
              uploadButton: {
                children: t("administrative_console.import_page.submit"),
                onClick: () => {
                  onClickFn();
                },
                style: {
                  margin: "auto",
                  backgroundColor:
                    themeColorContext[0] == "light" ? "#3a6015" : "#599121",
                },
              },
            }}
            footerConfig={{
              customMessage: (
                <h5>{t("administrative_console.import_page.support")}</h5>
              ),
            }}
            // uploadConfig={{
            //   autoUpload: true,
            //   url: urlJoin(resServerBaseUrl, "/Import/ImportData"),
            //   headers: {
            //     Authorization: "Bearer " + localStorage.getItem("accessToken"),
            //     "X-FP-API-KEY": "chaptoken",
            //   },
            // }}
          >
            {files.map((file) => (
              <FileMosaic
                key={file.id}
                {...file}
                onDelete={removeFile}
                onCancel={handleCancel}
                valid={undefined}
                resultOnTooltip
                alwaysActive
                info
              />
            ))}
          </Dropzone>
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          {result ? <h4>{result}</h4> : null}
        </Grid>
        <SharedDialog
          isOpen={alertDialog.showDialog}
          onClose={handleAlertDialogClose}
          title={alertDialog.title}
          content={alertDialog.content}
          buttons={alertDialog.buttons}
        ></SharedDialog>
        <Dialog
          open={serverBypassDialog}
          onClose={handleServerBypassDialogClose}
        >
          <Grid sx={{ bgcolor: "white", color: "grey" }}>
            <DialogTitle>
              {t("administrative_console.import_page.confirm_bypass_server")}
            </DialogTitle>
          </Grid>
          <Grid item style={{ textAlign: "left", marginLeft: 25 }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={customSetting.KEEPAVSERVERBYPASS}
                    onChange={handleDoNotShowAgainChange("KEEPAVSERVERBYPASS")}
                  />
                }
                label={t("administrative_console.import_page.remember")}
              />
            </FormGroup>
          </Grid>
          <Grid>
            <DialogActions>
              <Button onClick={handleServerBypassConfirm}>
                {t("administrative_console.import_page.yes")}
              </Button>
              <Button onClick={handleServerBypassDialogClose}>
                {t("administrative_console.import_page.no")}
              </Button>
            </DialogActions>
          </Grid>
        </Dialog>
        <LoadingSpinner isOpen={isUseLoadingSpinner}></LoadingSpinner>
      </Grid>
    </div>
  );
}
