import * as React from "react";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableBody,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowCircleDown,
  ArrowCircleUp,
  Launch,
  ListAlt,
  Refresh,
  Settings,
} from "@mui/icons-material";
import urlJoin from "url-join";
import { resServerBaseUrl } from "../../../Config";
import { CallApiWithContext } from "../../../helpers/ApiHelper";
import { useAuthentication } from "../../../providers/AuthenticationProvider";
import "../Dashboard.css";
import SharedDialog from "../../../shared/SharedDialog";
import dayjs from "dayjs";
import { useTheme } from "@emotion/react";
import PropTypes from "prop-types";
import utc from "dayjs/plugin/utc";
import { ELEMENT_STATUS } from "../../../Constants";
import { useThemeColor } from "../../../providers/ThemeColorProvider";
import { useNavigate } from "react-router-dom";

dayjs.extend(utc);

const ListChart = React.forwardRef((props, ref) => {
  const { settings, settingsSID, editFunction, setLoadingSpinnerLoad } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const authenticationContext = useAuthentication();

  const [dashboardSetting, setDashboardSetting] = React.useState({});
  const [criteriaType, setCriteriaType] = React.useState({
    x: "",
    y: "",
  });
  const [chartDatas, setChartDatas] = React.useState({});
  const [chartRequests, setChartRequests] = React.useState({});
  const [showAlertDialog, setShowAlertDialog] = React.useState(false);
  const [alertDialog, setAlertDialog] = React.useState({
    title: "",
    content: "",
    buttons: [
      {
        text: t("administrative_console.report_page.confirm"),
        action: () => setShowAlertDialog(false),
      },
    ],
  });
  const [request, setRequest] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [lastSelectedSection, setLastSelectedSection] = React.useState({});
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [caseList, setCaseList] = React.useState([]);

  const levelRef = React.useRef();
  const themeColorContext = useThemeColor();
  const nav = useNavigate();
  const timezone = dayjs.tz.guess();

  const criteriaOperatorOption = React.useMemo(() => {
    const option = (xy) => {
      switch (xy) {
        case "String":
          return [
            { label: "=", value: 7 },
            { label: "in", value: 14 },
            { label: "like", value: 13 },
          ];
        case "Number":
          return [
            { label: ">", value: 9 },
            { label: "<", value: 10 },
            { label: "=", value: 7 },
            { label: "<=", value: 11 },
            { label: ">=", value: 12 },
            { label: "between", value: 15 },
            { label: "in", value: 14 },
          ];
        case "Date":
          return [
            { label: ">", value: 9 },
            { label: "<", value: 10 },
            { label: "=", value: 15 },
            { label: "<=", value: 11 },
            { label: ">=", value: 12 },
          ];
      }
    };
    return {
      x: option(criteriaType?.x ?? "String"),
      y: option(criteriaType?.y ?? "String"),
    };
  }, [criteriaType]);

  const handleDrillDown = (i) => {
    if (
      levelRef.current + 1 <
      Object.keys(JSON.parse(settings.settings).x).length
    ) {
      setLoading(true);
      levelRef.current = levelRef.current + 1;
      let selectedFunction = JSON.parse(settings.settings).chartRequest
        .Function[0];
      let sectionObj = lastSelectedSection;
      sectionObj[levelRef.current] = data[i].label;
      setLastSelectedSection(sectionObj);
      GenerateDashboardData(
        levelRef.current,
        sectionObj[levelRef.current],
        selectedFunction
      );
    }
  };

  const GenerateDashboardData = (level, selectedSection, selectedFunction) => {
    const url = urlJoin(resServerBaseUrl, "/Report/GenerateDashboardData");
    const data = {
      DashboardSettingSID: settingsSID,
      Level: level ?? 0,
      SelectedSection: selectedSection ?? null,
      Function: selectedFunction,
    };
    CallApiWithContext(url, authenticationContext, JSON.stringify(data))
      .then((response) => {
        console.log(response);
        if (!Object.keys(response).includes("message")) {
          GenerateChartData(response);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GenerateChartData = (dataArr) => {
    let data = [];
    let maxNameLength = 7;
    let sum = dataArr[0]?.Total ?? 0;
    if (dataArr.length > 0) {
      data = dataArr[0].Data.map((a, i) => {
        return {
          id: i,
          value: a.value,
          label: dayjs(a.label, "M/D/YYYY h:mm:ss A", true).isValid()
            ? dayjs
                .utc(a.label, "M/D/YYYY h:mm:ss A")
                .tz(timezone)
                .format("YYYY-MM-DD HH:mm:ss")
            : a.label,
          availableCases: a.AvailableCases,
        };
      });
      if (data.length > 20) {
        data = data.sort((v1, v2) => v2.value - v1.value).slice(0, 20);
      }
    }
    console.log(data);

    setData(data);
    let setting = {
      sid: dataArr.sid,
      chartName: dataArr.chartName,
      chartType: dataArr.chartType,
      chartRow: dataArr.chartRow,
      chartColumn: dataArr.chartColumn,
      chartOrder: dataArr.chartOrder,
      dataSum: sum,
      x: dataArr.x,
      y: dataArr.y,
      data: dataArr,
    };
    let request = {
      CaseTplUUID: dataArr.uuid,
      TplSID: dataArr.tplSid,
      FilterCriteria: dataArr.criteria,
      IsPreviewReport: dataArr.IsPreviewReport,
      IsDownload: dataArr.IsDownload,
      X: [dataArr.x],
      Y: [dataArr.y],
      Function: dataArr.function,
      Level: dataArr.level,
    };
    setChartDatas(setting);
    setChartRequests(request);
    setLoading(false);
    // dataArr.splice(dataIndex, 1, setting);
    // requestArr.splice(requestIndex, 1, request);
  };

  const handleDrillUp = (event, chartData, settings, drillUp) => {
    setLoading(true);
    let lastSelecSec;
    let lastSelecFun = JSON.parse(settings.settings).chartRequest.Function[0];

    if (levelRef.current - 1 > 0) {
      lastSelecSec = lastSelectedSection[levelRef.current - 1];
    } else if (levelRef.current == 0) {
      lastSelecSec = null;
    }
    levelRef.current = levelRef.current - 1;

    GenerateDashboardData(levelRef.current, lastSelecSec, lastSelecFun);
  };

  const boxWidth = (cols) => {
    switch (cols) {
      case 1:
        return 401;
      case 2:
        return 813;
      case 3:
        return 1227;
      case 4:
        return 1621;
      default:
        return 401;
    }
  };

  const handleRelatedCaseDialogOpen = (cases) => {
    setDialogOpen(true);
    setCaseList(cases);
  };
  const handleRelatedCaseDialogClose = () => {
    setDialogOpen(false);
    setCaseList([]);
  };

  const OpenCase = (sid) => {
    nav(`${process.env.PUBLIC_URL}/case/${sid}/`);
  };

  const handleRefresh = () => {
    setLoading(true);
    if (levelRef.current === 0) {
      GenerateDashboardData(0, null, null);
    } else {
      GenerateDashboardData(
        levelRef.current,
        lastSelectedSection[levelRef.current],
        JSON.parse(settings.settings).chartRequest.Function[0]
      );
    }
  };

  React.useEffect(() => {
    setLoading(true);
    GenerateDashboardData(0, null, null);
    setDashboardSetting(JSON.parse(settings.settings));
    setRequest(JSON.parse(settings.settings).chartRequest);
    levelRef.current = 0;

    console.log(criteriaOperatorOption);
  }, [settings]);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid item xs={"auto"}>
          {levelRef.current === 0 ? null : (
            <IconButton
              disabled={loading}
              onClick={(e) => {
                //add function to handle setting with last level of criteria
                handleDrillUp(e, chartDatas.data, settings, true);
              }}
            >
              <ArrowCircleUp />
            </IconButton>
          )}
        </Grid>
        <Grid item width={150} height={35} overflow={"auto"}>
          <Typography variant="h6" sx={{ wordBreak: "break-word" }}>
            {JSON.parse(settings.settings).chartName.split("").length > 50
              ? JSON.parse(settings.settings).chartName.slice(0, 50) + "..."
              : JSON.parse(settings.settings).chartName}
          </Typography>
        </Grid>
        <Grid container item xs={"auto"}>
          {JSON.parse(settings.settings)?.DynamicDate ??
          []
            .map((d) => d.range[1] === "now" || d.range[1] === 0)
            .includes(true) ? (
            <Grid item>
              <IconButton
                onClick={() => {
                  handleRefresh();
                }}
              >
                <Refresh />
              </IconButton>
            </Grid>
          ) : null}

          <Grid item>
            <IconButton
              onClick={() =>
                editFunction(JSON.parse(settings.settings), settings.sid)
              }
              disabled={loading}
            >
              <Settings />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {loading || data.length === 0 ? (
        <Box
          width={boxWidth(JSON.parse(settings.settings)?.chartColumn ?? 1)}
          height={350 * (JSON.parse(settings.settings)?.chartRow ?? 1)}
          alignContent={"center"}
        >
          {loading ? t("dashboard.loading") : t("dashboard.no_data")}
        </Box>
      ) : (
        <Grid container item direction={"column"} xs={12}>
          <List
            aria-labelledby="header"
            sx={{
              overflow: "auto",
              height: `${350 * JSON.parse(settings.settings).chartColumn}px`,
            }}
            subheader={
              <ListSubheader id={"header"}>
                {
                  Object.values(JSON.parse(settings.settings).x)[
                    levelRef?.current ?? 0
                  ]
                }
              </ListSubheader>
            }
          >
            {data.map((data, i) => (
              <ListItem key={"data" + i}>
                <ListItemText
                  sx={{ width: "200px" }}
                >{`${data.label} : ${data.value}`}</ListItemText>

                {Object.keys(JSON.parse(settings.settings).x).length > 1 &&
                levelRef.current !=
                  Object.keys(JSON.parse(settings.settings).x).length - 1 ? (
                  <Tooltip title={t("dashboard.drill_down")}>
                    <IconButton
                      onClick={() => {
                        handleDrillDown(i);
                      }}
                    >
                      <ArrowCircleDown />
                    </IconButton>
                  </Tooltip>
                ) : null}

                {Object.keys(JSON.parse(settings.settings).x)[
                  levelRef?.current ?? 0
                ] === "case number" ? null : (
                  <Tooltip title={t("dashboard.related_cases")}>
                    <IconButton
                      onClick={() => {
                        handleRelatedCaseDialogOpen(data.availableCases);
                      }}
                    >
                      <ListAlt />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      <Dialog open={dialogOpen} onClose={() => handleRelatedCaseDialogClose()}>
        <DialogTitle>{t("dashboard.related_cases")}</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableCell>
                  {t(
                    "administrative_console.report_page.system_column.case_number"
                  )}
                </TableCell>
                <TableCell>
                  {t("administrative_console.report_page.action")}
                </TableCell>
              </TableHead>
              <TableBody>
                {caseList.map((c) => (
                  <TableRow>
                    <TableCell>{c.CaseNumber}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          OpenCase(c.CaseSID);
                        }}
                      >
                        <Launch />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            title={t("report.exist_dialog.close")}
            onClick={() => handleRelatedCaseDialogClose()}
          />
        </DialogActions>
      </Dialog>

      <SharedDialog
        isOpen={showAlertDialog}
        onClose={() => setShowAlertDialog(false)}
        title={alertDialog.title}
        content={alertDialog.content}
        buttons={alertDialog.buttons}
      />
    </Grid>
  );
});

ListChart.propTypes = {
  settings: PropTypes.object.isRequired,
  settingsSID: PropTypes.string.isRequired,
  editFunction: PropTypes.func.isRequired,
  setLoadingSpinnerLoad: PropTypes.any.isRequired,
};

export default ListChart;
