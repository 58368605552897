import * as React from "react";
import { Autocomplete, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";

import MuiPhoneNumber from "mui-phone-number";
import urlJoin from "url-join";

import UserModulePermission from "./UserModulePermission";
import { resServerBaseUrl } from "../../../../Config";
import { CallApiWithContext } from "../../../../helpers/ApiHelper";
import { useAuthentication } from "../../../../providers/AuthenticationProvider";
import { useTranslation } from "react-i18next";
import { useCountry } from "../../../../providers/CountryProvider";
import {
  AccountCircle,
  Apartment,
  BorderColor,
  Email,
  Language,
  Phone,
  StickyNote2,
  Work,
} from "@mui/icons-material";
import { AddUserPasswordField } from "../../../../shared/PasswordRuleDisplay";

const UserEditor = React.forwardRef((props, ref) => {
  const {
    user,
    onValueChange,
    showIcon,
    disablePositionField,
    isGetUser,
    rules,
    isAddUser,
  } = props;
  const { t } = useTranslation();
  const [countryCode] = useCountry();
  const authenticationContext = useAuthentication();

  const [editingUser, setEditingUser] = React.useState();
  const [isUpdateUserPosition, setIsUpdateUserPosition] = React.useState(false);
  const [isPersonalInfoInputError, setIsPersonalInfoInputError] =
    React.useState({
      MOBILE: false,
      PHONE_HOME: false,
      PHONE_OFFICE: false,
      EMAIL: false,
      FAX: false,
      FIRSTNAME: false,
      LASTNAME: false,
      OTHERNAME: false,
    });
  const [isUserInfoChanged, setIsUserInfoChanged] = React.useState(false);
  const addingUserPasswordField = React.useRef();

  const getFullName = () => {
    const isChinese = /[^\u4e00-\u9fa5]/;

    if (editingUser && editingUser.attr) {
      const attr = editingUser.attr;

      if (Object.keys(attr).length > 0) {
        if (
          isChinese.test(attr.FIRSTNAME) === true &&
          isChinese.test(attr.LASTNAME) === true
        ) {
          return [attr.FIRSTNAME, attr.MIDDLENAME, attr.LASTNAME]
            .filter((w) => !!w)
            .join(" ");
        } else {
          return [attr.LASTNAME, attr.MIDDLENAME, attr.FIRSTNAME]
            .filter((w) => !!w)
            .join("");
        }
      }
    }

    return "";
  };

  const handleUserAttrChange = (prop, event) => {
    setIsUserInfoChanged(true);
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let noRegex = /^\+?[0-9()-]+$/;
    let webRegex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    const userInfo = { ...editingUser };
    let errorInputObject = isPersonalInfoInputError;

    switch (prop) {
      case "FIRSTNAME":
      case "LASTNAME":
      case "MIDDLENAME":
      case "JOBTITLE":
      case "DEPARTMENT":
        if (!event.target.value && prop != "MIDDLENAME") {
          errorInputObject[prop] = true;
        } else {
          errorInputObject[prop] = false;
        }
        userInfo.attr[prop] = event.target.value;

        if (prop === "JOBTITLE" || prop === "DEPARTMENT") {
          setIsUpdateUserPosition(true);
        }

        // update full name.
        userInfo.attr.FULLNAME = getFullName();
        break;
      case "accountType":
        if (event == null) {
          errorInputObject[prop] = true;
          userInfo[prop] = "";
        } else {
          errorInputObject[prop] = false;
          userInfo[prop] = event.value;
        }
        break;
      case "EMAIL":
        errorInputObject[prop] =
          event.target.value != "" &&
          !emailRegex.test(event.target.value.replace(/\s/g, ""));
        userInfo.attr[prop] = event.target.value;
        break;
      case "MOBILE":
      case "PHONE_HOME":
      case "PHONE_OFFICE":
        if (prop === "MOBILE") {
          errorInputObject[prop] = event === "+";
        }
        userInfo.attr[prop] = event;
        break;
      default:
        userInfo.attr[prop] = event.target.value;
        break;
    }

    errorInputObject = GetInvalidInput(userInfo);

    setIsPersonalInfoInputError((prev) => ({ ...prev, ...errorInputObject }));
    setEditingUser((prev) => ({ ...prev, ...userInfo }));
    if (onValueChange) {
      window.setTimeout(onValueChange);
    }
  };

  const handleUserObjChange = (prop, event) => {
    setIsUserInfoChanged(true);
    let value =
      prop === "accountType" ? event?.value ?? "" : event.target.value;

    setEditingUser({
      ...editingUser,
      [prop]: value,
    });
  };

  const phoneNumber = (number) => {
    let length = number
      ? number.split("").filter((e) => parseInt(e) !== "NaN").length
      : null;
    switch (length) {
      case 8:
        return `+852 ${number}`;
      case 11:
        return `+86 ${number}`;
      case 12:
        return `+886 ${number}`;
      default:
        return `+852 ${number}`;
    }
  };

  const accTypeOption = [
    {
      label: t("administrative_console.license_page.standard_users"),
      value: 0,
    },
    {
      label: t("administrative_console.license_page.restricted_users"),
      value: 2,
    },
  ];

  const GetInvalidInput = (userInfo) => {
    let errorInputObject = {};
    let emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]+$/i;
    let noRegex = /^\+?[0-9()-]+$/;
    let webRegex = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );

    Object.keys(userInfo.attr).forEach((a) => {
      if (
        (a === "JOBTITLE" ||
          a === "DEPARTMENT" ||
          a === "FIRSTNAME" ||
          a === "LASTNAME") &&
        userInfo.attr[a] === ""
      ) {
        errorInputObject[a] = true;
      } else if (
        a === "EMAIL" &&
        (!emailRegex.test(userInfo.attr[a].replace(/\s/g, "")) ||
          userInfo.attr[a] === "")
      ) {
        errorInputObject[a] = true;
      } else if (
        a === "MOBILE" &&
        !noRegex.test(userInfo.attr[a]) &&
        userInfo.attr[a] === "+"
      ) {
        errorInputObject[a] = true;
      } else if (a === "accountType" && userInfo[a] === "") {
        errorInputObject[a] = true;
      }
    });

    return errorInputObject;
  };

  /* expose method to integrate with user setting page */
  React.useImperativeHandle(ref, () => ({
    getEditingUser: () => {
      if (editingUser) {
        return {
          ...editingUser,
          attr: { ...editingUser.attr, FULLNAME: getFullName() },
        };
      }
    },
    hasError: () => {
      console.log(
        Object.values(isPersonalInfoInputError).includes(true) ||
          (addingUserPasswordField?.current?.getPasswordInvalidaty() ?? true)
      );
      if (isGetUser) {
        return Object.values(isPersonalInfoInputError).includes(true);
      } else {
        return (
          Object.values(isPersonalInfoInputError).includes(true) ||
          (addingUserPasswordField?.current?.getPasswordInvalidaty() ?? true)
        );
      }
    },
    isUpdateUserPosition: () => {
      return isUpdateUserPosition;
    },
    isUserInfoChanged: () => {
      return isUserInfoChanged;
    },
  }));

  React.useEffect(() => {
    const url = urlJoin(resServerBaseUrl, "/User/GetUserByUUID");
    if (isGetUser && (user?.uuid ?? false)) {
      const data = { uuid: user.uuid };
      CallApiWithContext(url, authenticationContext, JSON.stringify(data))
        .then((res) => {
          if (res.attr) {
            res.attr = JSON.parse(res.attr);
          } else {
            res.attr = {};
          }

          setEditingUser(res);
          setIsPersonalInfoInputError({
            MOBILE: false,
            PHONE_HOME: false,
            PHONE_OFFICE: false,
            EMAIL: false,
            FAX: false,
            FIRSTNAME: !res.attr?.FIRSTNAME,
            LASTNAME: !res.attr?.LASTNAME,
            OTHERNAME: false,
          });

          if (onValueChange) window.setTimeout(onValueChange);
        })
        .catch(console.log)
        .finally(() => {});
    } else {
      if (user) {
        let err = GetInvalidInput(user);
        setIsPersonalInfoInputError((p) => ({ ...p, ...err }));
        setEditingUser(user);
      }
    }
  }, [user]);

  React.useEffect(() => {
    console.log(editingUser?.accountType ?? "no user type");
  }, [editingUser]);

  return (
    <Grid container direction="row" justifySelf="center" spacing={1}>
      {/* first name */}
      <Grid container item xs={6} md={4}>
        <Grid item xs={12}>
          <TextField
            label={t("profile.first_name")}
            autoComplete="off"
            variant="standard"
            fullWidth
            required
            value={editingUser?.attr?.FIRSTNAME ?? ""}
            onChange={(e) => handleUserAttrChange("FIRSTNAME", e)}
            error={isPersonalInfoInputError.FIRSTNAME}
            InputProps={{ startAdornment: showIcon ? <AccountCircle /> : null }}
          />
        </Grid>
      </Grid>
      {/* middle name */}
      <Grid container item xs={6} md={4}>
        <Grid item xs={12}>
          <TextField
            id="input-with-sx"
            label={t("profile.middle_name")}
            variant="standard"
            autoComplete="off"
            fullWidth
            value={editingUser?.attr?.MIDDLENAME ?? ""}
            //sx={{ width: "100%" }}
            onChange={(e) => handleUserAttrChange("MIDDLENAME", e)}
            InputProps={{ startAdornment: showIcon ? <AccountCircle /> : null }}
          />
        </Grid>
      </Grid>
      {/* last name */}
      <Grid container item xs={6} md={4}>
        <Grid item xs={12}>
          <TextField
            label={t("profile.last_name")}
            variant="standard"
            value={editingUser?.attr?.LASTNAME ?? ""}
            required
            fullWidth
            autoComplete="off"
            onChange={(e) => handleUserAttrChange("LASTNAME", e)}
            error={isPersonalInfoInputError.LASTNAME}
            InputProps={{ startAdornment: showIcon ? <AccountCircle /> : null }}
          />
        </Grid>
      </Grid>

      {/* full name */}
      {isAddUser ? null : (
        <Grid container item xs={6} md={4}>
          <Grid item xs={12}>
            <TextField
              label={t(`profile.full_name`)}
              variant="standard"
              InputProps={{
                readOnly: true,
                startAdornment: showIcon ? <AccountCircle /> : null,
              }}
              fullWidth
              value={getFullName()}
              autoComplete="off"
            />
          </Grid>
        </Grid>
      )}
      {/* nickname */}
      {isAddUser ? null : (
        <Grid container item xs={6} md={4}>
          <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-end" }}>
            <TextField
              label={t("profile.nick_name")}
              variant="standard"
              disabled
              fullWidth
              autoComplete="off"
              defaultValue={editingUser?.attr?.NICKNAME ?? ""}
              onBlur={(e) => handleUserAttrChange("NICKNAME", e)}
              InputProps={{
                startAdornment: showIcon ? <AccountCircle /> : null,
              }}
            />
          </Grid>
        </Grid>
      )}

      {/* other name */}
      {isAddUser ? null : (
        <Grid item xs={6} md={4}>
          <Grid item xs={12}>
            <TextField
              label={t("profile.other_name")}
              variant="standard"
              fullWidth
              autoComplete="off"
              value={editingUser?.attr?.OTHERNAME || ""}
              onChange={(e) => handleUserAttrChange("OTHERNAME", e)}
              error={isPersonalInfoInputError.OTHERNAME}
              InputProps={{
                startAdornment: showIcon ? <AccountCircle /> : null,
              }}
            />
          </Grid>
        </Grid>
      )}

      {["PHONE_HOME", "PHONE_OFFICE", "MOBILE"].map((k, i) => {
        if ((isAddUser && k === "MOBILE") || !isAddUser) {
          return (
            <Grid item xs={12} md={isAddUser ? 6 : 4} id={k} key={k}>
              <MuiPhoneNumber
                fullWidth
                defaultCountry={countryCode ?? "hk"}
                variant={"standard"}
                label={t(`profile.${k.toLowerCase()}`)}
                autoComplete="off"
                value={
                  (editingUser?.attr[k] ?? "").includes("+")
                    ? editingUser?.attr[k] ?? ""
                    : phoneNumber(editingUser?.attr[k] ?? "")
                }
                onChange={(e) => handleUserAttrChange(k, e)}
                error={isPersonalInfoInputError[k]}
              />
            </Grid>
          );
        }
      })}

      {/* email */}
      <Grid
        item
        xs={12}
        md={isAddUser ? 6 : 12}
        sx={{ display: "flex", alignItems: "flex-end" }}
      >
        <TextField
          label={t("profile.email")}
          variant="standard"
          fullWidth
          placeholder="@gmail.com"
          autoComplete="off"
          value={editingUser?.attr.EMAIL ?? ""}
          onChange={(e) => handleUserAttrChange("EMAIL", e)}
          InputProps={{ startAdornment: showIcon ? <Email /> : null }}
          error={isPersonalInfoInputError.EMAIL}
        />
      </Grid>

      {/* password */}
      {isAddUser ? (
        <Grid container item xs={12} md={isAddUser ? 6 : 4}>
          <AddUserPasswordField
            ref={addingUserPasswordField}
            password={editingUser?.password ?? ""}
            rules={rules}
            userLogin={editingUser?.attr.EMAIL ?? ""}
            onChange={handleUserObjChange}
          />
        </Grid>
      ) : null}

      {/* account type */}
      {isAddUser ? (
        <Grid item xs={12} md={6}>
          <Autocomplete
            value={
              accTypeOption?.find(
                (a) => a.value === editingUser?.accountType ?? ""
              )?.label ?? ""
            }
            options={accTypeOption}
            onChange={(e, value) => handleUserAttrChange("accountType", value)}
            renderInput={(params) => (
              <TextField
                variant="standard"
                {...params}
                fullWidth
                label={t("administrative_console.user_page.account_type")}
                autoComplete="off"
                error={isPersonalInfoInputError?.accountType ?? false}
              />
            )}
          />
        </Grid>
      ) : null}

      {[
        { key: "JOBTITLE", label: "job_title" },
        { key: "DEPARTMENT", label: "department" },
      ].map((k, i) => (
        <Grid
          container
          item
          key={k.key + "update"}
          xs={6}
          md={isAddUser ? 6 : 4}
        >
          <Grid item xs={12}>
            <TextField
              disabled={disablePositionField}
              label={t(`profile.${k.label}`)}
              variant="standard"
              fullWidth
              autoComplete="off"
              value={editingUser?.attr[k.key] ?? ""}
              onChange={(e) => handleUserAttrChange(k.key, e)}
              InputProps={{ startAdornment: showIcon ? <Work /> : null }}
              error={isPersonalInfoInputError[k.key]}
            />
          </Grid>
        </Grid>
      ))}

      {/* fax */}
      {isAddUser ? null : (
        <Grid container item xs={6} md={4}>
          <Grid item xs={12}>
            <MuiPhoneNumber
              fullWidth
              defaultCountry={countryCode ?? "hk"}
              variant={"standard"}
              label={t(`profile.fax`)}
              autoComplete="off"
              value={
                (editingUser?.attr.FAX ?? "").includes("+")
                  ? editingUser.attr.FAX
                  : phoneNumber(editingUser?.attr.FAX)
              }
              onChange={(e) => handleUserAttrChange("FAX", e)}
            />
          </Grid>
        </Grid>
      )}

      {/* description */}
      {isAddUser ? null : (
        <Grid item xs={6} md={12}>
          <TextField
            label={t("profile.description")}
            variant="standard"
            sx={{ width: "100%" }}
            autoComplete="off"
            defaultValue={editingUser?.attr.DESCRIPTION || ""}
            onBlur={(e) => handleUserAttrChange("DESCRIPTION", e)}
            InputProps={{ startAdornment: showIcon ? <StickyNote2 /> : null }}
          />
        </Grid>
      )}

      {/* title */}
      <Grid item xs={6} md={isAddUser ? 12 : 6}>
        <TextField
          fullWidth
          label={t("profile.title")}
          variant="standard"
          autoComplete="off"
          value={editingUser?.attr.TITLE ?? ""}
          onChange={(e) => handleUserAttrChange("TITLE", e)}
          InputProps={{ startAdornment: showIcon ? <StickyNote2 /> : null }}
        />
      </Grid>

      {/* web page */}
      {isAddUser ? null : (
        <Grid item xs={6} md={6}>
          <TextField
            label={t("profile.web_page")}
            variant="standard"
            autoComplete="off"
            fullWidth
            value={editingUser?.attr.WEBPAGE ?? ""}
            onChange={(e) => handleUserAttrChange("WEBPAGE", e)}
            InputProps={{ startAdornment: showIcon ? <Language /> : null }}
          />
        </Grid>
      )}

      {["ADDRESS_HOME", "ADDRESS_CORRESPONDING", "ADDRESS_OFFICE"].map(
        (k, i) => {
          if ((isAddUser && k == "ADDRESS_OFFICE") || !isAddUser) {
            return (
              <Grid item key={k + "update"} xs={12}>
                <TextField
                  label={t(`profile.${k.toLowerCase()}`)}
                  variant="standard"
                  fullWidth
                  autoComplete="off"
                  value={editingUser?.attr[k] ?? ""}
                  onChange={(e) => handleUserAttrChange(k, e)}
                  InputProps={{
                    startAdornment: showIcon ? <Apartment /> : null,
                  }}
                />
              </Grid>
            );
          }
        }
      )}

      {/* about me */}
      {isAddUser ? null : (
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            sx={{
              pt: "10px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            {showIcon ? <BorderColor /> : null}
            <Typography variant="p" style={{ color: "gray" }}>
              {t("profile.about_me")}
            </Typography>
          </Grid>

          <Grid item container xs={12} direction="row">
            <TextField
              size="small"
              multiline
              rows={3}
              fullWidth
              autoComplete="off"
              value={editingUser?.attr.ABOUTME ?? ""}
              onChange={(e) => handleUserAttrChange("ABOUTME", e)}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

export default UserEditor;
